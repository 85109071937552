<div class="chat-message"
     [class.from-me]="message.senderUid === MY_UID"
     [class.from-other]="message.senderUid !== MY_UID"
     [class.broadcast]="message.isBroadcast"
>
    <p class="sender-name"  *ngIf="message.senderUid === MY_UID">You</p>
    <p class="sender-name" *ngIf="message.senderUid !== MY_UID">{{ message.senderName }}</p>

    {{ message.message }}
    <!--
    <ng-container *ngFor="let item of messageArray">
        <span *ngIf="!item.isEmoji">{{ item.message }}</span>
        <ngx-emoji *ngIf="item.isEmoji" [emoji]="item.message" size="16"></ngx-emoji>
    </ng-container>
    -->
</div>
