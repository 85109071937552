<!-- <div class="outside">
    <div class="container clearfix">
        <div class="chat">
            <div class="chat-history" #chathistory>
                <ul>

                    <li class="clearfix" *ngFor="let message of chatService.currentChat">
                        <div class="message-data align-right" *ngIf="message.sender == uid">
                            <span class="message-data-time" >{{ message.date }}</span> &nbsp; &nbsp;
                            <span class="message-data-name" >Me </span> <i class="fas fa-circle me"></i>
                        </div>
                        <div class="message other-message float-right" *ngIf="message.sender == uid">
                            {{ message.msg }}
                        </div>

                        <div class="message-data" *ngIf="!(message.sender == uid)">
                            <span class="message-data-name"><i class="fas fa-circle online"></i>{{ supervisorName }}</span>
                            <span class="message-data-time">{{ message.date }}</span>
                        </div>
                        <div class="message my-message" *ngIf="!(message.sender == uid) && !message.isBroadcast">
                            {{ message.msg }}
                        </div>
                        <div class="message broadcast" *ngIf="!(message.sender == uid) && message.isBroadcast">
                            {{ message.msg }}
                        </div>
                    </li>

                </ul>

            </div> 

            <div class="chat-message clearfix">
                <textarea name="message-to-send" id="message-to-send" placeholder ="Type your message" rows="3" [(ngModel)]="chatService.currentMessage"></textarea>
                <button (click)="sendMessage(chatService.currentMessage)">Send</button>
            </div> 

        </div> 

    </div>
</div> -->
<div class="chat">
  <div class="chat-history" #chathistory>
    <div *ngFor="let message of chatService.currentChat">
      <!-- From current user -->
      <div *ngIf="message.sender == uid" class="clearfix">
        <div class="message-data align-right">
          <span class="message-data-time" >{{ chatService.renderDate(message.date) }}</span> &nbsp; &nbsp;
          <span class="message-data-name" ><tra slug="lbl_me"></tra> </span> <i class="fa fa-circle me"></i>
        </div>
        <div class="message other-message float-right">
          {{ message.msg }}
        </div>
      </div>

      <!-- From another user -->
      <div *ngIf="!(message.sender == uid)" [class.broadcast]="message.isBroadcast" class="clearfix">
        <div class="message-data">
          <span class="message-data-name"><i class="fa fa-circle online"></i>{{ supervisorName }}</span>
          <span class="message-data-time">{{ chatService.renderDate(message.date) }}</span>
        </div>
        <div class="message my-message" *ngIf="!message.isBroadcast">
          {{ message.msg }}
        </div>
        <div class="message" *ngIf="message.isBroadcast">
          <i class="fa fa-bullhorn broadcast-icon" aria-hidden="true"></i>
          <div class="message-content">
            <div class="message-label"><tra-md slug="lbl_announcement" [props]="{index: message.broadcastIndex}"></tra-md></div>
            {{ message.msg }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chat-message">
    <textarea 
      name="message-to-send" 
      id="message-to-send" 
      [placeholder] ="lang.tra('txt_invig_messages')" 
      rows="1" 
      [(ngModel)]="chatService.currentMessage"
    ></textarea>
    <button 
      (click)="sendMessage(chatService.currentMessage)"  
      title="send"
    >
      <i class="fa fa-paper-plane" aria-hidden="true"></i>
    </button>
  </div> <!-- end chat-message -->
</div> <!-- end chat -->
