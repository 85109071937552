import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'element-render-reader',
  templateUrl: './element-render-reader.component.html',
  styleUrls: ['./element-render-reader.component.scss']
})
export class ElementRenderReaderComponent implements OnInit {
  @Input() contentElement: any;
  @Input() isLocked: any;
  @Input() questionState: any;
  @Input() element: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
