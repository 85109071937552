import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import katex from 'katex';
import { LangService } from '../../core/lang.service';
import { parseTemplate } from '@angular/compiler';

export const transformFrenchDecimal = (str:string, lang:string, comma:string=',') => {
  const isFr = (lang == 'fr');
  // console.log('transformFrenchDecimal', str, lang)
  if (isFr){
    const pattern = /\d*\.+\d+/g;
    return str.replace(pattern, match => {
      return match.split('.').join(comma)
    })
  }
  return str;
}

export const transformThousandsSeparator = (str:string, lang:string, spacer:string=' ') => {
  let triggerLen = 4;
  if (lang == 'fr'){
    triggerLen = 3;
  }
  return applyThousandsSpacesPY(str, spacer, triggerLen)
}
export const applyThousandsSpacesPY = (str:string, spacer:string=' ', triggerLen:number=3) => {
  const DECIMAL_POINT = '.'; // this algorithm assumes that the FR decimal transformation has not yet occurred
  const pattern = /Y?\d*\.*\d+/g;
  return str.replace(pattern, match => {
    if (match[0] === 'Y'){ // year marker
      return match.substr(1, match.length-1);
    }
    const parts = match.split(DECIMAL_POINT)
    const wholePart = parts[0];
    if (wholePart.length > triggerLen){
      const newWholePart = applyThousandsSpaces(wholePart, spacer);
      parts[0] = newWholePart;
    }
    return parts.join(DECIMAL_POINT)
  })
}
export const applyThousandsSpaces = (str:string, spacer:string=' ') => {
  var len = str.length;
  var newStr = ''
  for (let i=0; i<len; i++){
    if ((i % 3 === 0) && (i !== 0)){ newStr = spacer + newStr; }
    const index = len - (i+1)
    newStr = str[index] + newStr;
  }
  return newStr;
}

@Component({
  selector: 'render-math',
  templateUrl: './render-math.component.html',
  styleUrls: ['./render-math.component.scss']
})
export class RenderMathComponent implements OnInit, OnDestroy, OnChanges {

  @Input() obj:any;
  @Input() prop:string;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() raw:string;
  @ViewChild('mathField', { static: true }) mathFieldRef: ElementRef;

  mathField:HTMLSpanElement;
  currentLatex;
  intervalTracker;
  isRendered:boolean;

  constructor(
    private lang:LangService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateLatex();
  }

  ngOnDestroy() {
    this.clearListener();  
  }

  clearListener(){
    if (this.intervalTracker){
      clearInterval(this.intervalTracker);
    }
  }

  ngAfterViewInit() {
    // console.log('ngAfterViewInit')
    this.mathField = this.mathFieldRef.nativeElement;
    this.updateLatex();
    // if (!this.raw){
    this.intervalTracker = setInterval(this.updateLatex.bind(this), 2000);
    // }
  }

  sanitizeLatex(latex:string){
    latex = latex.replace('\\fontseries{n}', '');
    latex = latex.replace('\\placeholder{denominator}', '');
    latex = latex.replace('\\placeholder{numerator}', '');
    latex = latex.replace('\\placeholder{}', '');
    latex = this.transformThousandsSeparator(latex);
    latex = this.transformFrenchDecimal(latex);
    latex = this.transformColonSpacing(latex);
    return latex;
  }

  transformColonSpacing(latex:string){
    if (this.lang.c() == 'en'){
      const pattern = /:[a-zA-Z0-9\:]+/g;
      return latex.replace(pattern, match => {
        return match.split(':').join('{:}')
      })
    }
    return latex;
  }

  // $$\frac{\placeholder{}}{\placeholder{denominator}}$$

  transformThousandsSeparator(latex:string){
    const res = transformThousandsSeparator(latex, this.lang.c(), '\\space');
    return res;
  }

  transformFrenchDecimal(latex:string){
    return transformFrenchDecimal(latex, this.lang.c(), '{,}');
  }

  renderLatex(latex){
    latex = this.sanitizeLatex(latex);
    katex.render(latex, this.mathField, {
      throwOnError: false
    });
  }

  updateLatex(isForcedChange:boolean=false){
    // if (this.isRendered){
    //   this.clearListener(); 
    //   return 
    // }
    // console.log('updateLatex', this.raw, this.mathField)
    if (this.raw && this.mathField){
      this.renderLatex(this.raw);
      this.isRendered = true;
      return;
    }
    else if (this.mathField && this.obj && this.obj[this.prop]){
      if ( isForcedChange || (this.obj[this.prop] !== this.currentLatex) ){
        this.currentLatex = this.obj[this.prop];
        // this.mathField.innerHTML = '$$'+this.currentLatex+'$$';
        // this.MathLive.renderMathInElement(this.mathField);
        this.renderLatex(this.currentLatex);
        this.isRendered = true;
      }
  
    }
  }

}

