<!-- <div *ngIf="element.displayStyle==='vertical'">
  
</div>

<div *ngIf="element.displayStyle==='horizontal'">
  
</div> -->

<div 
  class="option-container"
  cdkDropList (cdkDropListDropped)="drop(options, $event);"
  [class.is-vertical]="isVertical()"
  [class.is-horizontal]="isHorizontal()"
  cdkDropListOrientation="{{ isHorizontal() ? 'horizontal' : 'vertical' }}"
>
  <div cdkDrag *ngFor="let optionElement of options; let optionIndex = index;">
    <div 
      class="inner-option-container"
      [class.is-vertical]="isVertical()"
      [class.is-horizontal]="isHorizontal()"
    >
      <div *ngIf="optionIndex !== 0" style="padding:0.3em; flex-grow:0;">
        {{element.delimiter}}
      </div>
      <div [ngSwitch]="optionElement.elementType" class="option">
        <div *ngSwitchCase="'text'">
          {{optionElement.content}}
        </div>
        <div *ngSwitchCase="'image'">
          <render-image [element]="optionElement"></render-image>
        </div>
        <div *ngSwitchCase="'math'">
          <render-math [obj]="optionElement" [prop]="'content'"></render-math>
        </div>
      </div>
    </div>
  </div>
</div>
