<div class="clickable-header">
  <button class="button is-small is-white btn-sort" (click)="toggleSort()">
    <span class="is-header-text" [class.is-wrap-disabled]="disableCaptionWrap" [ngStyle]="{'color': color}">{{caption}}</span>
    <span class="sorting-indicator" [ngSwitch]="getSortType()">
      <span *ngSwitchCase="SortMode.DESC"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
      <span *ngSwitchCase="SortMode.ASC"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
    </span>
  </button>
  <button *ngIf="id !== 'resolved'" class="button is-small is-light btn-filter" (click)="toggleFilter()">
    <i class="fa fa-filter" aria-hidden="true"></i>
  </button>
</div>
<div class="filter-settings" *ngIf="isFilterActive">
  <capture-filter-range
    [id]="id"
    [isActive]="true"
    [model]="getFilterModel()"
    (change)="updateFilter()"
    [selectionOptions]="selectionOptions"
    [radioValueMapping]="radioValueMapping"
  ></capture-filter-range>
</div>
