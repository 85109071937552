<div class="sbs-container"> 
  <div class="sbs-panel" *ngIf="element.left">
    <div *ngFor="let subElement of element.left">
      <element-render [contentElement]="subElement" [isLocked]="isLocked" [questionState]="questionState" ></element-render>
    </div>
  </div>
  <div class="sbs-panel" *ngIf="element.right">
    <div *ngFor="let subElement of element.right">
      <element-render [contentElement]="subElement" [isLocked]="isLocked" [questionState]="questionState" ></element-render>
    </div>
  </div>
</div>