import * as moment from 'moment-timezone';
import { TimezoneService } from '../../core/timezone.service';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from 'src/app/core/lang.service';

enum Periods { // this is designed to match the format used by momentjs
  AM = 'am', 
  PM = 'pm' ,
};

const FMT_TIME = 'hh:mma';

export const dateAndTimeToDbDate = (date:string, time:string) => moment(`${date} ${time}`, 'YYYY-MM-DD '+FMT_TIME).utc().format();

const timeLeadingZero = (num:number) => {
  let str = ''+num;
  if (!str){
    str = '00';
  }
  if (str.length < 2){
    str = '0'+str;
  }
  return str.substr(0, 2)
}

@Component({
  selector: 'input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() fc:FormControl;
  @Input() isDisabled:boolean;

  constructor(
      private timezone: TimezoneService,
      public lang: LangService
  ) { }

  hour = new FormControl();
  minutes = new FormControl();
  period = new FormControl();
  
  Periods = Periods;

  ngOnInit(): void {
    this.fc.valueChanges.subscribe(v => this.injectNewTime());
    this.hour.valueChanges.subscribe(v => this.updateFormValue());
    this.minutes.valueChanges.subscribe(v => this.updateFormValue());
    this.period.valueChanges.subscribe(v => this.updateFormValue());
    this.injectNewTime();
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.isDisabled){ this.updateDisabledStatus() }
  }

  ngOnDestroy() {

  }

  updateFormValue(){
    let hour = +(this.hour.value || 0);
    let minutes = +(this.minutes.value || 0);
    let period = this.period.value || 0;
    if (hour > 12){
      hour = hour % 12;
      period = Periods.PM;
    }
    hour = Math.min(23, hour);
    hour = Math.max(0, hour);
    minutes = Math.min(59, minutes);
    minutes = Math.max(0, minutes);
    if (period !== Periods.PM){
      period = Periods.AM;
    }
    const timeStr = timeLeadingZero(hour) + ':' + timeLeadingZero(minutes) + period;
    if (timeStr !== this.fc.value){
      this.fc.setValue(timeStr);
    }
  }

  updateDisabledStatus(){
    if (this.isDisabled){
      this.hour.disable();
      this.minutes.disable();
      this.period.disable();
    }
    else{
      this.hour.enable();
      this.minutes.enable();
      this.period.enable();
    }
  }

  injectNewTime(){
    const time = this.fc.value;
    if (time){
      const hour = time.substr(0,2);
      const minutes = time.substr(3,2);
      const period = time.substr(5,2);
      this.hour.setValue(+hour);
      this.minutes.setValue(+minutes);
      this.period.setValue(period);
    }
  }

}
