import { Component, OnInit, Input } from '@angular/core';
import { IContentElementTable, QuestionState, IContentElementSbs } from '../models';

@Component({
  selector: 'element-render-sbs',
  templateUrl: './element-render-sbs.component.html',
  styleUrls: ['./element-render-sbs.component.scss']
})
export class ElementRenderSbsComponent implements OnInit {
  
  @Input() element:IContentElementSbs;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  constructor() { }

  ngOnInit(): void {
  }

}
