<div zwibbler #zwibbler z-controller="MyController" id="{{ 'zwib' + id }}" class="full flex-col" [ngClass]="{'draw-frame': isFrameEnabled}" showToolbar="false" z-no-auto-init pageView="false" pagePlacement="centre" defaultZoom="page" defaultPaperSize="none"
    defaultBrushWidth="4">
    <div z-canvas class="stretch"></div>
    <div *ngIf="isToolbarEnabled" toolbar class="dialog zwibbler-passthrough flex-row wrap is-main">
        <button z-click="ctx.usePickTool(ctx.focus(true))" z-selected="ctx.getCurrentTool()==='pick'" title="Select">
            <i class="fas fa-mouse-pointer"></i></button>
        <button z-show-popup="palette" title="Palette">
            <i class="fas fa-palette"></i></button>
        <button z-click="ctx.useTextTool(ctx.focus(true))" title="Text" z-selected="ctx.getCurrentTool()==='text'">
            <i class="fas fa-font"></i></button>
        <button z-click="ctx.useLineTool(ctx.renderDashesConfig({}),{singleLine: true},ctx.focus(true))" title="Line" z-selected="ctx.getCurrentTool()==='line'">
            <i class="fas fa-pencil-ruler"></i>
        </button>
        <button z-click="ctx.useArrowTool(ctx.renderDashesConfig({}),ctx.focus(true))" title="Arrow" z-selected="ctx.getCurrentTool()==='arrow'">
            <i class="fas fa-long-arrow-alt-up"></i>
        </button>
        <button z-click="ctx.useBrushTool({
            lineWidth: 10,
            strokeStyle: 'erase',
            layer: 'my_eraser_layer'})" z-selected="ctx.getCurrentTool()==='brush'" title="Eraser">
            <i class="fas fa-eraser"></i>
        </button>
        <button z-click="ctx.useFreehandTool(ctx.focus(true))" z-selected="ctx.getCurrentTool()==='freehand'" title="Pencil">
            <i class="fas fa-pencil-alt"></i></button>
        <button z-show-popup="stampPalette" title="Stamp">
            <i class ="fas fa-stamp"></i>
        </button>
        <button z-show-popup="shapes" z-selected="ctx.getCurrentTool()==='rectangle' || ctx.getCurrentTool()==='circle'" title="Shape">
            <i class="fas fa-shapes"></i>
        </button>
        <button z-show-popup="dashes" title="Line Style">
            <i class="fas fa-grip-lines"></i>
        </button>

        <!-- <div style="margin-top:0.02em; border-top:1px solid #fff; height:50px; position:relative" class="btn-line">
            <button (click)="setDashes(false)" style="width:42px; height:15px;" [class.is-selected]="!isDashesEnabled"> <div class="rep-line"></div> </button>
            <button (click)="setDashes(true)" style="width:42px; height:15px;" [class.is-selected]="isDashesEnabled"> <div class="rep-line dashed"></div> </button>
        </div> -->
    </div>
    <div *ngIf="isToolbarEnabled" toolbar class="dialog zwibbler-passthrough flex-row wrap is-main" style="opacity:0;">

        <button z-click="ctx.usePickTool(ctx.focus(true))" z-selected="ctx.getCurrentTool()==='pick'" title="Select">
                <i class="fas fa-mouse-pointer"></i></button>



        <button z-click="ctx.useLineTool({
                lineWidth: 3, lockAspectRatio:false},{singleLine: true},ctx.focus(true))" title="Line" id="line-select" z-selected="ctx.getCurrentTool()==='line'">
                <i class="fas fa-pencil-ruler"></i>
            </button>

    </div>
    <div *ngIf="isToolbarEnabled" toolbar class="dialog zwibbler-passthrough flex-row is-doc">
        <button z-click="ctx.zoomIn()" title="Zoom in">
            <i class="fas fa-search-plus"></i>
        </button>
        <button z-click="ctx.zoomOut()" title="Zoom out">
            <i class="fas fa-search-minus"></i>
        </button>
        <button z-click="ctx.copy()" title="Copy">
            <i class="fas fa-copy"></i>
        </button>
        <button z-click="ctx.paste()" title="Paste">
            <i class="fas fa-paste"></i>
        </button>
        <button z-click="ctx.deleteSelection()" title="Delete Selection">
            <i class="fas fa-trash-alt"></i>
        </button>
        <button z-click="ctx.undo()" title="Undo" z-disabled="!ctx.canUndo()">
            <i class="fas fa-undo"></i>
        </button>
        <button z-click="ctx.redo()" title="Redo" z-disabled="!ctx.canRedo()">
            <i class="fas fa-redo"></i>
        </button>
    </div>

    <div z-popup="palette" class="dialog" z-click-dismiss z-show-position="tr tl">
        <div class="swatch" z-for="colour in colours" z-click="ctx.setColor(colour, true)" z-on:contextmenu="ctx.setColor(colour, false)" z-style="{backgroundColor:colour}">
        </div>
    </div>

    <div z-popup="stampPalette" class="dialog" z-click-dismiss z-show-position="tr tl">
        <!-- <div class="swatch" z-for="index in 8" z-click="ctx.useStampTool({url: images[index], width: 50}, true)" z-style="{backgroundImage: `url(${images[index]})`}">
        </div> -->
        <!-- <button z-style="{backgroundImage: 'url(\'assets/RedTriangle.png\')'}" z-click="ctx.useStampTool({url: 'assets/RedTriangle.png', width: 50}, true)"></button> -->
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/BlackCircle.png', width:15}, true)" class="stampButton blackCircleButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/RedTriangle.png', width:15}, true)" class="stampButton redTriangleButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/GreenSquare.png', width:15}, true)" class="stampButton greenSquareButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/BlueStar.png', width:15}, true)" class="stampButton blueStarButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/GoldCircle.png', width:15}, true)" class="stampButton goldCircleButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/PurpleTriangle.png', width:15}, true)" class="stampButton purpleTriangleButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/PinkSquare.png', width:15}, true)" class="stampButton pinkSquareButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/BrownStar.png', width:15}, true)" class="stampButton brownStarButton"></button>
    </div>

    <div z-popup="stampPalette" class="dialog" z-click-dismiss z-show-position="tr tl">
        <!-- <div class="swatch" z-for="index in 8" z-click="ctx.useStampTool({url: images[index], width: 50}, true)" z-style="{backgroundImage: `url(${images[index]})`}">
        </div> -->
        <!-- <button z-style="{backgroundImage: 'url(\'assets/RedTriangle.png\')'}" z-click="ctx.useStampTool({url: 'assets/RedTriangle.png', width: 50}, true)"></button> -->
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/BlackCircle.png', width:15}, true)" class="stampButton blackCircleButton"></button>
        <button z-click="ctx.useStampTool({url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/GoldCircle.png', width:15}, true)" class="stampButton goldCircleButton"></button>
    </div>
    <div z-popup="shapes" class="dialog" z-click-dismiss>
        <button z-click="ctx.useRectangleTool(ctx.renderShapeOutlineConfig({}),ctx.focus(true))"><i class="far fa-square"></i></button>
        <button z-click="ctx.useRectangleTool({strokeStyle:'transparent'},ctx.focus(true))"><i class="fas fa-square"></i></button>
        <button z-click="ctx.useCircleTool(ctx.renderShapeOutlineConfig({}),ctx.focus(true))"><i class="far fa-circle"></i></button>
        <button z-click="ctx.useCircleTool({strokeStyle:'transparent'},ctx.focus(true))"><i class="fas fa-circle"></i></button>
    </div>
    <div z-popup="dashes" class="dialog" z-click-dismiss>
        <button z-click="ctx.setToolProperty('dashes', null)" style="width:42px; height:15px;"> <div class="rep-line"></div> </button>
        <button z-click="ctx.setToolProperty('dashes', '6,3')" style="width:42px; height:15px;"> <div class="rep-line dashed"></div> </button>
    </div>
</div>

<div id="preview">
    <img [src]="pngFile">
</div>