import {IStudent} from '../data';
import {randomFirstName, randomLastName} from '../../../constants/fakenames';

export const students: IStudent[] = [
    {
        'id': 1,
        'first_name': 'Brandy',
        'last_name': 'Marsha'
    },
    {
        'id': 2,
        'first_name': 'Modestia',
        'last_name': 'Thetos'
    },
    {
        'id': 3,
        'first_name': 'Hinda',
        'last_name': 'Jarlathus'
    },
    {
        'id': 4,
        'first_name': 'Loutitia',
        'last_name': 'Maren'
    },
    {
        'id': 5,
        'first_name': 'Milicent',
        'last_name': 'Tawney'
    },
    {
        'id': 6,
        'first_name': 'Mirabella',
        'last_name': 'Ludlow'
    },
    {
        'id': 7,
        'first_name': 'Valry',
        'last_name': 'Winifield'
    },
    {
        'id': 8,
        'first_name': 'Nessa',
        'last_name': 'Martie'
    },
    {
        'id': 9,
        'first_name': 'Anna-Diana',
        'last_name': 'Kone'
    },
    {
        'id': 10,
        'first_name': 'Mahalia',
        'last_name': 'Bitthia'
    },
    {
        'id': 11,
        'first_name': 'Deane',
        'last_name': 'Liederman'
    },
    {
        'id': 12,
        'first_name': 'Arly',
        'last_name': 'Wilscam'
    },
    {
        'id': 13,
        'first_name': 'Arly',
        'last_name': 'Angelique'
    },
    {
        'id': 14,
        'first_name': 'Cybil',
        'last_name': 'Hoem'
    },
    {
        'id': 15,
        'first_name': 'Norene',
        'last_name': 'Bonns'
    },
    {
        'id': 16,
        'first_name': 'Lise',
        'last_name': 'Sinai'
    },
    {
        'id': 17,
        'first_name': 'Bethena',
        'last_name': 'Rimma'
    },
    {
        'id': 18,
        'first_name': 'Randene',
        'last_name': 'Alfi'
    },
    {
        'id': 19,
        'first_name': 'Mirabella',
        'last_name': 'Corso'
    },
    {
        'id': 20,
        'first_name': 'Pegeen',
        'last_name': 'Angelique'
    },
    {
        'id': 21,
        'first_name': 'Shane',
        'last_name': 'Vinita'
    },
    {
        'id': 22,
        'first_name': 'Lucinda',
        'last_name': 'Trainer'
    },
    {
        'id': 23,
        'first_name': 'Stacie',
        'last_name': 'Wilscam'
    },
    {
        'id': 24,
        'first_name': 'Stacia',
        'last_name': 'Haldeman'
    },
    {
        'id': 25,
        'first_name': 'Carly',
        'last_name': 'Reba'
    },
    {
        'id': 26,
        'first_name': 'Jaquelin',
        'last_name': 'Treacy'
    },
    {
        'id': 27,
        'first_name': 'Tiphany',
        'last_name': 'Santini'
    },
    {
        'id': 28,
        'first_name': 'Daffi',
        'last_name': 'Lynus'
    },
    {
        'id': 29,
        'first_name': 'Anna-Diana',
        'last_name': 'Tawney'
    },
    {
        'id': 30,
        'first_name': 'Elana',
        'last_name': 'Sinai'
    },
    {
        'id': 31,
        'first_name': 'Hertha',
        'last_name': 'Farrington'
    },
    {
        'id': 32,
        'first_name': 'Georgine',
        'last_name': 'Thilde'
    },
    {
        'id': 33,
        'first_name': 'Jemie',
        'last_name': 'Mizuki'
    },
    {
        'id': 34,
        'first_name': 'Sindee',
        'last_name': 'Winifield'
    },
    {
        'id': 35,
        'first_name': 'Tara',
        'last_name': 'Chaim'
    },
    {
        'id': 36,
        'first_name': 'Brandy',
        'last_name': 'Gillett'
    },
    {
        'id': 37,
        'first_name': 'Jemie',
        'last_name': 'Jaan'
    },
    {
        'id': 38,
        'first_name': 'Stacie',
        'last_name': 'Olympias'
    },
    {
        'id': 39,
        'first_name': 'Maridel',
        'last_name': 'Cristionna'
    },
    {
        'id': 40,
        'first_name': 'Anna-Diana',
        'last_name': 'Liederman'
    },
    {
        'id': 41,
        'first_name': 'Elfrieda',
        'last_name': 'Zolner'
    },
    {
        'id': 42,
        'first_name': 'Madel',
        'last_name': 'Adin'
    },
    {
        'id': 43,
        'first_name': 'Deane',
        'last_name': 'Vinita'
    },
    {
        'id': 44,
        'first_name': 'Tiphany',
        'last_name': 'Chaim'
    },
    {
        'id': 45,
        'first_name': 'Cybil',
        'last_name': 'Davidde'
    },
    {
        'id': 46,
        'first_name': 'Phelia',
        'last_name': 'Thetos'
    },
    {
        'id': 47,
        'first_name': 'Blondell',
        'last_name': 'Veleda'
    },
    {
        'id': 48,
        'first_name': 'Evie',
        'last_name': 'Schecter'
    },
    {
        'id': 49,
        'first_name': 'Mehetabel',
        'last_name': 'Walden'
    },
    {
        'id': 50,
        'first_name': 'Evie',
        'last_name': 'Ludvig'
    },
    {
        'id': 51,
        'first_name': 'Lusa',
        'last_name': 'Crispas'
    },
    {
        'id': 52,
        'first_name': 'Amabelle',
        'last_name': 'Melissa'
    },
    {
        'id': 53,
        'first_name': 'Deane',
        'last_name': 'Schecter'
    },
    {
        'id': 54,
        'first_name': 'Melinde',
        'last_name': 'Morganne'
    },
    {
        'id': 55,
        'first_name': 'Alexandra',
        'last_name': 'Schmeltzer'
    },
    {
        'id': 56,
        'first_name': 'Brandy',
        'last_name': 'Jaan'
    },
    {
        'id': 57,
        'first_name': 'Celle',
        'last_name': 'Denice'
    },
    {
        'id': 58,
        'first_name': 'Alexandra',
        'last_name': 'Morganne'
    },
    {
        'id': 59,
        'first_name': 'Melinde',
        'last_name': 'Pavia'
    },
    {
        'id': 60,
        'first_name': 'Alysia',
        'last_name': 'Sinai'
    },
    {
        'id': 61,
        'first_name': 'Mirelle',
        'last_name': 'Kone'
    },
    {
        'id': 62,
        'first_name': 'Rodie',
        'last_name': 'Treacy'
    },
    {
        'id': 63,
        'first_name': 'Hinda',
        'last_name': 'Martie'
    },
    {
        'id': 64,
        'first_name': 'Kacie',
        'last_name': 'Arlina'
    },
    {
        'id': 65,
        'first_name': 'Mirabella',
        'last_name': 'Timothea'
    },
    {
        'id': 66,
        'first_name': 'Aida',
        'last_name': 'Wilscam'
    },
    {
        'id': 67,
        'first_name': 'Margareta',
        'last_name': 'Farrington'
    },
    {
        'id': 68,
        'first_name': 'Eolande',
        'last_name': 'Courtenay'
    },
    {
        'id': 69,
        'first_name': 'Olivia',
        'last_name': 'Arlina'
    },
    {
        'id': 70,
        'first_name': 'Elana',
        'last_name': 'Lorine'
    },
    {
        'id': 71,
        'first_name': 'Rochell',
        'last_name': 'Trainer'
    },
    {
        'id': 72,
        'first_name': 'Leoline',
        'last_name': 'Sinai'
    },
    {
        'id': 73,
        'first_name': 'Corella',
        'last_name': 'Sinai'
    },
    {
        'id': 74,
        'first_name': 'Leoline',
        'last_name': 'Winifield'
    },
    {
        'id': 75,
        'first_name': 'Melinde',
        'last_name': 'Sinai'
    },
    {
        'id': 76,
        'first_name': 'Alexandra',
        'last_name': 'Courtenay'
    },
    {
        'id': 77,
        'first_name': 'Maridel',
        'last_name': 'Corso'
    },
    {
        'id': 78,
        'first_name': 'Stephenie',
        'last_name': 'Treacy'
    },
    {
        'id': 79,
        'first_name': 'Lucinda',
        'last_name': 'Felike'
    },
    {
        'id': 80,
        'first_name': 'Rochell',
        'last_name': 'Morganne'
    },
    {
        'id': 81,
        'first_name': 'Meggie',
        'last_name': 'Reba'
    },
    {
        'id': 82,
        'first_name': 'Blondell',
        'last_name': 'Gillett'
    },
    {
        'id': 83,
        'first_name': 'Fredi',
        'last_name': 'Farrington'
    },
    {
        'id': 84,
        'first_name': 'Arly',
        'last_name': 'Crin'
    },
    {
        'id': 85,
        'first_name': 'Charla',
        'last_name': 'Teryn'
    },
    {
        'id': 86,
        'first_name': 'Elfrieda',
        'last_name': 'Alfi'
    },
    {
        'id': 87,
        'first_name': 'Stacia',
        'last_name': 'Kenzi'
    },
    {
        'id': 88,
        'first_name': 'Maridel',
        'last_name': 'Naresh'
    },
    {
        'id': 89,
        'first_name': 'Nicol',
        'last_name': 'Schwab'
    },
    {
        'id': 90,
        'first_name': 'Nerty',
        'last_name': 'Tawney'
    },
    {
        'id': 91,
        'first_name': 'Harmony',
        'last_name': 'Lynus'
    },
    {
        'id': 92,
        'first_name': 'Mirelle',
        'last_name': 'Farrington'
    },
    {
        'id': 93,
        'first_name': 'Latia',
        'last_name': 'Winifield'
    },
    {
        'id': 94,
        'first_name': 'Lucinda',
        'last_name': 'Veleda'
    },
    {
        'id': 95,
        'first_name': 'Meryl',
        'last_name': 'Ave'
    },
    {
        'id': 96,
        'first_name': 'Maridel',
        'last_name': 'Prudie'
    },
    {
        'id': 97,
        'first_name': 'Alexandra',
        'last_name': 'Kone'
    },
    {
        'id': 98,
        'first_name': 'Talyah',
        'last_name': 'Ludlow'
    },
    {
        'id': 99,
        'first_name': 'Harmony',
        'last_name': 'Olympias'
    },
    {
        'id': 100,
        'first_name': 'Mirabella',
        'last_name': 'Corso'
    }
];

const leaders: IStudent[] = [...Array(100)].map((_, i) => {
    return {
        id: i + 1,
        first_name: randomFirstName(),
        last_name: randomLastName(),
    };
});

export function genStudents() {
    console.log(leaders);
    // 1. Print to console.
    // 2. store as global variable (right click the printed array in the console).
    // 3. copy(temp1).
    // 4. paste here.
}
