import * as moment from 'moment-timezone';
import * as DBT from "../api/models/db-types";
import { Injectable } from '@angular/core';
import { MPT } from '../domain/contexts/mpt';
import { WhitelabelService } from '../domain/whitelabel.service';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  
  private timezone: string;
  
  constructor(
      private whiteLabel: WhitelabelService,
  ) { 
    if (this.whiteLabel.context === MPT.id) {
      this.timezone = this.whiteLabel.contextData.defaultTimezone; // 'America/Toronto' 
    } else {
      this.timezone = moment.tz.guess();
    }
  }
  
  getTimezone() {
    return this.timezone;  
  }
  
  moment(datetime: string | DBT.DATETIME | number): moment.Moment {
    return moment.tz(datetime, this.timezone)
  }

}
