<!-- <button *ngIf="!isRecording" class="button" (click)="startRecording()">Start Recording</button>
<button *ngIf="isRecording" class="button" (click)="stopRecording()">Stop Recording</button> -->

<div [ngSwitch]="!!isLocked">
<capture-voice *ngSwitchCase="false"
    [element]="capture" 
    [isResponse]="true"
    [recordEndCallback]="stopRecording"
></capture-voice>
<render-audio  *ngSwitchCase="true"
    [url]="capture.url"
    [hasControls]="true"
></render-audio>
</div>
