import { Component, OnInit, Input } from '@angular/core';
import { IContentElementVideo, QuestionState } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

export const memo = <T>(map: Map<string, T>, key:string, generator: (key:string) => T ) => {
  if (map.has(key)){
    return map.get(key);
  }
  else{
    const val = generator(key);
    map.set(key, val);
    return val;
  }
}

@Component({
  selector: 'element-render-video',
  templateUrl: './element-render-video.component.html',
  styleUrls: ['./element-render-video.component.scss']
})
export class ElementRenderVideoComponent implements OnInit {

  @Input() element:IContentElementVideo;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  public plyrOptions = {
    controls: [
      'play-large', 
      'play', 
      'progress', 
      'current-time', 
      'mute', 
      'volume', 
      'captions', 
      'settings', 
      'fullscreen'
    ]
  }
  private sanitizedUrls:Map<string, SafeResourceUrl> = new Map();
  private videoSources:Map<string, any[]> = new Map();
  private subtitleTracks:Map<string, any[]> = new Map();


  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  getSubtitleTracks(){
    return memo(this.subtitleTracks, this.element.subtitlesUrl, src => [{
      kind: 'captions',
      label: 'Enabled',
      // srclang: 'en',
      src,
      default: true,
    }]);
  }

  getVideoSources(){
    return memo(this.videoSources, this.element.url, src => [{ src: this.optimizeUrl(src), /*type: 'video/mp4',*/ }]);
  }

  optimizeUrl(url:string){
    const domainRaw:string = 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/';
    const domainCdn:string = 'https://d3azfb2wuqle4e.cloudfront.net/';
    return url.split(domainRaw).join(domainCdn);
  }

  getVideoUrl(){
    return memo(this.sanitizedUrls, this.element.url, rawUrl => this.sanitizer.bypassSecurityTrustResourceUrl(this.optimizeUrl(rawUrl)) );
  }

}
