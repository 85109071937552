<div class="runner-container" >
  <div *ngIf="getQuestionAudioUrl() && isAudioPlayerShowing()" class="dont-print">
    <render-audio
      [url]="getQuestionAudioUrl()"
      [hasControls]="true"
    ></render-audio>
  </div>
  <div class="question-container">
    <div class="question-panels">
      <div class="question-panel" *ngIf="currentQuestion && currentQuestion.content" [ngStyle]="{'font-size.em':questionTextSize.value}">
        <div *ngFor="let contentElement of currentQuestion.content; let elementIndex = index;" 
             class="content-element"
             [class.fade-in]="isFadeInEntrance()"
             [ngStyle]="getElementStyle(contentElement, elementIndex)"
        >
          <element-render [contentElement]="contentElement" 
                          [isLocked]="isInputLocked()"  
                          [questionState]="questionState"
          ></element-render>
        </div>
      </div>
   </div>
  </div>
  <!-- <button (click)="prevQuestion.emit(questionState)" [disabled]="isFirstQuestion" class="button">Back</button> -->
  <!-- <button (click)="nextQuestion.emit(questionState)" class="button is-success">Continue</button> -->
</div>
