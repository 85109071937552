import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionState, IContentElement, IEntryStateGroup, IContentElementGroup, IContentElementGroupTarget, ScoringTypes, IContentElementDndSub, IContentElementDndDraggable, getElementWeight } from '../models';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
// import {IContentElementGroup} from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';

const SCORING_TYPE = ScoringTypes.AUTO;
@Component({
  selector: 'element-render-grouping',
  templateUrl: './element-render-grouping.component.html',
  styleUrls: ['./element-render-grouping.component.scss']
})
export class ElementRenderGroupingComponent implements OnInit, OnChanges {
  @Input() element:IContentElementGroup;
  @Input() isLocked: boolean;
  @Input() questionState: QuestionState;
  @Input() changeCounter: number;
  draggables: IElementPos[] = [];
  targets: {targetContext: IContentElementGroupTarget, contents:IElementPos[]}[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      this.updateDisplayEls();
    }
  }

  updateDisplayEls() {
    this.draggables = [];
    this.targets = [];

    this.targets = this.element.targets.map(target => {
      return {
        targetContext: target,
        contents: [],
      }
    });
    this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));
    // console.log('this.targets', this.targets)
  }

  addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
    return
  }

  refreshState(){
    let entryState: IEntryStateGroup = {
      type: 'grouping',
      isCorrect: false,
      isStarted: false, //this.targets.find((target:any) => target.groups.length) ? true : false,
      isFilled: false, //this.targets.find((target:any) => target.groups.length) ? true : false,
      score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
      weight: getElementWeight(this.element),
      scoring_type: SCORING_TYPE,
      draggables: this.element.draggables,
      targets: this.targets
    };
    if (this.questionState){
      this.questionState[this.element.entryId] = entryState;
    }
  }

  drop(event: CdkDragDrop<string[]>, isHomeDest:boolean=false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } 
    else {
      let allowTransfer;
      if (this.element.isMatchingMode){
        allowTransfer = false;
        if (isHomeDest){
          allowTransfer = true;
        }
        else if (event.container.data.length === 0) { 
          allowTransfer = true;
        }
      }
      else{
        allowTransfer = true;
      }

      if (allowTransfer){
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
      }
    }
    // console.log(event, this.targets);
    this.refreshState();
  }

}
