import { Injectable } from '@angular/core';
import { IActiveModal } from './modal/types.ts/model';

@Injectable({
    providedIn: 'root'
})
export class PageModalService {

    constructor() { }

    defineNewPageModal(){
        return new PageModalController();
    }
}

export class PageModalController {

    currentModal:IActiveModal;
    isSaving:boolean;

    getCurrentModal(){
        return this.currentModal;
    }

    closeModal = (config?: any) => {
        this.currentModal = null;
    }

    confirmModal() {
        this.currentModal.finish(this.currentModal.config);
    }

    newModal = (modalConfig: IActiveModal) => {
        this.currentModal = modalConfig;
    }
}
