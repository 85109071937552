import { Component, OnInit, ElementRef ,Input, ChangeDetectorRef} from '@angular/core';
import { ZwibblerContext, ZwibblerClass } from "./zwibbler"
import { DrawDisplayMode } from './constants';

declare let Zwibbler: ZwibblerClass;


@Component({
  selector: 'element-render-drawing',
  templateUrl: './element-render-drawing.component.html',
  styleUrls: ['./element-render-drawing.component.scss']
})
export class ElementRenderDrawingComponent implements OnInit {

  constructor(private myElement: ElementRef,private changeRef: ChangeDetectorRef) {

  }
  @Input() mode='';
  @Input() id;
  @Input() currId;
  @Input() parent;
  @Input() zoomLevel;
  @Input() isZoomIn;
  @Input() isZoomOut;
  @Input() activatedState:boolean = true;
  @Input() useEraser:boolean = false;
  @Input() useHighlighter:boolean = false;
  @Input() useLine:boolean = false;
  @Input() isFrameEnabled:boolean = true;
  @Input() isToolbarEnabled:boolean = false;
  
  preview = false;
  config
  pngFile
  lineClick = true;
  highlightClick = false;
  pageIndexTracker = new Map();
  pageIndexes:number[] = [];
  DrawDisplayMode = DrawDisplayMode;

  private ctx: ZwibblerContext; // Zwibbler context
  ngOnDestroy() {
    if (this.ctx) {
      // console.log("DESTROY ZWIBBLER");
      this.ctx.destroy();
      this.ctx = null;
    }
  }

  ngOnInit() {
    
  }

  setPageToQuestion(){
    const id = this.currId;
    if(this.ctx){
      if (!this.pageIndexTracker.has(id)){
        this.pageIndexTracker.set(id, this.pageIndexes.length);
        this.pageIndexes.push(id);
      }
      const pageIndex = this.pageIndexTracker.get(id);
      const initialPageCount = this.ctx.getPageCount();
      for(let i=initialPageCount; i<(pageIndex + 1); i++){
        this.ctx.addPage();
      }
      this.ctx.setCurrentPage(pageIndex);
      // console.log('setCurrentPage', pageIndex)
    }
  }

  ngOnChanges() {
    this.changeRef.detectChanges();
    if(this.ctx){
      this.ctx.resize();
    }
 
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      this.setPageToQuestion();
      if(this.ctx){
        if(this.useEraser){
          this.ctx.useBrushTool({
            lineWidth: 35,
            strokeStyle: 'erase',
            layer: 'my_eraser_layer'})
        }
      
        if(this.useHighlighter){
          this.ctx.useLineTool({
            lineWidth: 24, strokeStyle:'#FFFF00',opacity:'0.3',lockAspectRatio:false},{singleLine: true})
        }
        if(this.useLine){
          this.ctx.useLineTool({
            lineWidth: 3, strokeStyle:'#3298DC', lockAspectRatio:false},{singleLine: true})
        }
      }
    }
    else{
      if(this.mode === 'review'){
        this.completed()
        document.getElementById("zwib"+this.id).style.display = "none"
        document.getElementById("preview").style.display = "block"
        this.preview = true;
      }
      else{
        this.preview = false;
        document.getElementById("preview").style.display = "none"
        document.getElementById("zwib"+this.id).style.display = "block"
      }
    }

  }

  ngAfterViewInit() {
    Zwibbler = window['Zwibbler'];
    let zwibblerDiv = this.myElement.nativeElement.querySelector("[zwibbler]")!;
    // console.log("zwibblerdiv is ", zwibblerDiv);
    
    Zwibbler.controller("MyController", function (ctx) {
      //Colours available in the colour palette
      ctx.colours = [
          "#000",
          "#f7412d",
          "#47b04b",
          "#1194f6",
          "#ffc200",
          "#9d1bb2",
          "#ec1561",
          "#7a5547"
      ];

      //Start with black, default brush size of 4
   //ctx.ctx.useFreehandTool(ctx.colours[0], 4);
  //  ctx.ctx.useLineTool({
  //   lineWidth: 3, lockAspectRatio:false},{singleLine: true})
  // ctx.ctx.useLineTool({
  //   lineWidth: 8, strokeStyle:'#FFFF00',opacity:'0.7',lockAspectRatio:false},{singleLine: true})
    });
    
    const ret = Zwibbler.attach(zwibblerDiv, {});
    const ctx = ret.ctx;
   
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      ctx.setConfig("background", "clear");
      ctx.setConfig("showHints", false);
      ctx.setConfig("setFocus", false);
      ctx.setConfig("scrollbars",false);
      ctx.focus(false);
      if(this.useHighlighter){
        ctx.useLineTool({
          lineWidth: 24, strokeStyle:'#FFFF00',opacity:'0.3',lockAspectRatio:false},{singleLine: true})
      }
      if(this.useLine){
        ctx.useLineTool({
          lineWidth: 3, strokeStyle:'#3298DC', lockAspectRatio:false},{singleLine: true})
      }
    }
    else{
      ctx.setConfig("setFocus", true);
      ctx.setConfig("background", "grid");
      ctx.setConfig("showHints", true);
      ctx.focus(true);
     
  
    }
    ctx.setConfig("pageBorderColour","rgba(0,0,0,0.0)")
    ctx.setConfig("autoPickTool", false);
    ctx.setConfig("showColourPanel", false);
    ctx.setConfig("showPropertyPanel", false); // good for troubleshooting the available properties
    ctx.setConfig("showPageSelectorControls",true);
    ctx.setConfig("autoPickToolText", false);
    ctx.setConfig("allowTextInShape", false); // BUG: this does not seem to be working
    ctx.setConfig("allowResize", true); // this should only be applied to stamp and text maybe?
    ctx.setConfig("multilineText", true); 
    ctx.setConfig("defaultFontSize", 18); // this should only be applied to stamp and text maybe?
    ctx.setConfig("outsidePageColour", '#ffffff');
    ctx.setConfig("pageShadow", false);
    // ctx.setPaperSize(width, height);
   
    ctx.on("nodes-added", function(nodes) {
      // console.log("Added %s nodes", nodes.length);
      // lockSize
    });
   
    ctx.on("hint", function(text) {
      console.log("hint event is called")
      ctx.addToLanguage("en:click-to-place-first-point-of-line:Click to place first point of line")
      ctx.addToLanguage("en:click-to-place-another-point-or-double-click-to-end-the-line:Click once to finish drawing, or double-click to place a point and continue drawing.")
    });

    ctx['setColor'] = (colour:any) => {
      ctx.setColour(colour, false);
      ctx.setColour(colour, true);
    }

    ctx['renderShapeFillConfig'] = (config:any) => {
      const colour = ctx.getFillColour();
      // config.fillStyle = 'transparent';
      return ctx['renderDashesConfig'](config);
    }
  
    ctx['renderShapeOutlineConfig'] = (config:any) =>{
      const colour = ctx.getFillColour();
      config.strokeStyle = colour;
      config.fillStyle = 'transparent';
      
      return ctx['renderDashesConfig'](config);
    }
  
    ctx['renderDashesConfig'] = (config:any) =>{
      if (this.isDashesEnabled){
        config.dashes = '6,3'
      }
      else{
        config.dashes = null;
      }
      return config;
    }

    ctx['setColor']('#000000');

    this.ctx = ret.ctx;
    this.ctx.resize();  
    
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      this.setPageToQuestion();
    }

  }

  isDashesEnabled:boolean;
  setDashes(isDashesEnabled:boolean){
    this.isDashesEnabled = isDashesEnabled;
    const config = this.ctx['renderDashesConfig']({});
    this.ctx.setToolProperty('dashes', config.dashes);
  }

  completed(){
    this.pngFile = this.ctx.save("png"); 
  }

}
