import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LangService } from '../core/lang.service';

interface ISlugCalendarEntries {
    weekdays: Array<string>,
    months: Array<string>,
}

@Injectable()
export class SlugCalendarService extends NgbDatepickerI18n {
    constructor(private lang: LangService) { 
        super();
        const weekdays = [];
        const months = [];

        months.push(lang.tra('lbl_jan'), 
            lang.tra('lbl_feb'), 
            lang.tra('lbl_mar'), 
            lang.tra('lbl_apr'), 
            lang.tra('lbl_may'), 
            lang.tra('lbl_jun'),
            lang.tra('lbl_jul'),
            lang.tra('lbl_aug'),
            lang.tra('lbl_sep'),
            lang.tra('lbl_oct'),
            lang.tra('lbl_nov'),
            lang.tra('lbl_dec'));
    
        weekdays.push(lang.tra('lbl_sun'),
            lang.tra('lbl_mon'),
            lang.tra('lbl_tue'),
            lang.tra('lbl_wed'),
            lang.tra('lbl_thu'),
            lang.tra('lbl_fri'),
            lang.tra('lbl_sat'));
        
        this.slugCalendarEntries = {months, weekdays};
    }

    slugCalendarEntries: ISlugCalendarEntries;

    getWeekdayShortName(weekday: number): string { 
        return this.slugCalendarEntries.weekdays[weekday - 1]; 
    }

    getMonthShortName(month: number): string {
        return this.slugCalendarEntries.months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

