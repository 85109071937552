import { Component, OnInit, Input } from '@angular/core';
import { IContentElement, ElementType } from '../models';

@Component({
  selector: 'element-render',
  templateUrl: './element-render.component.html',
  styleUrls: ['./element-render.component.scss']
})
export class ElementRenderComponent implements OnInit {

  @Input() contentElement: IContentElement;
  @Input() isLocked: boolean;
  @Input() questionState: any;

  ElementType = ElementType;

  constructor() { }

  ngOnInit() {
  }

}
