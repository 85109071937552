export enum AccountType {
    TEST_ADMIN   = 'test-admin',
    TEST_ADMIN_INVIG = 'mpt-test-admin-invig',
    DIST_ADMIN   = 'dist-admin',
    TEST_TAKER   = 'test-taker',
    TEST_CTRL    = 'test-ctrl',
    TEST_CTRLD   = 'test-ctrl-data',
    CERT_BODY    = 'test-cert',
    SUPPORT      = 'support',
    TEST_AUTH    = 'test-auth',
    
    MRKG_CTRL    = 'mrkg-ctrl',
    MRKG_SUPR    = 'mrkg-supr',
    MRKG_MRKR    = 'mrkg-mrkr',
    MRKG_CAND    = 'mrkg-cand',
    MRKG_UPLD    = 'mrkg-upld',

    MRKG_COORD = 'mrkg-coord',
    MRKG_LEAD = 'mrkg-lead',
}
