export interface ICustomTaskSet {
  __id?: string;
  __backup__id?: string;
  __backup__userDisplayName?: string;
  name?: string;
  order?: number;
  taskId?: string; // cross link to collection `tasks`
  project?: string;
  isReady?: boolean;
  isProtected?: boolean;
  labelCounter?: number;
  isArchived?: boolean;
  questions?: IQuestionConfig[] | string;
  creatorUid?: string;
  timeCreated?: any;
  timeLastSaved?: any;
  schemaVersion?: number;
  lastTouchedBy?: string;
  assessmentFrameworkId?: string;
}

export interface ICustomTaskTag {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdBy: string;
  timestamp: any;
}

export interface ICustomTaskComment {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdByName: string;
  createdByUid: string;
  timestamp: any;
}

export interface IQuestionConfig {
  id?: number;
  content: IContentElement[];
  label: string;
  isReady?: boolean;
  isInfoSlide?: boolean;
  isNotScored?: boolean;
  notes?: string;
  taskId?: string;
  localTaskId: number;
  entryIdCounter?: number;
  entryOrder?: number[];
  quadrantFreq?: number;
  estimatedExposure?: number;
  langLink: IQuestionConfig;
  voiceover: {
    url?: string
    script?: string
    fileType?: string
  },
  __changeCounter?:number, // only used for meta changes at first
  meta: any,
  comments?: any[];
}
export interface IContentElement {
  elementType: string | ElementType;
  isShowAdvancedOptions?: boolean;
  entryId?: number;
  _changeCounter?: number;
  assetId?: number;
  assetVersionId?: number;
}
export interface IScoredResponse {
  entryId?: number;
  scoreWeight?: number;
}
export interface IContentElementSbs extends IContentElement {
  left: IContentElement[];
  right: IContentElement[];
}
export interface IContentElementGraphing extends IContentElement {
  availableTools: {[key: string]: boolean};
}
export interface IContentElementGraphics extends IContentElement {
  url?: string;
}
export interface IContentElementText extends IContentElement {
  caption: string;
  paragraphStyle: TextParagraphStyle;
  simpleList: string[];
  advancedList: IContentElement[];
}
export interface IContentElementTable extends IContentElement {
  grid: Array<
    Array<
      IContentElementTableCell
    >
  >;
  isHeaderRow: boolean;
  isHeaderCol: boolean;
  isColWidthConst: boolean;
  isTableOfValues?: boolean;
  colWidthConst: number;
}
export interface IContentElementTableCell {
  val: string;
  elementType?: string;
  align?: string;
}

export enum TextParagraphStyle {
  HEADLINE = 'headline',
  HEADLINE_SMALL = 'headline_small',
  REGULAR = 'regular',
  SMALL = 'small',
  BULLET = 'bullet',
  NUMBERED = 'numbered',
  ADVANCED_INLINE = 'advanced-inline',
}

export enum InputFormat {
  NUMBER = 'number',
  FRACTION = 'fraction',
  ALGEBRA = 'algebra',
  RATIO = 'ratio',
  TEXT = 'text',
}

export type QuestionState = Map<number, any>;

// <option>Headline</option>
// <option>Regular</option>
// <option>Small</option>
// <option>Bullet List</option>
// <option>Numbered List</option>
// <option>Advanced Inline</option>

export interface IContentElementMath extends IContentElement {
  latex: string;
  paragraphStyle: string;
}
export interface IContentElementImage extends IContentElement {
  url?: string;
  fileType?: string;
  altText?: string;
  scale?: number;
}


export interface IContentElementVideo extends IContentElement {
  url: string;
  fileType?: string;
  altText?: string;
  subtitlesUrl?: string;
}
export interface IContentElementIframe extends IContentElement {
  url: string;
}
export interface IContentElementDnd extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  defaultTargetStyle: IContentElementDndSub;
  backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementDndTarget>;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
}

export interface IContentElementGroup extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  defaultTargetStyle: IContentElementDndSub;
  backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementGroupTarget>;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
  isProportionalScoring: boolean;
  isInstructionsDisabled: boolean;
  isMatchingMode: boolean;
  isVerticalLayout: boolean;
}

export interface IDndGroup {
  id: number;
  caption: string;
}
export enum DndTargetType {
  DRAGGABLE = 'DRAGGABLE',
  TARGET = 'TARGET',
  GROUP = 'GROUP',
}
export interface IContentElementDndSub {
  id?: number;
  x: number;
  y: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderThickness?: string;
  borderRadius?: number;
  padding?: number;
  verticalAlignment?: string;
  horizontalAlignment?: string;
}
export interface IContentElementDndBackground extends IContentElementDndSub {
  element: IContentElement;
}
export interface IContentElementDndDraggable extends IContentElementDndSub {
  element: IContentElement;
  targetId?: number;
  groupId?: number;
}
export interface IContentElementDndTarget extends IContentElementDndSub {
  draggableId?: number;
  groupId?: number;
}

export interface IContentElementGroupTarget extends IContentElementDndSub {
  draggableId?: number;
  groupId?: number;
  groups?: any[];
}

export interface IContentElementOrder extends IContentElement, IScoredResponse {
  displayStyle: McqDisplay;
  options: Array<IContentElementOrderOption>;
  delimeter: string;
  isScrambled: boolean;
  delimiter?: any;
}
export interface IContentElementOrderOption {
  optionId: number;
  elementType: string;
  content: string;
}

export interface IContentElementMcq extends IContentElement, IScoredResponse {
  customInstructions: string;
  displayStyle: McqDisplay;
  options: Array<IContentElementMcqOption>;
  gridCellWidth?: number;
  gridNumColumns?: number;
  isContentsCentered?: boolean;
  isMultiSelect?: boolean;
  isSelectAllMessage?: boolean;
  isSelectToggle?: boolean;
  isScrambled?: boolean;
  isLimittedWidth?: boolean;
  isRadioDisabled?: boolean;
  isOptionLabelsDisabled?: boolean;
  isSelectAllMessageDisabled?: boolean;
  isRadioLetterDisabled ?: boolean;
  isGlobalFill?: boolean;
}
export interface IContentElementMcqOption {
  optionId: number;
  elementType: string;
  content: string;
  url?: string;
  voiceover?: any;
  isCorrect?: boolean;
}

export enum McqDisplay {
  VERTICAL = 'vertical',
  GRID = 'grid',
  HORIZONTAL = 'horizontal',
  WRAP = 'wraparound',
  DROPDOWN = 'dropdown',
}
export interface IRatioTermConfig {
  value: string;
}
export enum NumberTolerance {
  ALTVALUE = 'altvalue',
  DECIMALS = 'decimals',
  MINMAX = 'minmax',
  PROXPERC = 'proxperc', // proximity by percentage
}
export interface INumberTolerance {
  toleranceTypeId: NumberTolerance;
  numDecimals?: number;
  min?: number;
  max?: number;
  proxPerc?: number;
}
export interface IContentElementInput extends IContentElement, IScoredResponse {
  format: InputFormat;
  latex?: string;
  startingLatex?: string;
  tolerances?: INumberTolerance[];
  value?: string;
  prefix?: string;
  suffix?: string;
  minChars?: number;
  maxChars?: number;
  alternativeValues?: IContentElementInput[];
  ratioTerms?: string[];
  isStrictLowestTerms?: boolean;
  isMixedNumber?: boolean;
  roundingTolerance?: string;
  fracWholeNumber?: string;
  fracNumerator?: string;
  fracDenominator?: string;
  isStrictSimplified?: boolean;
  isAllowEquivalent?: boolean;
}

export interface IElementTypeDef {
  id: ElementType | string;
  caption: string;
  icon: string;
  isInteractive?: boolean;
  isDisabled?: boolean;
  feedProps?: {[key: string]: any}; // used for the dropdown creators where the block type is overloaded and the config is what differentiates it
}

export enum ElementType {
  TEXT = 'text',
  MATH = 'math',
  IMAGE = 'image',
  MCQ = 'mcq',
  DND = 'dnd',
  ORDER = 'order',
  GRAPHING = 'graphing',
  TABLE = 'table',
  INPUT = 'input',
  VIDEO = 'video',
  AUDIO = 'audio',
  MIC = 'mic',
  CAMERA = 'camera',
  UPLOAD = 'upload',
  HOTSPOT = 'hotspot',
  HOTTEXT = 'hottext',
  MATCHING = 'matching',
  READER = 'reader',
  IFRAME = 'iframe',
  SBS = 'sbs',
  GRAPHICS = 'Graphics',
  GROUPING = 'Grouping'
}

export const ElementTypeDefs = {
  SBS:     {id: ElementType.SBS,     caption: 'SBS',   icon: 'fa-columns'},
  TEXT:    {id: ElementType.TEXT,    caption: 'Text',  icon: 'fa-font'},
  TABLE:   {id: ElementType.TABLE,   caption: 'Table', icon: 'fa-table'},
  MATH:    {id: ElementType.MATH,    caption: 'Math',  icon: 'fa-pencil-alt'},
  IMAGE:   {id: ElementType.IMAGE,   caption: 'Image', icon: 'fa-image'},
  VIDEO:   {id: ElementType.VIDEO,   caption: 'Video', icon: 'fa-file-video'},
  AUDIO:   {id: ElementType.AUDIO,   caption: 'Audio', isDisabled: false,  icon: 'fa-file-audio'},
  INPUT:   {id: ElementType.INPUT,   caption: 'Keyboard Input',   isInteractive: true, isDisabled: false, icon: 'fa-keyboard'},
  MCQ:     {id: ElementType.MCQ,     caption: 'Multiple Choice',  isInteractive: true, icon: 'fa-bars'},
  ORDER:   {id: ElementType.ORDER,   caption: 'Ordering',         isInteractive: true, icon: 'fa-sort'},
  GRAPHING: {id: ElementType.GRAPHING, caption: 'Geometry',         isInteractive: true, isDisabled: false, icon: 'fa-paint-brush'},
  DND:     {id: ElementType.DND,     caption: 'Drag & Drop',      isInteractive: true, isDisabled: true, icon: 'fa-hand-pointer-o'},
  HOTSPOT: {id: ElementType.HOTSPOT, caption: 'Hot Spot',         isInteractive: true, isDisabled: true, icon: 'fa-asterisk'},
  HOTTEXT: {id: ElementType.HOTTEXT, caption: 'Hot Text',         isInteractive: true, isDisabled: true, icon: 'fa-commenting'}, // include features such as: click any word to highlight, include instructions above (which use the highlighting styling), click any word to capitalize it, drag in a comma (into any open space), semi-colon or other punctuation, drag out a punctuation to throw it away.
  MATCHING: {id: ElementType.MATCHING, caption: 'Matching',         isInteractive: true, isDisabled: false,     icon: 'fa-compress'}, // like equation attack ... https://h5p.org/image-pairing
  READER:  {id: ElementType.READER,  caption: 'Reader',           isDisabled: true,    icon: 'fa-bars'}, // like the liseuse in French
  MIC:     {id: ElementType.MIC,     caption: 'Microphone',       isInteractive: true, isDisabled: false, icon: 'fa-microphone'},
  CAMERA:  {id: ElementType.CAMERA,  caption: 'Camera',           isInteractive: true, isDisabled: false, icon: 'fa-camera'},
  UPLOAD:  {id: ElementType.UPLOAD,  caption: 'File Upload',      isInteractive: true, isDisabled: false, icon: 'fa-upload'},
  GRAPHICS: {id: ElementType.GRAPHICS, caption: 'Graphics',         isInteractive: true, isDisabled: true, icon: 'fa-image'},
  GROUPING: { id: ElementType.GROUPING, caption: 'Grouping', isInteractive: true, isDisabled: false, icon: 'fa-object-group' },
  PAINTING: {id: ElementType.TEXT,    caption: 'Painting',  icon: 'fa-font'},
  SCULPTING: {id: ElementType.TEXT,    caption: 'Sculpting',  icon: 'fa-font'},
 };

export const elementTypes: IElementTypeDef[] = [
  ElementTypeDefs.TEXT,
  ElementTypeDefs.TABLE,
  ElementTypeDefs.MATH,
  ElementTypeDefs.IMAGE,
  ElementTypeDefs.GRAPHICS,
  ElementTypeDefs.VIDEO,
  ElementTypeDefs.AUDIO,
  ElementTypeDefs.INPUT,
  ElementTypeDefs.MCQ,
  ElementTypeDefs.ORDER,
  ElementTypeDefs.GRAPHING,
  // ElementTypeDefs.DND,
  // ElementTypeDefs.MATCHING,
  ElementTypeDefs.GROUPING,
  // ElementTypeDefs.HOTSPOT,
  // ElementTypeDefs.HOTTEXT,
  ElementTypeDefs.MIC,
  ElementTypeDefs.CAMERA,
  ElementTypeDefs.UPLOAD,
  ElementTypeDefs.READER,
  ElementTypeDefs.SBS,
];

export interface IEntryState {
  type: string;
  isCustomGrading?: boolean;
  isCorrect?: boolean;
  isStarted: boolean;
  isFilled: boolean;

}

export const getElementWeight = (element: IScoredResponse) => {
  const weight = element.scoreWeight;
  if (weight === 0) { return weight; }
  if (!weight) { return 1; }
  return weight;
};

export enum ScoringTypes {
  AUTO= 'AUTO',
  REVIEW= 'REVIEW',
  MANUAL= 'MANUAL'
}
export interface IEntryStateScored extends IEntryState {
  score: number;
  weight: number;
  scoring_type: ScoringTypes;
}

export interface IEntryStateMcq extends IEntryStateScored {
  selections: Array<{
    i: number,
    id: number,
    elementType: string,
    content: any
  }>;

  alreadyScrambled: boolean;
  isGlobalFill?: boolean;
}

export interface IEntryStateGroup extends IEntryStateScored {
  draggables: any[];
  targets: any[];
}

export interface IEntryStateCamera extends IEntryStateScored {
  url?: string;
}
export interface IEntryStateMic extends IEntryStateScored {
  url?: string;
}

export interface IEntryStateInputNumber extends IEntryStateScored {
  value: string;
}
export interface IEntryStateInputMath extends IEntryStateScored {
  latex: string;
}
export interface IEntryStateInputText extends IEntryStateScored {
  str: string;
}
export interface IEntryStateInputFraction extends IEntryStateScored {
  wholenumber: number;
  numerator: number;
  denominator: number;
  reducedValue?: number;
}
export interface IEntryStateInputRatio extends IEntryStateScored {
  terms: number[];
}

export interface IEntryStateGraphing extends IEntryStateScored {
  state: any;
}

export interface IEntryStateGraphics extends IEntryState {
  state: any;
}
