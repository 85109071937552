<div *ngIf="isDisplayStyleDropdown()" class="select">
  <select [formControl]="dropdownSelector" >
    <option *ngFor="let optionElement of element.options; let i = index" [value]="i" >
      {{optionElement.content}}
      <!-- <div [ngSwitch]="optionElement.elementType">
          <div *ngSwitchCase="'text'">
            <markdown-inline [input]="optionElement.content"></markdown-inline>
          </div> -->
    </option>
  </select>
</div>
<div *ngIf="!isDisplayStyleDropdown()">
  <div *ngIf="element.isMultiSelect && (element.isSelectAllMessage || !element.isSelectAllMessageDisabled)" class="small-instruction">
    <tra slug="lbl_select_all_apply"></tra>
  </div>
  <div *ngIf="element.isMultiSelect && element.customInstructions" class="small-instruction">
    {{element.customInstructions}}
  </div>
  <div 
    class="option-container"
    [class.is-limitted-width]="element.isLimittedWidth"
    [class.is-vertical]="element.displayStyle==='vertical'"
    [class.is-horizontal]="element.displayStyle==='horizontal'"
    [class.is-grid]="element.displayStyle==='grid'"
    [class.is-wraparound]="element.displayStyle==='wraparound'"
    [class.is-locked]="isLocked"
    [class.is-no-option-radio-letter] ="element.isRadioLetterDisabled"
    [class.is-no-option-radio] ="element.isRadioDisabled"
    [class.is-no-option-indicator]="element.isOptionLabelsDisabled"
    [ngStyle]="getOptionsContainerStyle()"
  >
  
    <div 
      *ngFor="let optionElement of element.options; let index = index"
      class="option-button-container"
    >
      <button 
        class="option" 
        [class.is-active]="isSelected(optionElement)"
        [class.is-incorrect]="isSelected(optionElement) && isLocked && !optionElement.isCorrect"
        [class.is-correct]="isSelected(optionElement) && isLocked && optionElement.isCorrect"
        [class.is-missed]="!isSelected(optionElement) && isLocked && optionElement.isCorrect"
        (click)="selectOptionManual(optionElement)" 
        [ngStyle]="getButtonContainerStyle(optionElement)"
        [class.is-centered]="element.isContentsCentered"
      >
        <div [ngSwitch]="optionElement.elementType">
          <div *ngSwitchCase="'text'">
            <div *ngIf="isAdvTextEl(optionElement)">
              <element-render-text [element]="optionElement"></element-render-text>  
            </div>
            <div *ngIf="!isAdvTextEl(optionElement)" style="white-space: pre-wrap;">
              <markdown-inline [input]="optionElement.content"></markdown-inline>
            </div>
          </div>
          <div *ngSwitchCase="'table'">
            <element-render-table [element]="optionElement"></element-render-table>
          </div>
          <div *ngSwitchCase="'image'">
            <render-image [element]="optionElement"></render-image>
          </div>
          <div *ngSwitchCase="'math'">
            <render-math [obj]="optionElement" [prop]="'content'"></render-math>
          </div>
        </div>

        <div *ngIf="optionElement.voiceover && optionElement.voiceover.url">
          <render-audio [url]="optionElement.voiceover.url" [trigger]="getClickTrigger(optionElement)" [isTriggerDisabled]="!isVoiceoverEnabled()"></render-audio>
        </div>
  
        <div class="option-indicator">
          <div class="radio-label">
            {{buttonLabels[index]}}
          </div>
          <div class="radio-container">
            <div class="radio-outer"></div>
            <div class="radio-inner"></div>
          </div>
        </div>
  
      </button>
      <div *ngIf="isLocked && optionElement.isCorrect" style="font-size:0.8em; padding-left: 2em; margin-bottom:1em;">
        <tra slug="lbl_correct_answer"></tra>: <b>{{buttonLabels[index]}}</b>
      </div>
    </div>
  </div>      
</div>
