import { ITestDesignPayload } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";

export interface IAssessmentFrameworkDef {
    __id?: string,
    projectId?: string,
    caption: string,
    subcaption?: string,
    uidCreator?: string,
    uidLastTouch?: string, 
    timeCreated?: any,
    timeLastTouch?: any,
}
export interface IQuadrantConstraint { // only equality
    param: string,
    val: string,
}
export enum TestletConstraintFunction {
    MATCH = 'MATCH',
    VARIETY = 'VARIETY',
    AVG = 'AVG',
    STDEV = 'STDEV',
}
export interface ITestletConstraintCommon {
    param: string, 
    isMin: boolean,
    isMax: boolean,
    isEqual: boolean,
    // isProportion: boolean,
}
export interface ITestletConstraint_MATCH extends ITestletConstraintCommon {
    val: string | number,
    count: number,
}
export interface ITestletConstrain_VARIETY extends ITestletConstraintCommon {
    count: number,
}
export interface ITestletConstrain_AVG extends ITestletConstraintCommon {
    val: number,
}
export interface ITestletConstrain_STEDEV extends ITestletConstraintCommon {
    val: number,
}
export interface ITestletConstraint {
    weight: number,
    func: TestletConstraintFunction,
    config: ITestletConstraint_MATCH | 
            ITestletConstrain_VARIETY | 
            ITestletConstrain_AVG | 
            ITestletConstrain_STEDEV,
}
export interface IQuadrantTestletConfig {
    numItems: number,
    discardThreshold: number,
    triesBeforeFail: number,
    constraints: ITestletConstraint[],
}
export interface IQuadrant {
    id: number, // dont show to user
    section: number,
    constraints: IQuadrantConstraint[],
    description: string,
    config: IQuadrantTestletConfig,
}



export interface IQuadrantTestlet {
    id: number, // dont show to user
    section: number,
    quadrant: number,
    isReviewed?: boolean,
    statsMap: {[key:string]:number | string},
    stats: Array<{key:string, val:number | string}>,
    isModified?: boolean,
    quality: number,
    questions: {
        label: string,
        [key:string]: any,
    }[],
}

export enum TestFormConstructionMethod {
    LINEAR = 'LINEAR',
    TLOFT = 'TLOFT',
    MSCAT = 'MSCAT',
}

export interface IPanelAssemblyConfig {
    startingModule: string,
    allowNumCorrect?: boolean,
    allowShuffle?: boolean,
    numStages: number,
    uniqueSpecificExpectationCode?: boolean,
    maxDifficultyLevel?:number,
    easyStartSettings: {
        numberOfItems: number,
        prop: string,
        isInverse: boolean,
    },
    allModules: Array<{
        id: string,
        stageNumber: number,
        difficultyLevel?: number,
        item_count: number,
        difficulty_range: number[], 
        item_difficulty_range?: number[], 
    }>,
    routingRules: {
        [key:string]: Array<IRoutingRule>
    },
    constraints: Array<{
        when: Array<{
            moduleProp: string,
            comparison: string,
            value: number
        }>,
        require: Array<{
            prop: string,
            method: string,
            comparison: string,
            config: {
                value?:string,
                count?:number,
            }
        }>
    }>
}

export interface IPanelModulesConfig {
    id: number,
    // overallAgreement?: number,
    dateCreated: string,
    modules: {
        moduleId: number,
        itemLabels: string[],
        // difficulty?:number,
    }[]
}
export interface IAssessmentFrameworkDetail extends IAssessmentFrameworkDef{
    notes?: string;
    parititionIdAI: number,
    quadrantIdAI: number,
    quadrants: IQuadrant[],
    sectionItems?: {
        [key:number]: {
            questions: {
                label: string,
                id?: number,
            }[]
        }
    }
    panelAssembly?: IPanelAssemblyConfig,
    panels?: IPanelModulesConfig[],
    quadrantItems?: {
        id: number,
        questions: {
            label: string
        }[],
        numTestlets?:number,
        exposureMin?:number,
        exposureMax?:number,
        numItemsUsed?:number,
        description:string,
        minQRequired:number,
        crossLinkedItems:{
            label:string, 
            quadrantsCrossLinks: any[]
        }[],
        // [key:string]: any,
    }[],
    testlets?: IQuadrantTestlet[],
    testletCounter?: number,
    testForms?: ITestDesignPayload[],
    partitions? : IAssessmentPartition[],
    estimatedTestTakers? : number,
    isExposureComputed? : boolean,
    helpPageId? : number,
    referenceDocumentPages? : {itemId:number, caption:string}[],
    isTimerDisabled? : boolean,
    testFormType? : TestFormConstructionMethod,
    testletStatCol? : Array<{
        isShown:boolean,
        key: string,
    }>,
    primaryDimensions:IAssessmentFrameworkDimensionDetail[],
    secondaryDimensions: IAssessmentFrameworkDimensionDetail[]
}
export interface IRoutingRule { 
    module: string, 
    minTheta:number,
    maxTheta:number,
    minPropC: number, // >=
    maxPropC: number, // <
}

export interface IAssessmentPartition {
    id: number,
    description: string,
    preambleQuestionLabel?: string,
    isShuffled?: boolean,
    isCalculatorEnabled?: boolean,
    isFormulaSheetEnabled?: boolean,
    isTimeLimit?: boolean,
    isConditional?: boolean,
    timeLimitMinutes?:number,
    conditionOnItem?:string,
    conditionOnOption?:string,
}

export enum DimensionType {
    SELECT = 'select-sub',
    BINARY = 'binary',
    NUMERIC = 'numeric',
} 
;

export interface IAssessmentFrameworkDimensionDetail {
    name: string, // ex: Big Ideas
    code: string, // ex: D1
    type: DimensionType,
    color?: string,
    isHidden?: boolean,
    config: {
        special?: {
            [key:string]: boolean,
        },
        tags? : Array<{
            name: string, // ex: Number Sense
            code: string, // ex: NS
        }>,
    }
}