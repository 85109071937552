import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';

export enum EAppealType {
  attempt = 'attempt',
  session = 'session'
}
@Injectable({
  providedIn: 'root'
})
export class AllowAppealsService {

  public sysAllow: boolean = undefined;
  private sysAllowPromise;

  constructor(private auth: AuthService,
    private routes: RoutesService) {
      this.auth.user().subscribe((userInfo) => {
        if(userInfo !== null) {
          this.sysAllowPromise = this.auth.apiFind(this.routes.TEST_TAKER_ALLOW_APPEALS).then((res) => {
            if(!res) {
              this.sysAllow = false;
            } else {
              this.sysAllow = true;
            }
            return this.sysAllow;
          });
        }
      })
    }

  async updateSysAllowAppeals() {
    this.sysAllow = await this.sysAllowPromise;
  }
}
