import {ILeaderSelectionItem} from './data';

enum ItemType {
    SYSTEM = 'SYSTEM', // comes from the system itself, authoring => test taking.
    SCAN = 'SCAN',
}

export const leaderItems: ILeaderSelectionItem[] = [
    {
        "item": {
            "id": 1,
            "name": "Dairy Farm",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 30
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    },
    {
        "item": {
            "id": 2,
            "name": "Maple Syrup",
            "type": ItemType.SCAN,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 37
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    },
    {
        "item": {
            "id": 3,
            "name": "Olympics",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 150
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    },
    {
        "item": {
            "id": 4,
            "name": "Wastewater",
            "type": ItemType.SYSTEM,
            "response_count": 1000,
            "responses_expected": 2000,
            "marked_count": 16
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    },
    {
        "item": {
            "id": 5,
            "name": "Salmon Run",
            "type": ItemType.SYSTEM,
            "response_count": 1200,
            "responses_expected": 2000,
            "marked_count": 0
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 0,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    },
    {
        "item": {
            "id": 6,
            "name": "Totem Poles",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 2000
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            },
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false
            }
        ]
    }
];
