import { Component, OnInit, Input } from '@angular/core';
import { ElementType } from '../../ui-testrunner/models';

@Component({
  selector: 'element-config',
  templateUrl: './element-config.component.html',
  styleUrls: ['./element-config.component.scss']
})
export class ElementConfigComponent implements OnInit {

  @Input() contentElement;

  constructor() { }

  ElementType = ElementType;

  ngOnInit() {
  }

}
