import { Component, OnInit, Input } from '@angular/core';
import { IContentElementText, TextParagraphStyle, QuestionState, IContentElementTable } from '../models';

@Component({
  selector: 'element-render-table',
  templateUrl: './element-render-table.component.html',
  styleUrls: ['./element-render-table.component.scss']
})
export class ElementRenderTableComponent implements OnInit {

  @Input() element:IContentElementTable;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  constructor() { }

  ngOnInit() {
  }

  checkHeaderByIndex(i:number){
    if (this.element.isHeaderRow && i === 0){
      return true;
    }
  }

  checkColHeaderByIndex(i:number){
    if (this.element.isHeaderCol && i === 0){
      return true;
    }
  }

  parseCellAlignment(align:string){
    switch(align){
      case 'left': return 'left';
      case 'right': return 'right';
      default:
      case 'center': return 'center';
    }
  }

  getColWidth(col_i:number){
    if (this.element.isColWidthConst && this.element.colWidthConst){
      return this.element.colWidthConst;
    }
  }

}
