import {
    IDay,
    IItem,
    IItemResponse,
    ILeaderSelectionItem,
    IMarkingItem,
    IMarkingSession,
    randomScore,
    randomSelection
} from '../data';
import {literacyAssessment, numeracyAssessment} from './assessments';
import {getLiteracyLeaders, getNumeracyLeaders} from '../accounts/leaders';
import {getLiteracyMarkers, getNumeracyMarkers} from '../accounts/markers';
import {markingResponsesByItemId, responsesByItemId} from './responses';

const days: IDay[] = [
    {index: 1, date: '2020-07-27'},
    {index: 2, date: '2020-07-28'},
    // {index: 3, date: '2020-07-29T09:00:00.000Z'},
    // {index: 4, date: '2020-07-30T09:00:00.000Z'},
    // {index: 5, date: '2020-07-31T09:00:00.000Z'},
    // {index: 6, date: '2020-08-01T09:00:00.000Z'},
    // {index: 7, date: '2020-08-02T09:00:00.000Z'},
    // {index: 8, date: '2020-08-03T09:00:00.000Z'},
    // {index: 9, date: '2020-08-04T09:00:00.000Z'},
    // {index: 10, date: '2020-08-05T09:00:00.000Z'},
];

export const numeracyMarkingSession: IMarkingSession = {
    id: 1,
    days: days,
    start_date: '2020-08-28T13:00:00.000Z',
    end_date: '2020-09-01T13:00:00.000Z',

    active_training: 1,
    assessments: [numeracyAssessment],

    leaders: getNumeracyLeaders(),
    markers: getNumeracyMarkers(),
    name: 'Grade 10 Sample Graduation Numeracy Assessment - Form A | Marking Session',
    notes: '',
    unique_items: numeracyAssessment.items.length
};
export const literacyMarkingSession: IMarkingSession = {
    id: 2,
    days: days,
    start_date: '2020-08-28T13:00:00.000Z',
    end_date: '2020-09-01T13:00:00.000Z',

    active_training: 1,
    assessments: [literacyAssessment],

    leaders: getLiteracyLeaders(),
    markers: getLiteracyMarkers(),
    name: 'Grade 10 Sample Graduation Literacy Assessment | Marking Session',
    notes: '',
    unique_items: literacyAssessment.items.length
};

export const allMarkingSessions: IMarkingSession[] = [numeracyMarkingSession, literacyMarkingSession];

export function markingSessionsByUID(uid: number) {
    switch (uid) {
        case 6267:
        case 6268:
        case 6269:
        default:
            return [literacyMarkingSession, numeracyMarkingSession];
            // console.error('Invalid UID');
            // return [];
    }
}
export function itemsByMarkingSessionId(id: number) {
    for (let i = 0; i < allMarkingSessions.length; i++) {
        if (id === allMarkingSessions[i].id) {
            let items: IItem[] = [];
            allMarkingSessions[i].assessments.forEach(assessment => {
                items = items.concat(assessment.items);
            });
            return items;
        }
    }
}

export function leaderS1ItemsByMarkingSessionId(id: number) {
    let items: ILeaderSelectionItem[] = [];
    for (let i = 0; i < allMarkingSessions.length; i++) {
        if (+id === +allMarkingSessions[i].id) {
            allMarkingSessions[i].assessments.forEach(assessment => {
                items = items.concat(itemsToStageItem(assessment.items, Stage.S1));
            });
            break;
        }
    }
    return items;
}
export function markingItemsByMarkingSessionId(id: number) {
    let items: IMarkingItem[] = [];
    for (let i = 0; i < allMarkingSessions.length; i++) {
        if (+id === +allMarkingSessions[i].id) {
            allMarkingSessions[i].assessments.forEach(assessment => {
                items = items.concat(itemsToMarkingItem(assessment.items));
            });
            break;
        }
    }
    return items;
}


export function leaderS2ItemsByMarkingSessionId(id: number) {
    let items: ILeaderSelectionItem[] = [];
    for (let i = 0; i < allMarkingSessions.length; i++) {
        if (+id === +allMarkingSessions[i].id) {
            allMarkingSessions[i].assessments.forEach(assessment => {
                items = items.concat(itemsToStageItem(assessment.items, Stage.S2));
            });
            break;
        }
    }
    return items;
}
export function leaderS3ItemsByMarkingSessionId(id: number) {
    let items: ILeaderSelectionItem[] = [];
    for (let i = 0; i < allMarkingSessions.length; i++) {
        if (+id === +allMarkingSessions[i].id) {
            allMarkingSessions[i].assessments.forEach(assessment => {
                items = items.concat(itemsToStageItem(assessment.items, Stage.S3));
            });
            break;
        }
    }
    return items;
}
/*
export function leaderS1ItemByItemId(id: number) {
    let item: ILeaderSelectionItem;
    for (let i = 0; i < allMarkingSessions.length; i++) {
        allMarkingSessions[i].assessments.forEach(assessment => {
            for (let j = 0; j < assessment.items.length; j++) {
                if (+assessment.items[j].id === +id) {
                    item = itemToS1(assessment.items[j]);
                    return item;
                }
            }
        });
    }
    // return item;
}*/

enum Stage {
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
}

function itemsToMarkingItem(items: IItem[]): IMarkingItem[] {
    const markingItems: IMarkingItem[] = [];
    items.forEach((item: IItem) => {
        const markerItem: IMarkingItem = itemToMarkingItem(item);
        markingItems.push(markerItem);
    });
    return markingItems;
}

function itemsToStageItem(items: IItem[], stage: Stage): ILeaderSelectionItem[] {
    const leaderItems: ILeaderSelectionItem[] = [];
    items.forEach((item: IItem) => {
        let leaderItem: ILeaderSelectionItem;
        switch (stage) {
            case Stage.S1:
                leaderItem = itemToS1(item);
                break;
            case Stage.S2:
                leaderItem = itemToS2(item);
                break;
            case Stage.S3:
                leaderItem = itemToS3(item);
                break;
            default:
                leaderItem = itemToS1(item);
                break;
        }
        leaderItems.push(leaderItem);
    });
    return leaderItems;
}

function itemToMarkingItem(item: IItem) {
    const responses = markingResponsesByItemId(item.id);
    return {item, responses};
}

function itemToS1(item: IItem) {
    const responses = responsesByItemId(item.id);
    return {item, isOffSiteSet: false, isOnSiteSet: false, isReliabilitySet: false, isScoredDone: false, isSelectedDone: false, responses};
}
function itemToS2(item: IItem) {
    const responses = responsesByItemId(item.id).map((resp: IItemResponse) => {
        const s2Resp: IItemResponse = {
            ... resp,
            selection: randomSelection()
        };
        return s2Resp;
    });
    return {item, isOffSiteSet: false, isOnSiteSet: false, isReliabilitySet: false, isScoredDone: false, isSelectedDone: false, responses};
}
function itemToS3(item: IItem) {
    const responses = responsesByItemId(item.id).map((resp: IItemResponse) => {
        const s3Resp: IItemResponse = {
            ... resp,
            selection: randomSelection(),
            score: randomScore()
        };
        return s3Resp;
    });
    return {item, isOffSiteSet: false, isOnSiteSet: false, isReliabilitySet: false, isScoredDone: false, isSelectedDone: false, responses};
}


