
<div
#elPosContainer
class="pos-container"
>
  <div cdkDropListGroup class="">
    <div >
      <div
        style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
        cdkDropList
        class="drag-drop-list drag-drop-source mb-3"
        [class.is-empty]="draggables.length == 0 "
        [cdkDropListData]="draggables"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event, true)">
        <div class="drag-el" *ngFor="let elPos of draggables" cdkDrag style="margin-right: 10px;">
            <element-render
                *ngIf="elPos.ref.element"
                [contentElement]="elPos.ref.element"
                [isLocked]="true"
                [questionState]="{}"
            ></element-render>
        </div>
      </div>
    </div>

    <div *ngIf="!element.isInstructionsDisabled" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;"  > 
      <tra-md slug="txt_default_drag_instr"></tra-md>
    </div>
    <div class="group-container" [class.is-vertical]="element.isVerticalLayout">
      <div class="drag-drop-container" *ngFor="let target of targets; index as idx">
        <div class="group-title">
          <element-render
              *ngIf="target.targetContext.element"
              [contentElement]="target.targetContext.element"
              [isLocked]="true"
              [questionState]="{}"
          ></element-render>
        </div>

        <div
          cdkDropList
          [cdkDropListData]="target.contents"
          class="drag-drop-list"
          style="flex-wrap: wrap; flex-direction: row;"
          (cdkDropListDropped)="drop($event, false)">
          <div class="drag-el" *ngFor="let assignedElement of target.contents" cdkDrag style="margin: 10px;">
              <element-render
                  *ngIf="assignedElement.ref.element"
                  [contentElement]="assignedElement.ref.element"
                  [isLocked]="true"
                  [questionState]="{}"
              ></element-render>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>