import { Component, OnInit, Input } from '@angular/core';
import { IContentElement, QuestionState } from '../models';

@Component({
  selector: 'element-render-upload',
  templateUrl: './element-render-upload.component.html',
  styleUrls: ['./element-render-upload.component.scss']
})
export class ElementRenderUploadComponent implements OnInit {

  @Input() element:IContentElement;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;

  file = {}

  constructor() { }

  ngOnInit() {
  }

}
