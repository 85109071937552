import { Injectable } from '@angular/core';
import { IContentElement, IContentElementText, ElementType, IContentElementTable, IContentElementMath, IContentElementImage, IContentElementMcq, TextParagraphStyle, IQuestionConfig, IContentElementTableCell, IContentElementMcqOption, IContentElementGroup } from '../ui-testrunner/models';
import { LangService } from '../core/lang.service';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { StylingProcess, processText, StyleprofileService, removeLeadingEllipses} from '../core/styleprofile.service';

// const OPTION_TEXT_EN = 'Option'
export interface IScriptGenMeta {
  optionScripts:string[],
  useOldScripts?:boolean,
  useOldScriptsDecision?:boolean,
}

const optionLetters = 'ABCDEFGHIJKLMNOP'.split('')



@Injectable({
  providedIn: 'root'
})
export class ScriptGenService {
  constructor(
    private auth:AuthService,
    private routes:RoutesService,
    private lang: LangService,
    private profile: StyleprofileService
  ) { }

  numUploadsStarted:number;
  numUploadsCompleted:number;

  autoGenQuestionVoiceover(question:IQuestionConfig, lang:string='en', isOverridesDisabled:boolean=false){
    this.numUploadsStarted = 0;
    this.numUploadsCompleted = 0;
    let optionScripts = [];
    // try to pull manual overrides on the question text
    if (question.voiceover && question.voiceover.script){
      let lastLineWithOption = null;
      let hasNonAdjacentLines = false;
      question.voiceover.script.split('\n').forEach((lineStr, i) => {
        const optionLineStart = 'Option "';
        if (lineStr.substr(0, optionLineStart.length) === optionLineStart){
          if (lastLineWithOption && lastLineWithOption !== i-1){
            hasNonAdjacentLines = true;
          }
          optionScripts.push(lineStr);
          lastLineWithOption = i;
        }
      });
      if (!isOverridesDisabled){
        if (hasNonAdjacentLines){
          if (!confirm('The option text looks a little more complex than usual, and some text might have been missed. Would you still like to apply manual overrides from the main text to the option text?')){
            optionScripts = [];
          }
        }
        else if (optionScripts.length > 0){
          if (!confirm('Would you like to apply manual overrides from the main text to the option text?')){
            optionScripts = [];
          }
        }
      }
    }
    const meta:IScriptGenMeta = {optionScripts}; 
    if (isOverridesDisabled){
      meta.useOldScriptsDecision = true;
      meta.useOldScripts = true;
    }
    // compute the script from the child nodes
    let script = this.extractScriptFromNodes(question.content, lang, meta, []);
    
    if(this.profile.getStyleProfile()[lang].voiceScript.general.removeBeginningEllipses) {
      script = removeLeadingEllipses(script);
    }

    if (!question.voiceover){
      question.voiceover = {};
    }
    question.voiceover.script = script;
    return script;
  }

  private extractScriptFromNodes(nodes:IContentElement[], lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[], delim:string='\n', pauseAroundExpression:boolean=false) {
    let script = "";
    if(!nodes) {
      return "";
    }
    for(let i = 0; i < nodes.length; i++) {
      if(pauseAroundExpression && i !== 0 && nodes[i].elementType === ElementType.MATH && 
        nodes[i - 1].elementType === ElementType.TEXT) {
          script += " ... ";
        }
      script += this.extractScriptFromNode(nodes[i], lang, meta, preProcesses);
      if(pauseAroundExpression && i !== nodes.length - 1 && nodes[i].elementType === ElementType.MATH && 
        nodes[i + 1].elementType === ElementType.TEXT) {
        script += ` ... ${delim}`
      } else {
        script += delim;
      }
    }

    return script;
  }
  private extractScriptFromNode(node:IContentElement, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    switch(node.elementType){
      case ElementType.TEXT: return this.extractScriptFromTextNode( <IContentElementText> node, lang, meta, preProcesses);
      case ElementType.TABLE: return this.extractScriptFromTableNode( <IContentElementTable> node, lang, meta);
      case ElementType.MATH: return this.extractScriptFromMathNode( <IContentElementMath> node, lang);
      case ElementType.IMAGE: return this.extractScriptFromImageNode( <IContentElementImage> node, lang, meta);
      case ElementType.MCQ: return this.extractScriptFromMcqNode( <IContentElementMcq> node, lang, meta, preProcesses);
      case ElementType.GROUPING: 
      case ElementType.MATCHING: 
        return this.extractScriptFromGroupingNode( <IContentElementGroup> node, lang, meta );
    }
  }

  extractScriptFromGroupingNode(node:IContentElementGroup, lang:string, meta:IScriptGenMeta) {
    // const response = [];
    // response.push('Draggable options. ')
    // node.draggables.forEach((draggable, i) => {
    //   response.push('Option 1. '+ this.extractScriptFromNode(draggable.element, lang, meta)); 
    // })
    // response.push('Draggable options. ')
    // node.draggables.forEach((draggable, i) => {
    //   response.push('Option 1. '+ this.extractScriptFromNode(draggable.element, lang, meta)); 
    // })
    return '';
  }

  private extractScriptFromTextNode(node:IContentElementText, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    switch(node.paragraphStyle){
      case TextParagraphStyle.ADVANCED_INLINE:
        const pauseAroundExpression = this.profile.getStyleProfile()[lang].voiceScript.advancedInline.pauseAroundExpression;
        return this.extractScriptFromNodes(node.advancedList, lang, meta, preProcesses, ' ', pauseAroundExpression);
      case TextParagraphStyle.BULLET:
      case TextParagraphStyle.NUMBERED:
        if (node.simpleList.length > 0){
          return node.simpleList.map( str => this.processPlainTextScript(str, lang, preProcesses)).join('\n')
        }
        else if (node.advancedList){
          return this.extractScriptFromNodes(node.advancedList, lang, meta, preProcesses)
        }
        else{
          return '';
        }
      case TextParagraphStyle.HEADLINE:
      case TextParagraphStyle.HEADLINE_SMALL:
      case TextParagraphStyle.REGULAR:
      case TextParagraphStyle.SMALL:
      default:
        return this.processPlainTextScript(node.caption, lang, preProcesses);
    }
  }

  private processPlainTextScript(str:string, lang:string, preProcesses: StylingProcess[]) {
    return processText(str, preProcesses.concat(<StylingProcess[]>this.profile.getStyleProfile()[lang].voiceScript.plainText));
  }

  private extractScriptFromTableCell(cell:IContentElementTableCell, i_row:number, i_col:number, node:IContentElementTable, lang:string, meta:IScriptGenMeta, inverted:boolean) {
    const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
    const firstColumnIsHeader = node.isHeaderCol;
    const firstRowIsHeader = node.isHeaderRow;

    let rowColNumber = (inverted ? i_row : i_col) + 1; //we should actually be looking at the rows if inverted
    
    const isHeaderCell = (i_row === 0 && firstRowIsHeader) || (i_col === 0 && firstColumnIsHeader);
    
    if(!isHeaderCell && tableProfile.onlyReadHeaderCells) {
      return [];
    }

    const preProcesses = isHeaderCell ? tableProfile.headerProcesses : [];
    
    let str = cell.elementType ? this.extractScriptFromNode(<IContentElement> cell, lang, meta, preProcesses) : this.processPlainTextScript(cell.val, lang, preProcesses);
    
    if(isHeaderCell && tableProfile.onlyReadHeaderCells) {
      str += " ... "
    }
    
    let cellScript = [];

    if(!tableProfile.onlyReadHeaderCells) {
      cellScript.push(` ... ${this.lang.tra(inverted ? tableProfile.beginRow : tableProfile.beginColumn, lang)} ${rowColNumber} ... `);
    }
    cellScript.push(str);
    
    return cellScript;
  }

  private extractScriptFromTableNode(node:IContentElementTable, lang:string, meta:IScriptGenMeta) {
    const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
    
    const firstColumnIsHeader = node.isHeaderCol;
    const firstRowIsHeader = node.isHeaderRow;

    let script = [];
    const beginTableSlug = node.isTableOfValues ? tableProfile.beginTableValues : tableProfile.beginTable;
    script.push(`${this.lang.tra(beginTableSlug, lang)} ... `);

    if(firstColumnIsHeader && tableProfile.columnHeaderReadRowsFirst && node.grid.length > 0) {
      for(let c = 0; c < node.grid[0].length; c++) {
        let cellScript = [];
        for(let r = 0; r < node.grid.length; r++) {
          cellScript = cellScript.concat(this.extractScriptFromTableCell(node.grid[r][c], r, c, node, lang, meta, true));
        }

        let colNumber = c + 1;
        if(!tableProfile.onlyReadHeaderCells) {
          script.push(` ... ${this.lang.tra(tableProfile.beginColumn, lang)} ${colNumber} ... `);
        }
        script = script.concat(cellScript)

      }
    } else {
      node.grid.forEach( (row:IContentElementTableCell[], i_row) => {
        let cellScript = [];
        row.forEach((cell:IContentElementTableCell, i_col) => {
          cellScript = cellScript.concat(this.extractScriptFromTableCell(cell, i_row, i_col, node, lang, meta, false));
        });
  
        let rowNumber = i_row + 1;
        if(!tableProfile.onlyReadHeaderCells) {
          script.push(` ... ${this.lang.tra(tableProfile.beginRow, lang)} ${rowNumber} ... `);
        }
        script = script.concat(cellScript)
      });
    }

    const endTableSlug = node.isTableOfValues ? tableProfile.endTableOfValues : tableProfile.endTable;
    if(endTableSlug) {
      script.push(` ... ${this.lang.tra(endTableSlug, lang)} ... `);
    }
    return script.join(' ');
  }

  private extractScriptFromMathNode(node:IContentElementMath, lang:string) {
    let latex = node.latex || (<any>node).content; // second alt is for mcq math
    
    if(!latex) {
      latex = "";
    }
    return processText(latex, <StylingProcess[]>this.profile.getStyleProfile()[lang].voiceScript.math);
  }

  private extractScriptFromImageNode(node:IContentElementImage, lang:string, meta:IScriptGenMeta) {
    return node.altText;
  }
  public extractScriptFromMcqNode(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    let scriptParts:string[] = [];
    node.options.forEach((option, i) => this.extractScriptFromMcqOptionNode(node, lang, meta, option, i, scriptParts, preProcesses) );
    return scriptParts.join('\n')
  }

  public extractScriptFromMcqNodeAsync(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    let scriptParts:string[] = [];
    return Promise.all(
      node.options.map((option, i) => this.extractScriptFromMcqOptionNode(node, lang, meta, option, i, scriptParts, preProcesses) )
    )
  }

  public extractScriptFromMcqOptionNode(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, option:IContentElementMcqOption, i:number, scriptParts:string[], preProcesses:StylingProcess[]) {
    const mcqProfile = this.profile.getStyleProfile()[lang].voiceScript.mcq;

      // save the script into the option voice slot
      if (!option.voiceover){
        option.voiceover = {};
      }
      let script;
      switch (option.elementType){
        case 'text':
          let elText:IContentElementText = <any> option;
          if (elText.paragraphStyle){
            script = this.extractScriptFromNode(option, lang, meta, preProcesses);
          }
          else{
            script = this.processPlainTextScript(option.content, lang, preProcesses);
          }
        break;
        default: script = this.extractScriptFromNode(option, lang, meta, preProcesses); break;
      }
      const returnOptionsScript = (optionLetter)=>{ script = `${this.lang.tra(mcqProfile.beginOption, lang)} ` +optionLetter+`. ${script} ...`;}
      
      if(!node.isOptionLabelsDisabled){
        returnOptionsScript(`${optionLetters[i]}`)
      } else{
        returnOptionsScript('');
      }

      if (option.voiceover.script && !meta.useOldScriptsDecision){
        meta.useOldScripts = confirm('Some of the options already have some script generated inside. Would you want to use the old script?');
        meta.useOldScriptsDecision = true;
      }
      
      if (!meta.useOldScripts && meta.optionScripts.length > 0){
        const injectedScript = meta.optionScripts.splice(0,1)[0];
        option.voiceover.script = injectedScript
      }
      else if (option.voiceover.script && meta.useOldScripts){
        if (script !== option.voiceover.script){
          console.warn('Option script has been modified from original recording', [script, option.voiceover.script])
        }
      }
      else {
        option.voiceover.script = script;
      }
      scriptParts.push(option.voiceover.script);
      return this.uploadNewVoice(option.voiceover.script, option.voiceover, lang);
  }

  uploadNewVoice(script, element:{url?: string, fileType?:string}, lang){
    return new Promise((resolve, reject) => {
      return this.auth
        .apiCreate(
          this.routes.TEST_AUTH_TEXT_VOICE,
          { script, lang }
        )
        .then((res:{url:string})=> {
          element.url = res.url;
          element.fileType = 'audio/mp3';
          setTimeout(() => {
            resolve();
          }, 500)
        })
    });
  }

}
