import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AuthService, IUserInfo, F_ERR_MSG__INVALID_LOGIN, F_ERR_MSG__INVALID_EMAIL , F_ERR_MSG__NO_PASSWORD} from '../../api/auth.service';
import { LangService } from '../../core/lang.service';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';

interface ILoginFormErrors {
  isBlank?:boolean,
  isInvalid?: boolean,
}

enum FormFail {
  EMAIL,
  PASSWORD,
  NOT_FOUND,
  NOT_VERIFIED,
  UNKNOWN,
  PSW_RESET_REQ,
}

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  @Input() accountType:AccountType;
  @Input() prefilledEmail:string;
  @Output() onLoginAttempt = new EventEmitter();

  public breadcrumb = [];
  public isFormValidated:boolean;
  public isFormSent:boolean;
  public isFormFailed:boolean;
  public formFailReason:FormFail;
  public routeSub:Subscription;
  public formGroupEmailAddress = new FormControl();
  public formGroupPassword = new FormControl();
  public formGroup = new FormGroup({
    emailAddress: this.formGroupEmailAddress,
    password: this.formGroupPassword,
  })
  public FormFail = FormFail;
  private formSubs:Subscription[] = [];
  public signIn = this.lang.tra('sign_in')

  constructor(
    private lang: LangService,
    private auth: AuthService,
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private route:ActivatedRoute,
    private scrollService:ScrollService,
  ) {

  }
  
  ngOnInit() {
    const onFormValueChange = (r) => this.isFormSent = false;
    this.formSubs = this.formSubs.concat([
      (<FormControl>this.formGroup.controls.emailAddress).valueChanges.subscribe(onFormValueChange),
      (<FormControl>this.formGroup.controls.password).valueChanges.subscribe(onFormValueChange)
    ])
    if (this.prefilledEmail){
      this.formGroup.controls.emailAddress.setValue(this.prefilledEmail);
    }

  }

  ngOnDestroy() {
    this.formSubs.forEach(sub => sub.unsubscribe() );
  }

  getMainHeader(){
    switch(this.accountType){
      case AccountType.CERT_BODY: return 'page_login_h1_cb';
      case AccountType.TEST_ADMIN: return 'page_login_h1_ta';
      case AccountType.TEST_CTRL: return 'page_login_h1_tc';
      case AccountType.TEST_TAKER: return 'page_login_h1_tt';
      default: return 'page_login_h1'
    }
  }

  getMainInstruction(){
    switch(this.accountType){
      case AccountType.CERT_BODY: return 'instr_login_cb';
      case AccountType.TEST_ADMIN: return 'instr_login_test_admin';
      case AccountType.TEST_CTRL: return 'instr_login_tc';
      case AccountType.TEST_TAKER: return 'instr_login_tt';
      default: return ''
    }
  }

  getCurrentAccountType(){
    return this.accountType || AccountType.TEST_TAKER;
  }

  getForgotPasswordRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/forgot-password`
  }
  
  getAccountCreationRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/create-account`
  }

  isUsingSebSoftware(){
    return navigator.userAgent.match(/SEB/i) ? true: false
  }
  
  validateForm(){
    // TO DO: a lot of this validation makes even more sense to have in the registration area, abstract these functions
    
    this.isFormValidated = false; 
    let hasAnyErrors = false;
    const email = <FormControl>this.formGroup.controls.emailAddress;
    const password = <FormControl>this.formGroup.controls.password;
    let emailErrors:ILoginFormErrors = {}
    let passwordErrors:ILoginFormErrors = {}
    if (!email.value){
      emailErrors.isBlank = true;
      hasAnyErrors = true;
    }
    else{
      const str = <string>email.value;
      const isMissingAtSign = str.indexOf('@') === -1;
      const isTooShort = str.length < 3;
      if (isMissingAtSign || isTooShort){
        emailErrors.isInvalid = true;
        hasAnyErrors = true;
      }
    }
    
    if (!password.value){
      passwordErrors.isBlank = true;
      hasAnyErrors = true;
    }
    else{
      const str = <string>password.value;
      if (str.length < 3){ // should be 8, but I need to type in my password
        this.onApiError({ message: F_ERR_MSG__INVALID_LOGIN });
        return
      }
    }
    if(emailErrors.isInvalid || emailErrors.isBlank){
      this.onApiError({message: F_ERR_MSG__INVALID_EMAIL});
      return;
    }
    // email.setErrors(emailErrors);
    // password.setErrors(passwordErrors);
    if(passwordErrors.isBlank){
      this.onApiError({message:F_ERR_MSG__NO_PASSWORD });
      return;
    }
    this.formGroup.markAsUntouched();
    if (hasAnyErrors){
      this._onApiError()
      return; 
    }
    // const controls = [email, password];
    this.formGroup.disable();
    this.isFormValidated = true; 
  }

  isLoginTroubleshooting:boolean;
  loginErrorTroubleshootMsg;
  onApiError(e:{message:string}){
    this.formGroup.enable();
    this.isFormValidated = false;
    this.isFormFailed = true;
    if (e.message == 'NOT_VERIFIED'){
      this.formFailReason = FormFail.NOT_VERIFIED;
    }
    else if(e.message == F_ERR_MSG__INVALID_EMAIL){
      this.formFailReason = FormFail.EMAIL
    }
    else if(e.message == F_ERR_MSG__NO_PASSWORD){
      this.formFailReason = FormFail.PASSWORD
    }
    else if (e.message == F_ERR_MSG__INVALID_LOGIN){
      this.formFailReason = FormFail.NOT_FOUND;
    }
    else{
      this.formFailReason = FormFail.UNKNOWN;
    }

    // easier troubleshooting
    try {
      this.loginErrorTroubleshootMsg = JSON.stringify(e, null, '  ');
    }
    catch(e){
      this.loginErrorTroubleshootMsg = e.code +' '+ e.message;
    }
    // console.log('formFailReason', this.formFailReason)
  }
  private _onApiError(){
    this.formGroup.enable();
    this.isFormValidated = false;
  }

  isInputBlank(control:FormControl){
    return control.untouched && control.hasError('isBlank');
  }

  isInputInvalid(control:FormControl){
    return control.untouched && control.hasError('isInvalid');
  }

  submitForm(){
    this.isFormFailed = false;
    this.validateForm();
    if (this.isFormValidated){
      this.isFormSent = true;
      const email = this.formGroup.controls.emailAddress.value;
      const password = this.formGroup.controls.password.value;
      this.onLoginAttempt.emit()
      // console.log('validateForm', email, password)
      this.auth
        .login( email, password )
        .then(success => {
          if (!success){
            this._onApiError();
            this.clearForm();
          }
        })
        .catch(e => {
          this.onApiError(e);
          this.formGroup.controls.password.reset();
          this.auth.apiCreate('public/log', {
            slug: 'FAILED_LOGIN',
            data: {
              email, // password, // todo: remove this
              errorMessage: e.message
            }
          })
        })
    }
  }

  clearForm(){
    this.formGroup.controls.emailAddress.reset();
    this.formGroup.controls.password.reset();
  }

}
