import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  private _isActive:boolean = false;

  public get isActive() : boolean {
    return this._isActive;
  }
  

  constructor() { }

  toggle(){
    this._isActive = !this._isActive;
  }
}
