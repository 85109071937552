import { arrayToHash } from "@fullcalendar/core/util/object";

// https://raw.githubusercontent.com/dominictarr/random-name/master/first-names.json
// https://raw.githubusercontent.com/dominictarr/random-name/master/names.json

export const r = (names:string[]) => {
    return () => {
        return names[~~(Math.random()*names.length)]
    }
}
export const randomFirstName   = r(require('./firstnames.json'));
export const randomLastName    = r(require('./lastnames.json'));
export const randomName = () => {
    return randomFirstName() + ' ' +randomLastName();
}
export const randomIdentity = () => {
    const firstName = randomFirstName()
    const lastName = randomLastName();
    return {
        firstName, lastName, 
        name: firstName+' '+lastName,
        email: firstName.toLowerCase()[0] + lastName.toLowerCase() + '@uni.ca'
    }
}


// var g = () => {
//     return names[~~(Math.random()*names.length)]
// }
// var arr = []
// for (var i=0; i<100; i++){
//     arr.push(g())
// }
// console.log(JSON.stringify(arr))