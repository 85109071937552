import {IItem, ItemType, MarkingType} from '../data';

export const numeracyItems: IItem[] = [
    {id: 1, name: 'Dairy Farm', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/G98j8pK.png'},
    {id: 2, name: 'Maple Syrup', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/VZLAFku.png'},
    {id: 3, name: 'Olympics', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/1zmcbsW.png'},
    {id: 4, name: 'Wastewater', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/PDtQSql.png'},
    {id: 5, name: 'Salmon Run', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/lHYGMqJ.png'},
    {id: 6, name: 'Totem Poles', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/Owz3FYo.png'},
    // {id: 7, name: '7.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/I5pcavH.png'},
    // {id: 8, name: '8.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/JKDpIOc.png'},
    // {id: 9, name: '9.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/E0Fzxco.png'},
    // {id: 10, name: '10.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/rwqufEQ.png'},
    // {id: 11, name: '11.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/RCHfXtM.png'},
    // {id: 12, name: '12.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/oNUe6um.png'},
    // {id: 13, name: '13.', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/9zonmpL.png'},
    // {id: 14, name: '14.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/m8wg0Kl.png'},
    // {id: 15, name: '15.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/6KwrziL.png'},
    // {id: 16, name: '16.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/MBNurnA.png'},
    // {id: 17, name: '17.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/fxyMJmr.png'},
    // {id: 18, name: '18.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/xL9b0it.png'},
    // {id: 19, name: '19.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/xIF1J3l.png'},
    // {id: 20, name: '20.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/PIfxE13.png'},
    // {id: 21, name: '21.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/0O6HOe9.png'},
    // {id: 22, name: '22.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/n5eQLGO.png'},
    // {id: 23, name: '23.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/7eAcxNJ.png'},
    // {id: 24, name: '24.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/JhGWXLQ.png'},
    // {id: 25, name: '25.', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/mRtOctD.png'},
    // {id: 26, name: '26.', type: ItemType.SCAN, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/q7b4L5p.png'},
];
export const literacyItems: IItem[] = [
    // A
    {id: 27, name: 'Question A-1', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/QOQMn46.png'},
    {id: 28, name: 'Question A-2', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/D4Ogmvz.png'},
    {id: 29, name: 'Question A-3', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/obownC3.png'},
    {id: 30, name: 'Question A-4', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/HIqsesC.png'},
    {id: 31, name: 'Question A-5', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/PV8CedZ.png'},
    // {id: 32, name: 'Question A-6', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/8gLAKBx.png'},
    // {id: 33, name: 'Question A-7', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/GVgMJOx.png'},
    // {id: 34, name: 'Question A-8', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/ddb1AbD.png'},
    // {id: 35, name: 'Question A-9', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/aqAwlYl.png'},
    // {id: 36, name: 'Question A-10', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/F2uhDov.png'},
    // {id: 37, name: 'Question A-11', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/GWMs1r3.png'},
    // {id: 38, name: 'Question A-12', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/8WGkGX4.png'},
    // {id: 39, name: 'Question A-13', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/bUReRlP.png'},
    // {id: 40, name: 'Question A-14', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/D0AjAeM.png'},
    // {id: 41, name: 'Question A-15', type: ItemType.TEXT_TABLE, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/CG64DOT.png'},
    // {id: 42, name: 'Question A-16', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/v0ACDf2.png'},
    // B
    {id: 43, name: 'Question B-1', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/uKeBAzM.png'},
    {id: 44, name: 'Question B-2', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/Cjhi1Oz.png'},
    {id: 45, name: 'Question B-3', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/vCtX5ZZ.png'},
    {id: 46, name: 'Question B-4', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/d5F446q.png'},
    {id: 47, name: 'Question B-5', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/xFttmzR.png'},
    // {id: 48, name: 'Question B-6', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/IU2u384.png'},
    // {id: 49, name: 'Question B-7', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/sCqdhiY.png'},
    // {id: 50, name: 'Question B-8', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/e9DMPxY.png'},
    // {id: 51, name: 'Question B-9', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/i4zx2EA.png'},
    // {id: 52, name: 'Question B-10', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/EwVTruI.png'},
    // {id: 53, name: 'Question B-11', type: ItemType.SYSTEM, response_count: 100, responses_expected: 100, marked_count: 100, image_url: 'https://i.imgur.com/ZQnzbRV.png'},
    // {id: 54, name: 'Question B-12', type: ItemType.AUDIO, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/SfIBA3k.png'},
    // {id: 55, name: 'Question B-13', type: ItemType.AUDIO, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/IEldtTN.png'},
    // {id: 56, name: 'Question B-14', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/fkLQnhC.png'},
    // {id: 57, name: 'Question B-15', type: ItemType.TEXT, response_count: 100, responses_expected: 100, marked_count: 0, image_url: 'https://i.imgur.com/4wQvFBn.png'},
];
