import {getRandomInt, IItem, IItemResponse, IMarkingItemResponse, IMarkingScore, IStudent, ItemType} from '../data';
import {literacyItems, numeracyItems} from './items';
import {students} from '../accounts/students';

const DUMMY_TEXT = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus id interdum velit laoreet id donec ultrices tincidunt. Cum sociis natoque penatibus et. Eu mi bibendum neque egestas congue quisque. Pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Nulla facilisi cras fermentum odio eu feugiat. Vulputate dignissim suspendisse in est. Massa id neque aliquam vestibulum morbi blandit cursus risus at. Amet est placerat in egestas. Ut ornare lectus sit amet est placerat in egestas. Senectus et netus et malesuada fames ac. Lectus nulla at volutpat diam ut venenatis tellus. Egestas sed sed risus pretium quam vulputate dignissim. Ultrices sagittis orci a scelerisque purus. Egestas maecenas pharetra convallis posuere. Cursus in hac habitasse platea dictumst quisque sagittis. Diam sollicitudin tempor id eu nisl. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Gravida arcu ac tortor dignissim convallis aenean et tortor at. Feugiat in fermentum posuere urna nec tincidunt. Sed odio morbi quis commodo odio aenean sed adipiscing. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Porta nibh venenatis cras sed.';
const DUMMY_TEXT_ARRAY = DUMMY_TEXT.split(' ');

const sampleScans = ['https://s3.ca-central-1.amazonaws.com/eassessment.vretta.com/samples/scan002.jpg', 'https://s3.ca-central-1.amazonaws.com/eassessment.vretta.com/samples/scan003.jpg'];
function randomSampleScan() {
    const index = getRandomInt(0, 1);
    return sampleScans[index];
}
function randomText() {
    const l = DUMMY_TEXT_ARRAY.length;
    const a = getRandomInt(0, 50);
    const b = getRandomInt(l - 50, l - 1);
    const newArr = DUMMY_TEXT_ARRAY.slice(a, b);
    return newArr.join(' ');
}
function randomTextTable() {
    // 5
    const arr = [randomText(), randomText(), randomText(), randomText(), randomText()];
    return JSON.stringify({values: arr});
}
function randomAudio() {
    return 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/voice/1588341024808/voice.wav';
}

function createResponse(responseId: number, item: IItem, student: IStudent) {
    let resp: IItemResponse;
    switch (item.type) {
        case ItemType.SYSTEM:
            resp = {id: responseId, raw: 'Machine Scored', uid: student.id, item_id: item.id, selection: null, score: null, status: '', archived: null};
            break;
        case ItemType.SCAN:
            resp = {id: responseId, raw: randomSampleScan(), uid: student.id, item_id: item.id, selection: null, score: null, status: '', archived: null};
            break;
        case ItemType.TEXT:
            resp = {id: responseId, raw: randomText(), uid: student.id, item_id: item.id, selection: null, score: null, status: '', archived: null};
            break;
        case ItemType.TEXT_TABLE:
            resp = {id: responseId, raw: randomTextTable(), uid: student.id, item_id: item.id, selection: null, score: null, status: '', archived: null};
            break;
        case ItemType.AUDIO:
            resp = {id: responseId, raw: randomAudio(), uid: student.id, item_id: item.id, selection: null, score: null, status: '', archived: null};
            break;
    }
    /*
    switch (item.type) {
        case ItemType.SYSTEM:
            resp = {id: responseId, raw: 'Machine Scored', isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            break;
        case ItemType.SCAN:
            resp = {id: responseId, raw: randomSampleScan(), isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            break;
        case ItemType.TEXT:
            resp = {id: responseId, raw: randomText(), isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            break;
        case ItemType.TEXT_TABLE:
            resp = {id: responseId, raw: randomTextTable(), isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            break;
        case ItemType.AUDIO:
            resp = {id: responseId, raw: randomAudio(), isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            break;
    }*/
    return resp;
}

export function allResponses(): { [itemId: number]: IItemResponse[] } {
    let responses: { [itemId: number]: IItemResponse[] } = {};
    const items = numeracyItems.concat(literacyItems);
    items.forEach((item: IItem) => {
        let itemResponses: IItemResponse[] = [];
        students.forEach((student: IStudent, j: number) => {
            // const respId = (item.id) + j;
            const respId = j + 1; // just for demo
            const resp: IItemResponse = createResponse(respId, item, student);
            // const resp = {id: item.id, raw: 'assets/scanned-response.jpg', isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            itemResponses.push(resp);
        });
        responses[item.id] = itemResponses;
    });
    return responses;
}

/*
export function literacyResponses(): { [itemId: number]: IItemResponse[] } {
    let litResponses: { [itemId: number]: IItemResponse[] } = {};
    literacyItems.forEach((item: IItem) => {
        let itemResponses: IItemResponse[] = [];
        students.forEach((student: IStudent) => {
            const resp: IItemResponse = createResponse(item, student);
            // const resp = {id: item.id, raw: DUMMY_TEXT, isReliability: false, isOnSite: false, isOffSite: false, uid: student.id, item_id: item.id};
            itemResponses.push(resp);
        });
        litResponses[item.id] = itemResponses;
    });
    return litResponses;
}*/

export function numeracyResponsesByStudent(id: number) {
    let numResponses: { [itemId: number]: IItemResponse } = {};
    numeracyItems.forEach((item: IItem) => {
        numResponses[item.id] = {id: item.id, raw: 'assets/scanned-response.jpg', isReliability: false, isOnSite: false, isOffSite: false, uid: id, item_id: item.id};
    });
    return numResponses;
}
export function literacyResponsesByStudent(id: number) {
    let litResponses: { [itemId: number]: IItemResponse } = {};
    literacyItems.forEach((item: IItem) => {
        litResponses[item.id] = {id: item.id, raw: DUMMY_TEXT, isReliability: false, isOnSite: false, isOffSite: false, uid: id, item_id: item.id};
    });
    return litResponses;
}

export function responsesByItemId(id: number) {
    const responses = allResponses()[id];
    if (!!responses) {
        return responses;
    }
    return [];
}

export function markingResponsesByItemId(id: number) {
    const responses = allResponses()[id];
    if (!!responses) {
        let markingResponses: IMarkingItemResponse[] = [];
        console.log(responses);
        responses.forEach((resp: IItemResponse)  => {
            markingResponses.push(responseToMarkingResponse(resp));
        });
        return markingResponses;
    }
    return [];
}

function responseToMarkingResponse(response: IItemResponse): IMarkingItemResponse {
    return {
        id: response.id,
        raw: response.raw,
        scores: []
    };
}
