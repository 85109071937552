import { Component, OnDestroy } from '@angular/core';
import { WhitelabelService } from './domain/whitelabel.service';
import { Router, NavigationError, ActivatedRoute, NavigationStart } from '@angular/router';
import { LoginGuardService } from './api/login-guard.service';
import { LangService } from './core/lang.service';
import { Subscription } from 'rxjs';
import { ChatService } from './chat/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  subscription = new Subscription();
  private tabindexMap: Map<HTMLElement, string> = new Map();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    public whiteLabel: WhitelabelService,
    public chatService: ChatService
  ) {
    this.applySiteLock();
    this.setupRouterDefault();
    this.setupConnectionOverlaySubs();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  title = 'app';
  isCertifiedRelease: boolean;
  isCertifiedReleaseInvalidated: boolean;
  isShowingDevNetFailOverlay: boolean;
  isShowingApiNetFailOverlay: boolean;

  confirmationProceedCaption: string;
  confirmationCancelCaption: string;

  currentConfirmation: { caption: string, confirm: () => any, hideCancel?: boolean };

  getLoginReq = () => this.loginGuard.getLoginReq();
  getDevNetFail = () => this.loginGuard.getDevNetFail();
  getApiNetFail = () => this.loginGuard.getApiNetFail();
  getSupportReq = () => this.loginGuard.getSupportReq();
  getAutoLogout = () => this.loginGuard.getAutoLogout();
  getAutoLogoutWarning = () => this.loginGuard.getAutoLogoutWarning();
  refreshLogin = () => this.loginGuard.refreshLogin();

  setupConnectionOverlaySubs() {
    this.subscription.add(this.getDevNetFail().subscribe(v => this.isShowingDevNetFailOverlay = v));
    this.subscription.add(this.getApiNetFail().subscribe(v => this.isShowingApiNetFailOverlay = v));
    this.subscription.add(
      this.loginGuard.getConfirmationReq().subscribe(v => {
        this.trapFocus(v);
        this.currentConfirmation = <any>v;
        if (v) {
          this.confirmationProceedCaption = v.btnProceedCaption || 'OK';
          this.confirmationCancelCaption = v.btnCancelCaption || this.lang.tra('btn_cancel');
        }
      })
    );
  }

  private trapFocus(confirmation: any) {
    const dialogButtons: Element[] = Array.from(document.querySelectorAll('.confirmation-overlay button'));
    if (confirmation && this.tabindexMap.size === 0) {
      document.querySelectorAll('button, input, select, textarea, .button').forEach(el => {
        const htmlElement = el as HTMLElement;
        this.tabindexMap.set(htmlElement, htmlElement.getAttribute('tabindex'));
        if (dialogButtons.indexOf(el) === -1) {
          (el as HTMLElement).setAttribute('tabindex', '-1')
        }
      });
    } else {
      this.tabindexMap.forEach((value, key) => {
        key.setAttribute('tabindex', value);
      });
      this.tabindexMap = new Map();
    }
  }

  applySiteLock() {
    const STORE_ADDR = 'site_lock';
    const currentTimestamp = new Date().valueOf();
    const prevTimestamp = window.localStorage.getItem(STORE_ADDR);
    if (prevTimestamp && currentTimestamp < parseFloat(prevTimestamp)) {
      this.isCertifiedRelease = true;
    }
    this.isCertifiedRelease = true; // comment this out before release for now...

    if (!this.isCertifiedRelease) {
      if (btoa((prompt('Password?') + '').trim().toLowerCase()) === 'dnI4MTI=') { // vr812
        this.isCertifiedRelease = true;
        const goodForSeconds = 30 * 60 * 1000;
        window.localStorage.setItem(STORE_ADDR, '' + (currentTimestamp + goodForSeconds));
      } else {
        this.isCertifiedReleaseInvalidated = true;
      }
    }
  }

  isAuthActive() {
    return this.loginGuard.isAuthActive();
  }

  closeModal() {
    this.loginGuard.confirmationReqDeactivate();
  }
  confirmModal() {
    this.currentConfirmation.confirm();
    this.loginGuard.confirmationReqDeactivate();
  }

  showCancel() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.hideCancel;
    }
  }

  dismissSupportPopup() {
    this.loginGuard.supportReqDeactivate();
  }

  gotoHomeScreen() {
    this.router.navigate([
      this.whiteLabel.getHomepageRoute()
    ]);
  }

  setupRouterDefault() {
    this.route.params.subscribe(params => {
      // console.log(params, params)
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.url) {
        this.lang.updateCurrentUrl(event.url);
        const routePath = event.url.split('/');
        this.lang.setCurrentLanguage(routePath[1]);
      }
      if (event instanceof NavigationError) {
        console.warn('NavigationError', event);
        setTimeout(() => {
          this.gotoHomeScreen();
        }, 100);
      }
    });
  }
}
