<div *ngIf="element.url">
  <!-- <video controls crossorigin="anonymous">
    <source [src]="getVideoUrl()" [type]="element.fileType">
  </video> -->
  <!-- <plyr  plyrTitle="" 
        [plyrPlaysInline]="false" 
        [plyrOptions]="plyrOptions"
        [plyrSources]="getVideoSources()" 
        [plyrTracks]="getSubtitleTracks()" 
        plyrCrossOrigin="anonymous"
  ></plyr> -->
    <!-- <div plyr plyrTitle="" [plyrPlaysInline]="false" [plyrCrossOrigin]="true"
   [plyrSources]="getVideoSources()" [plyrTracks]="getSubtitleTracks()" (plyrInit)="player = $event"
  (plyrPlay)="played($event)"></div> -->
  <video  controls="" width="640" height="480">
    <source type="video/mp4" [src]="getVideoSources()[0].src">
      <track [label]="getSubtitleTracks()[0].label" kind="getSubtitleTracks()[0].kind"  [src]="getSubtitleTracks()[0].src">
    </video>
</div>

