import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SampleQuestionsComponent } from './sample-questions/sample-questions.component';
import { QuestionRunnerComponent } from './question-runner/question-runner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ElementRenderIframeComponent } from './element-render-iframe/element-render-iframe.component';
import { ElementRenderImageComponent } from './element-render-image/element-render-image.component';
import { ElementRenderInputComponent } from './element-render-input/element-render-input.component';
import { ElementRenderMathComponent } from './element-render-math/element-render-math.component';
import { ElementRenderMcqComponent } from './element-render-mcq/element-render-mcq.component';
import { ElementRenderOrderComponent } from './element-render-order/element-render-order.component';
import { ElementRenderTextComponent } from './element-render-text/element-render-text.component';
import { ElementRenderVideoComponent } from './element-render-video/element-render-video.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RenderImageComponent } from './render-image/render-image.component';
import { RenderMathComponent } from './render-math/render-math.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MarkdownModule } from 'ngx-markdown';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UiTransModule } from '../ui-trans/ui-trans.module';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { FormulaSheetComponent } from './formula-sheet/formula-sheet.component';
import { MarkdownInlineComponent } from './markdown-inline/markdown-inline.component';
import { TestRunnerComponent } from './test-runner/test-runner.component';
import { WidgetCalculatorComponent } from './widget-calculator/widget-calculator.component';
import { ElementRenderTableComponent } from './element-render-table/element-render-table.component';
import { ElementRenderDndComponent } from './element-render-dnd/element-render-dnd.component';
import { ElementRenderComponent } from './element-render/element-render.component';
import { ElementRenderMicComponent } from './element-render-mic/element-render-mic.component';
import { ElementRenderCameraComponent } from './element-render-camera/element-render-camera.component';
import { ElementRenderUploadComponent } from './element-render-upload/element-render-upload.component';
import { ElementRenderHotspotComponent } from './element-render-hotspot/element-render-hotspot.component';
import { ElementRenderHottextComponent } from './element-render-hottext/element-render-hottext.component';
import { WidgetMathModule } from '../widget-math/widget-math.module';
import { RenderAudioComponent } from './render-audio/render-audio.component';
import { ElementRenderAudioComponent } from './element-render-audio/element-render-audio.component';
import { ElementRenderReaderComponent } from './element-render-reader/element-render-reader.component';
import { PlyrModule } from 'ngx-plyr';
import { ElementRenderGraphingComponent } from './element-render-graphing/element-render-graphing.component';
import { IoAudioModule } from '../io-audio/io-audio.module';
import { ElementRenderGraphicsComponent } from './element-render-graphics/element-render-graphics.component';
import { ElementRenderSbsComponent } from './element-render-sbs/element-render-sbs.component';
import { ElementRenderMatchingComponent } from './element-render-matching/element-render-matching.component';
import {TestTakerComponent} from '../ui-chat/test-taker/test-taker.component';
import { ElementRenderGroupingComponent } from './element-render-grouping/element-render-grouping.component';
import { ViewConstrRespComponent } from './view-constr-resp/view-constr-resp.component';
import { AngularSplitModule } from 'angular-split';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ElementRenderDrawingComponent } from './element-render-drawing/element-render-drawing.component';
import { ElementRenderTextConstrComponent } from './element-render-text-constr/element-render-text-constr.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MarkdownModule,
        DragDropModule,
        PlyrModule,
        TooltipModule,
        UiPartialModule,
        WidgetMathModule,
        TextFieldModule,
        IoAudioModule,
        FormsModule,
        AngularSplitModule,
        CKEditorModule
    ],
  declarations: [
    SampleQuestionsComponent, 
    QuestionRunnerComponent,
    CheckboxComponent, 
    ElementRenderIframeComponent,
    ElementRenderImageComponent,
    ElementRenderInputComponent,
    ElementRenderMathComponent,
    ElementRenderMcqComponent,
    ElementRenderOrderComponent,
    ElementRenderTextComponent,
    ElementRenderVideoComponent,
    RenderImageComponent,
    RenderMathComponent,
    FormulaSheetComponent,
    MarkdownInlineComponent,
    TestRunnerComponent,
    WidgetCalculatorComponent,
    ElementRenderTableComponent,
    ElementRenderDndComponent,
    ElementRenderComponent,
    ElementRenderMicComponent,
    ElementRenderCameraComponent,
    ElementRenderUploadComponent,
    ElementRenderHotspotComponent,
    ElementRenderHottextComponent,
    RenderAudioComponent,
    ElementRenderAudioComponent,
    ElementRenderReaderComponent,
    ElementRenderGraphingComponent,
    ElementRenderGraphicsComponent,
    ElementRenderSbsComponent,
    ElementRenderMatchingComponent,
    TestTakerComponent,
    ElementRenderGroupingComponent,
    ViewConstrRespComponent,
    ElementRenderDrawingComponent,
    ElementRenderTextConstrComponent,
  ],
    exports: [
        QuestionRunnerComponent,
        TestRunnerComponent,
        RenderImageComponent,
        RenderMathComponent,
        RenderAudioComponent,
        ElementRenderComponent,
        ElementRenderReaderComponent,
        ElementRenderAudioComponent
    ]
})
export class UiTestrunnerModule { }
