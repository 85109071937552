import { PassValConstraint } from "./password-validation";

export enum AUTH_REG_ERROR {
  PASSWORDS_UNMATCHED = 'PASSWORDS_UNMATCHED',
  INVALID_CREDS = 'INVALID_CREDS',
  MULTIPLE_AUTHS = 'MULTIPLE_AUTHS',
  NOT_FOUND = 'NOT_FOUND',
  NOT_VERIFIED = 'NOT_VERIFIED',
  MISSING_JWT = 'MISSING_JWT',
  MAX_LOGIN_ATTEMPT = 'MAX_LOGIN_ATTEMPT',
  EMAIL_IN_USE  = 'EMAIL_IN_USE',
  USED_INVIT    = 'USED_INVIT',
  INVALID_INVIT = 'INVALID_INVIT',
  INVALID_OCT_ID = 'INVALID_OCT_ID',
  OCT_ID_IN_USE = 'OCT_ID_IN_USE',
  ALREADY_CONFIRMED = 'ALREADY_CONFIRMED',
  EXPIRED_CODE = 'EXPIRED_CODE',
  EXPIRED_RESET_TOKEN = 'EXPIRED_RESET_TOKEN',
  ERR_INVALID_CODE = 'ERR_INVALID_CODE',
  PASS_MATCH = 'PASS_MATCH',
  MISSING_PASSWORD = 'MISSING_PASSWORD',
  MISSING_EMAIL = 'MISSING_EMAIL',
  MISSING_FIRSTNAME = 'MISSING_FIRSTNAME',
  MISSING_LASTNAME = 'MISSING_LASTNAME',
  MISSING_TEACHERPROGRAM = 'MISSING_TEACHERPROGRAM',
  MISSING_APPLICANTTYPE = 'MISSING_APPLICANTTYPE',
  MISSING_STUDYLEVEL = 'MISSING_STUDYLEVEL',
  MISSING_FACULTY = 'MISSING_FACULTY',
  MISSING_COUNTRY = 'MISSING_COUNTRY',
  MISSING_PROVINCE = 'MISSING_PROVINCE',
  MISSING_TEACHABLES_1 = 'MISSING_TEACHABLES_1',
  MISSING_TEACHABLES_2 = 'MISSING_TEACHABLES_2',
  MISSING_INVITATION = 'MISSING_INVITATION',
  MISSING_OCT_ID = 'MISSING_OCT_ID',
  EMAIL_MALFORMED = 'EMAIL_MALFORMED',
  TEST_NOT_REQUIRED = 'TEST_NOT_REQUIRED',
}

export const regErrorMsgToTransSlug = (message:string) => {
  switch(message){
    case AUTH_REG_ERROR.EMAIL_IN_USE : return 'txt_account_creation_err_email_in_use';
    case AUTH_REG_ERROR.ERR_INVALID_CODE : return 'txt_invitation_code_invalid';
    case AUTH_REG_ERROR.INVALID_OCT_ID : return 'txt_account_creation_err_invalid_oct_id';
    case AUTH_REG_ERROR.OCT_ID_IN_USE : return 'txt_account_creation_err_oct_id_in_use';
    case AUTH_REG_ERROR.PASS_MATCH : return 'txt_pass_reset_mismatch_pass';
    case AUTH_REG_ERROR.MISSING_PASSWORD : return 'txt_account_creation_err_missing_password';
    case AUTH_REG_ERROR.MISSING_EMAIL : return 'txt_account_creation_err_missing_email';
    case AUTH_REG_ERROR.MISSING_FIRSTNAME : return 'txt_account_creation_err_missing_firstname';
    case AUTH_REG_ERROR.MISSING_LASTNAME : return 'txt_account_creation_err_missing_lastname';
    case AUTH_REG_ERROR.MISSING_TEACHERPROGRAM : return 'txt_account_creation_err_missing_tprog';
    case AUTH_REG_ERROR.MISSING_APPLICANTTYPE : return 'txt_account_creation_err_missing_applicanttype';
    case AUTH_REG_ERROR.MISSING_STUDYLEVEL : return 'txt_account_creation_err_missing_studylevel';
    case AUTH_REG_ERROR.MISSING_FACULTY : return 'txt_account_creation_err_missing_faculty';
    case AUTH_REG_ERROR.MISSING_COUNTRY : return 'txt_account_creation_err_missing_country';
    case AUTH_REG_ERROR.MISSING_PROVINCE : return 'txt_account_creation_err_missing_province';
    case AUTH_REG_ERROR.MISSING_TEACHABLES_1 : return 'txt_account_creation_err_missing_teachables_1';
    case AUTH_REG_ERROR.MISSING_TEACHABLES_2 : return 'txt_account_creation_err_missing_teachables_2';
    case AUTH_REG_ERROR.MISSING_INVITATION : return 'txt_account_creation_err_missing_invitation';
    case AUTH_REG_ERROR.MISSING_OCT_ID : return 'txt_account_creation_err_missing_oct_id';
    case AUTH_REG_ERROR.EMAIL_MALFORMED : return 'txt_account_creation_err_malformed_email';
    case AUTH_REG_ERROR.TEST_NOT_REQUIRED : return 'txt_account_creation_err_not_required';
    case PassValConstraint.PASS_VALC_MIN_CHARS: return 'txt_pass_reset_invalid_pass_min';
    case PassValConstraint.PASS_VALC_MAX_CHARS: return 'txt_pass_reset_invalid_pass_max';
    case PassValConstraint.PASS_VALC_UPPERCASE: return 'txt_pass_reset_invalid_pass_upper';
    case PassValConstraint.PASS_VALC_LOWERCASE: return 'txt_pass_reset_invalid_pass_lower';
    case PassValConstraint.PASS_VALC_NUMBER   : return 'txt_pass_reset_invalid_pass_number';
    case PassValConstraint.PASS_VALC_SYMBOL   : return 'txt_pass_reset_invalid_pass_symbol';
    default: 
      return 'txt_create_account_error';
  }
}