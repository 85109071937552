import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';

@Component({
  selector: 'tra-md',
  templateUrl: './tra-md.component.html',
  styleUrls: ['./tra-md.component.scss']
})
export class TraMdComponent implements OnInit, OnChanges{
  @Input() id: number;
  @Input() slug: string;
  @Input() isInline: boolean;
  @Input() isCondensed: boolean;
  @Input() whitelabel: string; // whitelabel text which needs to be routed to determine what it should be
  @Input() props: {[key:string]: string | number} = {};

  val: string;

  constructor(
    private langService:LangService
  ) { }

  refreshVal() {
    this.val = this.langService.traWithWhitelabel(this.whitelabel, this.slug || this.id, undefined, this.props);
  }
  ngOnInit() {
    this.refreshVal();
    // console.log(this.slug, this.props)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshVal();
  }




}
