import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IQuestionConfig, elementTypes, QuestionState } from '../models/index';
import { FormControl } from '@angular/forms';
import { elementIconById } from '../../ui-item-maker/item-set-editor/models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlLoaderService } from '../url-loader.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../../ui-item-maker/auth-scope-settings.service';

@Component({
  selector: 'question-runner',
  templateUrl: './question-runner.component.html',
  styleUrls: ['./question-runner.component.scss']
})
export class QuestionRunnerComponent implements OnInit, OnChanges {

  @Input() currentQuestion:IQuestionConfig;
  @Input() currentQuestionIndex:number;
  @Input() totalQuestions:number;
  @Input() questionState:any;
  @Input() isSubmitted:boolean;

  questionTextSize = new FormControl(1);
  // isAudioPlayerShowing:boolean;
  questionAudioUrl:SafeResourceUrl;

  constructor(
    private urlLoader: UrlLoaderService,
    private textToSpeech:TextToSpeechService,
    private authScope: AuthScopeSettingsService,
  ) { }

  ngOnInit() {
    
  }
  
  ngOnChanges(changes:SimpleChanges){
    //console.log('Question Runner Change: ', changes, this.currentQuestion)
  }

  isFadeInEntrance(){
    return this.authScope.getSetting(AuthScopeSetting.Q_FADE_IN_ENTRANCE);
  }

  isAudioPlayerShowing(){
    return this.textToSpeech.isActive;
  }

  getQuestionAudioUrl(){
    return this.currentQuestion?.voiceover?.url;
  }

  isInputLocked(){
    return this.isSubmitted;
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  getElementStyle(element, index){
    // could be optimized
    let animDelayIncr = 250;
    let animDuration = 600;
    let animDelay = 0;
    for (let i=1; i<index; i++){
      animDelay += animDelayIncr;
      animDelayIncr *= 0.8;
      animDuration *= 0.8;
    }
    return {
      'animation-delay': animDelay+'ms',
      'animation-duration': animDuration+'ms',
    }
  }

}
