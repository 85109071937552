<div> 
  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isMatchingMode">
      Allow only one option per group?
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isVerticalLayout">
      User vertical layout
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isProportionalScoring">
      Enable proportional scoring?
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isInstructionsDisabled">
      Disable drag instruction text?
    </label>
  </div>

  <hr/>

  <div class="element-group-header">
    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
    Draggable Elements
  </div>
    <div 
      cdkDropList
      [cdkDropListDisabled]="isReadOnly()" 
      (cdkDropListDropped)="drop(element.draggables, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.draggables"
        class="draggable-row" 
      >
        <a 
          cdkDragHandle 
          class="button is-handle" 
          [class.is-info]="isSelectedPosRef(draggableEl)"
          (mousedown)="selectPosElByRef(draggableEl)"
        >
          <i class="fas fa-arrows-alt" aria-hidden="true"></i>
        </a>
        <div class="row-content">
          <capture-dnd-style 
            [element]="draggableEl" 
            (change)="throttledUpdate()"
            (idClick)="selectPosElByRef(draggableEl)"
          ></capture-dnd-style>
          <element-config  [contentElement]="draggableEl.element"></element-config>
        </div>
        <a class="button is-expanded" (click)="removeElement(element.draggables, draggableEl)">
          <i class="fas fa-trash-alt" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div>
      <select-element 
        [elementTypes]="subElementTypes"
        (insert)="insertDraggableElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>
    <hr/>
    <div class="element-group-header">
      <i class="fa fa-crosshairs" aria-hidden="true"></i>
      Target Elements
    </div>
    <div 
      cdkDropList 
      (cdkDropListDropped)="drop(element.targets, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.targets"
        class="draggable-row" 
      >
        <a 
          cdkDragHandle 
          class="button is-handle" 
          [class.is-info]="isSelectedPosRef(draggableEl)"
          (mousedown)="selectPosElByRef(draggableEl)"
        >
          <i class="fas fa-arrows-alt" aria-hidden="true"></i>
        </a>
        <div class="row-content">
          <capture-dnd-style 
            [element]="draggableEl" 
            (change)="throttledUpdate()"
            (idClick)="selectPosElByRef(draggableEl)"
          ></capture-dnd-style>
          <element-config  [contentElement]="draggableEl.element"></element-config>
        </div>
        <a class="button is-expanded" (click)="removeElement(element.targets, draggableEl)">
          <i class="fas fa-trash" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div>
      <select-element 
        [elementTypes]="subTargetElementTypes"
        (insert)="insertTargetElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>
    
    <hr /> 
  
    <div class="form-row" *ngIf="isScoreWeightEnabled()">
      <div class="form-row-label">
          Score Weight
      </div>
      <div class="form-row-input">
        <input type="text" class="input" [formControl]="scoreWeight">
      </div>
    </div>
  </div>