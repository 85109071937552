import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IMenuTabConfig<T> {
  id: T,
  caption: string,
  count?: () => number | string,
  showIf?: () => boolean,
  ///
  isCountEnabled?:boolean,
  isShowIfEnabled?:boolean,
  data?: any,
}

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  @Input() menuTabs:IMenuTabConfig<string>[];
  @Input() tabIdInit:string;
  @Output() change = new EventEmitter()

  private activeTabId:string;

  constructor() { }

  ngOnInit(): void {
    this.menuTabs.forEach(tab => {
      tab.isCountEnabled = (typeof tab.count === 'function');
      tab.isShowIfEnabled = (typeof tab.showIf === 'function');
    });
    if (this.tabIdInit !== undefined){
      this.setActiveId(this.tabIdInit);
    }
    console.log(this.tabIdInit, this.menuTabs)
  }

  isTabHidden(tab:IMenuTabConfig<any>){
    if (tab.isShowIfEnabled){
      return tab.showIf();
    }
    return true;
  }

  isActiveId(id:string){ return this.activeTabId === id; }
  setActiveId(id:string){ 
    console.log('isActiveId', this.isActiveId(id) );
    this.activeTabId = id; 
    this.change.emit(id);
  }

}
