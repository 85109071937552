import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import {UrlLoaderService} from '../url-loader.service';
import {AudioBufferService, VoiceSound} from '../audio-buffer.service';
import {Subject} from 'rxjs';
import { memo } from '../element-render-video/element-render-video.component';

@Component({
  selector: 'render-audio',
  templateUrl: './render-audio.component.html',
  styleUrls: ['./render-audio.component.scss']
})
export class RenderAudioComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() url:string;
  @Input() hasControls:boolean = false;
  @Input() trigger:Subject<boolean>;
  @Input() isTriggerDisabled:boolean;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;

  sound:VoiceSound;
  audioSources = new Map();
  
  constructor(
    private urlLoader: UrlLoaderService,
    private audioBuffer: AudioBufferService,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(){
    if (!this.hasControls){
      if (this.trigger){
        this.trigger.subscribe(this.onTriggerPlay)
      }
      this.sound = new VoiceSound(this.audioPlayer.nativeElement, this.audioBuffer);
    }
  }
  ngOnDestroy() {
    if (this.sound){
      this.sound.removeFromBuffer()
    }
  }
  onTriggerPlay = (isActive:boolean) => {
    if (isActive && !this.isTriggerDisabled){
      this.sound.play();
    }
  }
  sanitizeUrl(url:string){
    return this.urlLoader.sanitize(this.url);
  }

  getAudioSources(){
    // setTimeout(()=>{
    //   console.log(this.audioSources);
    // }, 500)
    return memo(this.audioSources, this.url, url => {
      const sanitized = this.urlLoader.sanitize(url)
      console.log(url);
      return [{ src: url, type: 'audio/mp3', }]
    });
  }

}
