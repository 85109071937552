


<div class="inline-text-block" *ngIf="isSimpleText()">
  <div
    [class.is-heading]="isHeading()"
    [class.is-heading-small]="isHeadingSmall()"
    [class.is-body]="isBody()"
    [class.is-small]="isSmall()"
    class="inline-text-block"
  >
    <markdown-inline [input]="element.caption"></markdown-inline>
  </div>
</div>

<div *ngIf="isBulletList()">
  <ul>
    <li *ngFor="let contentElement of element.advancedList">
      <div>
        <element-render [contentElement]="contentElement" [questionState]="questionState"></element-render>
      </div>
    </li>
  </ul>
</div>

<div *ngIf="isNumberedList()">
  <ol>
    <li *ngFor="let contentElement of element.advancedList">
      <div>
        <element-render [contentElement]="contentElement" [questionState]="questionState"></element-render>
      </div>
    </li>
  </ol>
</div>

<div *ngIf="isAdvancedInlineText()" class="advanced-inline-container inline-block">
  <div *ngFor="let contentElement of element.advancedList" class="inline-text-block">
    <div [ngSwitch]="contentElement.elementType" class="inline-text-block">
      <div *ngSwitchCase="'text'"   class="inline-text-block"> <element-render-text      [element]="contentElement" [questionState]="questionState" class="inline-text-block" ></element-render-text> </div>
      <div *ngSwitchCase="'math'"   class="inline-block is-math">      <element-render-math      [element]="contentElement" [questionState]="questionState" [isInline]="true"  ></element-render-math></div>
      <div *ngSwitchCase="'image'"  class="inline-block">      <element-render-image     [element]="contentElement" [questionState]="questionState" ></element-render-image></div>
      <div *ngSwitchCase="'table'"  class="inline-block">      <element-render-table     [element]="contentElement" [questionState]="questionState" ></element-render-table></div>
      <div *ngSwitchCase="'video'"  class="inline-block">      <element-render-video     [element]="contentElement" [questionState]="questionState" ></element-render-video></div>
      <div *ngSwitchCase="'mcq'"    class="inline-block">      <element-render-mcq       [element]="contentElement" [questionState]="questionState" ></element-render-mcq></div>
      <div *ngSwitchCase="'input'"  class="inline-block">      <element-render-input     [element]="contentElement" [questionState]="questionState" ></element-render-input></div>
    </div>
  </div>
</div>

