import { Component, OnInit, Input } from '@angular/core';
import { IContentElementImage, QuestionState } from '../models';

@Component({
  selector: 'element-render-audio',
  templateUrl: './element-render-audio.component.html',
  styleUrls: ['./element-render-audio.component.scss']
})
export class ElementRenderAudioComponent implements OnInit {

  @Input() element:IContentElementImage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used

  constructor(
  ) { }

  ngOnInit() {
    // console.log('element', this.element)
  }

}
