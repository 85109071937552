<div class="page-content" >
  <div class="form-content">
    <div>
      <h2 class="strong"> <tra [slug]="getMainHeader()"></tra> </h2>
    </div>
    <h2 class="minor"> <tra slug="lbl_returning_users"></tra> </h2>
    <div class="form-instruction-major">
      <tra [slug]="getMainInstruction()"></tra>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="submitForm()">

      <div class="field">
        <label class="label">
          <tra slug="lbl_user_or_email"></tra>
        </label>
        <div *ngIf="isInputBlank(formGroupEmailAddress)" class="help "> 
            <tra slug="error_enter_email"></tra>
        </div>
        <div *ngIf="isInputInvalid(formGroupEmailAddress)" class="help is-danger"> 
          <tra slug="error_login_invalid_email"></tra>
        </div>
        <div class="control">
          <input 
            type="text"
            id="username"
            [formControl]="formGroupEmailAddress"
            class="input" 
            [class.is-warning]="isInputBlank(formGroupEmailAddress)"
            [class.is-danger] ="isInputInvalid(formGroupEmailAddress)"
          >
        </div>
      </div>

      <div class="field">
        <label class="label">
          <tra slug="lbl_password"></tra>
        </label>
        <div *ngIf="isInputBlank(formGroupPassword)" class="help "> 
          <tra slug="error_enter_password"></tra>
        </div>
        <div *ngIf="isInputInvalid(formGroupPassword)" class="help is-danger"> 
          <tra slug="error_min_length_password"></tra>
        </div>
        <div class="control">
          <input 
            type="password"
            id="password"
            [formControl]="formGroupPassword"
            class="input" 
            [class.is-warning]="isInputBlank(formGroupPassword)"
            [class.is-danger] ="isInputInvalid(formGroupPassword)"
          >
        </div>
      </div>

      <div class="notification is-warning" *ngIf="isFormFailed">
        <div [ngSwitch]="formFailReason">
          <div *ngSwitchCase="FormFail.NOT_FOUND">
            <tra slug="txt_alert_bad_login"></tra>
          </div>
          <div *ngSwitchCase="FormFail.NOT_VERIFIED">
            <tra slug="txt_alert_bad_login_unverified"></tra>
          </div>
          <div *ngSwitchCase="FormFail.PSW_RESET_REQ">
            <tra-md slug="txt_alert_login_pass_reset_req"></tra-md>
          </div>
          <div *ngSwitchCase="FormFail.UNKNOWN">
            <tra slug="txt_alert_bad_login"></tra>
          </div>
          <div *ngSwitchCase="FormFail.EMAIL">
            <tra slug="msg_invalid_entry"></tra>
          </div>
          <div *ngSwitchCase="FormFail.PASSWORD">
            <tra slug="msg_no_password"></tra>
          </div>
        </div>
      </div>

      <div>
        <input 
          type="submit"
          value={{signIn}}
          id="signIn"
          class="button is-large is-success is-fullwidth signin"
          [disabled]="isFormSent"
        />
      </div>
      
    </form>

    
    <div>
      <a [routerLink]="getForgotPasswordRoute()">
        <tra slug="lbl_forgot_password"></tra>
      </a>
    </div>
    <div class="or-horz"><hr> <tra slug="dividing_or"></tra> <hr></div>
    <div>
      <h2 class="minor"> 
        <tra slug="lbl_new_users"></tra>
      </h2>
      <a [routerLink]="getAccountCreationRoute()" class="button is-info is-large is-fullwidth" [class.no-pointer-events]="isUsingSebSoftware()">
        <tra slug="btn_create_account"></tra>
      </a>
    </div>

    <div *ngIf="loginErrorTroubleshootMsg" style="margin-top:4em;">
      <button class="button is-light is-small" (click)="isLoginTroubleshooting = !isLoginTroubleshooting">Troubleshoot Login</button>
      <div *ngIf="isLoginTroubleshooting" style="margin-top:1em;">
        {{loginErrorTroubleshootMsg}}
      </div>
    </div>

  </div>
  
</div>
