
<!-- elementType: {{contentElement.elementType}} -->
<div [ngSwitch]="contentElement.elementType">
  <element-config-audio      *ngSwitchCase = "ElementType.AUDIO"       [element] = "contentElement" ></element-config-audio>
  <element-config-camera     *ngSwitchCase = "ElementType.CAMERA"      [element] = "contentElement" ></element-config-camera>
  <element-config-dnd        *ngSwitchCase = "ElementType.DND"         [element] = "contentElement" ></element-config-dnd>
  <element-config-graphics   *ngSwitchCase = "ElementType.GRAPHICS"    [element] = "contentElement" ></element-config-graphics>
  <element-config-graphing   *ngSwitchCase = "ElementType.GRAPHING"    [element] = "contentElement" ></element-config-graphing>
  <element-config-iframe     *ngSwitchCase = "ElementType.IFRAME"      [element] = "contentElement" ></element-config-iframe>
  <element-config-image      *ngSwitchCase = "ElementType.IMAGE"       [element] = "contentElement" ></element-config-image>
  <element-config-input      *ngSwitchCase = "ElementType.INPUT"       [element] = "contentElement" ></element-config-input>
  <element-config-matching   *ngSwitchCase = "ElementType.MATCHING"    [element] = "contentElement" ></element-config-matching>
  <element-config-math       *ngSwitchCase = "ElementType.MATH"        [element] = "contentElement" ></element-config-math>
  <element-config-mcq        *ngSwitchCase = "ElementType.MCQ"         [element] = "contentElement" ></element-config-mcq>
  <element-config-mic        *ngSwitchCase = "ElementType.MIC"         [element] = "contentElement" ></element-config-mic>
  <element-config-order      *ngSwitchCase = "ElementType.ORDER"       [element] = "contentElement" ></element-config-order>
  <element-config-reader     *ngSwitchCase = "ElementType.READER"      [element] = "contentElement" ></element-config-reader>
  <element-config-sbs        *ngSwitchCase = "ElementType.SBS"         [element] = "contentElement" ></element-config-sbs>
  <element-config-table      *ngSwitchCase = "ElementType.TABLE"       [element] = "contentElement" ></element-config-table>
  <element-config-text       *ngSwitchCase = "ElementType.TEXT"        [element] = "contentElement" ></element-config-text>
  <element-config-upload     *ngSwitchCase = "ElementType.UPLOAD"      [element] = "contentElement" ></element-config-upload>
  <element-config-video      *ngSwitchCase = "ElementType.VIDEO"       [element] = "contentElement" ></element-config-video>
  <element-config-grouping   *ngSwitchCase = "ElementType.GROUPING"    [element] = "contentElement"></element-config-grouping>
</div>