import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyBlockArrSelectComponent } from './body-block-arr-select/body-block-arr-select.component';
import { UiChartsModule } from '../ui-charts/ui-charts.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ListTravArrowsComponent } from './list-trav-arrows/list-trav-arrows.component';
import { CheckToggleComponent } from './check-toggle/check-toggle.component';
import { TraComponent } from './tra/tra.component';
import { TraMdComponent } from './tra-md/tra-md.component';
import { MarkdownModule } from 'ngx-markdown';
import { InfoCenterDetailsComponent } from './info-center-details/info-center-details.component';
import { ModalComponent } from './modal/modal.component';
import { PaginatorComponent } from './paginator/paginator.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { TableRowSelectorComponent } from './table-row-selector/table-row-selector.component';
import { EditSaveComponent } from './edit-save/edit-save.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { InputDateComponent } from './input-date/input-date.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { CaptureFilterRangeComponent } from './capture-filter-range/capture-filter-range.component';
import {LineChartModule, PieChartModule, BarChartModule } from '@swimlane/ngx-charts';
import {ExpectationTableComponent} from './expectation-table/expectation-table.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import { ChatEmojiPickerComponent } from './chat-emoji-picker/chat-emoji-picker.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {TextFieldModule} from '@angular/cdk/text-field';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MarkdownModule,
        ReactiveFormsModule,
        LineChartModule,
        PieChartModule,
        BarChartModule,
        FormsModule,
        UiChartsModule,
        PickerModule,
        EmojiModule,
        TextFieldModule,
        NgbModule,
    ],
  declarations: [
    BodyBlockArrSelectComponent,
    FooterComponent,
    HeaderComponent,
    ListTravArrowsComponent,
    CheckToggleComponent,
    TraComponent,
    TraMdComponent,
    InfoCenterDetailsComponent,
    ModalComponent,
    PaginatorComponent,
    MenuBarComponent,
    TableRowSelectorComponent,
    EditSaveComponent,
    InputTimeComponent,
    InputDateComponent,
    TableHeaderComponent,
    CaptureFilterRangeComponent,
    ExpectationTableComponent,
    ChatBoxComponent,
    ChatEmojiPickerComponent,
    ChatMessageComponent,
    ModalFooterComponent,
  ],
    exports: [
        BodyBlockArrSelectComponent,
        FooterComponent,
        HeaderComponent,
        ListTravArrowsComponent,
        CheckToggleComponent,
        TraComponent,
        TraMdComponent,
        InfoCenterDetailsComponent,
        PaginatorComponent,
        MenuBarComponent,
        EditSaveComponent,
        TableRowSelectorComponent,
        TableHeaderComponent,
        InputTimeComponent,
        InputDateComponent,
        CaptureFilterRangeComponent,
        ExpectationTableComponent,
        ChatBoxComponent,
        ModalFooterComponent
    ]
})
export class UiPartialModule { }
