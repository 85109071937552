import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MemDataPaginated, SortMode } from '../paginator/helpers/mem-data-paginated';
import { lang } from 'moment';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Input() caption:string = 'Header';
  @Input() color:string = '#333333';
  @Input() id:string;
  @Input() isSortEnabled:boolean;
  @Input() headingStyle: string;
  @Input() disableCaptionWrap:boolean;
  @Input() ctrl:MemDataPaginated<any>;
  @Input() selectionOptions: string[];      // for filtering
  @Input() radioValueMapping: Function;
  @Output() sort = new EventEmitter();
  @Output() filter = new EventEmitter();

  constructor(private lang: LangService) { }

  isFilterActive:boolean;
  filterSetting = {};
  SortMode = SortMode;

  ngOnInit(): void {
    if(this.lang.c() == "en"){this.headingStyle=""}
    if (this.ctrl && this.ctrl.activeFilters && this.ctrl.activeFilters[this.id]){
      this.isFilterActive = true;
    }
  }

  toggleSort(){
    if (this.isSortEnabled && this.ctrl && this.id){
      this.ctrl.toggleSortSetting(this.id);
    }
  }

  toggleFilter(){
    this.isFilterActive = !this.isFilterActive;
    if (this.ctrl && this.id){
      const filterModel = this.getFilterModel();
      if (!this.isFilterActive){
        filterModel[this.id] = null;
      }
      this.updateFilter();
    }
  }

  getFilterModel(){
    if (this.ctrl){
      return this.ctrl.activeFilters;
    }
  }

  updateFilter = _.debounce(() => {
    if (this.ctrl && this.id){
      this.ctrl.refreshFilters();
      this.filter.emit();
    }
  }, 500, {leading: true});

  isSorted(){
    return !!this.getSortType();
  }

  getSortType(){
    if (this.ctrl && this.id){
      return this.ctrl.getSortSettingMode(this.id);
    }
  }

}
