import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Sockette from 'sockette';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { AccountType } from '../constants/account-types';

interface SocketQueueItem {
  action: string,
  data: any
}
@Injectable({
  providedIn: 'root'
})
export class MptConnectionService {
  private ws: Sockette;
  private sessionId: number;

  private readonly wsURL: string = 'wss://sc4fvyeoh3.execute-api.ca-central-1.amazonaws.com/production';
  public disconnectedTestTakersSub = new BehaviorSubject<any[]>([]);
  public connectedTestTakersSub = new BehaviorSubject<any[]>([]);
  public initCompletedTestTakersSub = new BehaviorSubject<any[]>([]);
  public updateCompletedTestTakersSub = new BehaviorSubject<any>(null);
  pingInterval;
  public isSocketReady:BehaviorSubject<any> = new BehaviorSubject({});
  private socketReady: boolean = false;
  private socketQueue: Array<SocketQueueItem> = [];

  constructor(
    private routes: RoutesService,
    private auth: AuthService,
    @Inject('userType') private userType: string
  ) {
    this.auth.user().subscribe((info) => {
      this.onUserInfoChange(info);
    })
  }

  ngOnInit(): void {
  }

  onUserInfoChange(info) {
    if(info && this.userType === AccountType.TEST_TAKER) {
      //Update the session ID
      this.auth.apiFind(this.routes.TEST_TAKER_SESSION_ID).then((sessionIds : {test_session_id: number}[]) => {
        if(sessionIds && sessionIds.length > 0) {
          this.setSessionId(sessionIds[0].test_session_id);
          this.connect();
        }
      })
    }
  }

  setSessionId(sessionId) {
    this.sessionId = sessionId;
  }

  connect() {
    if(this.isConnected()) {
      this.disconnect();
    }
    this.ws = new Sockette(this.wsURL, {
      timeout: 10e3,
      maxAttempts: 60,
      onopen: e=> { 
        //console.log("mpt socket connected")
        this.onSocketReady();
        this.onUserConnect()
      },
      onmessage: e => {this.onUserMessage(e)},
      onreconnect: e => {console.log('reconnecting....'),e},
      onmaximum: e => {console.log('maximum.....'),e},
      onclose: e => {console.log('Closed!', e),this.ws.reconnect();},
      onerror: e => {}
    });
    this.pingInterval = setInterval(() => {
      const data = {
        uid: this.auth.user().value.uid, 
        userType: this.userType, 
        sessionId: this.sessionId
      }
      this.emit('ping',data);
    }, 30000);
  }

  public emit(action, data) {
    if(this.socketReady){
    this.ws.json({action, data});
    return;
    }
    this.socketQueue.push({ action, data });
  }

  private onSocketReady() {
    this.socketReady = true;
    while (this.socketQueue.length) {
      const { action, data } = this.socketQueue.shift();
      this.emit(action, data);
    }
  }

  private onUserConnect() {
    this.emit("mptConnect",{uid: this.auth.user().value.uid, userType: this.userType, sessionId: this.sessionId})
    //this.ws.json({action: "mptConnect", data:{uid: this.auth.user().value.uid, userType: this.userType, sessionId: this.sessionId}});
  }
  
  public updateCompleted(uid, isComplete) {
    this.emit("mptSessionComplete",{uid, sessionId: this.sessionId, isComplete})
    //this.ws.json({action: "mptSessionComplete", data:{uid, sessionId: this.sessionId, isComplete}});
  }

  private onUserDisconnect() {}

  private onUserMessage(e) {
    let eObj;
    try {
      eObj = JSON.parse(e.data);
    } catch(e) {
      //Don't set eObj
    }
    if(!eObj) {
      return;
    }
    switch (this.userType) {
      case AccountType.TEST_TAKER:
        this.onStudentMessage(eObj);
        break;
      case AccountType.TEST_ADMIN:
        this.onTestAdminMessage(eObj); 
        break;
    }
  }

  private onTestAdminMessage(e) {
    switch(e.eventType) {
      case 'updateConnectedTestTakers': 
        this.connectedTestTakersSub.next(e.connectedTestTakers);
        break;
      case 'updateCompletedTestTakers':
        this.updateCompletedTestTakersSub.next(e.testTaker);
        break; 
      case 'initCompletedTestTakers':
        this.initCompletedTestTakersSub.next(e.completedTestTakers);
        break;
    }
  }

  private onStudentMessage(e) {
    //Don't have anything here yet
  }

  isConnected() {
    return this.ws;
  }
  disconnect() {
    //console.log("trying to  disconnect socket.....")
    if(this.ws) {
      this.ws.close();
      this.socketReady = false;
      //console.log("socket disconnected.....")
    }
  }

}
