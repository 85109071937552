import {randomFirstName, randomLastName} from '../../constants/fakenames';
import {DATETIME} from '../../api/models/db-types';
import {leaderItems} from './leaderItems';
import {s2LeaderItems} from './s2LeaderItems';
import {s3LeaderItems} from './s3LeaderItems';
import {markerItems} from './markerItems';

// constants
const RESPONSE_GEN_COUNT = 100;

export enum ItemType {
    SYSTEM     = 'SYSTEM',       // comes from the system itself, authoring => test taking.
    SCAN       = 'SCAN',         // response is an image.
    TEXT       = 'TEXT',         // Single Text Area
    TEXT_TABLE = 'TEXT_TABLE',   // Graphic Organizer (multiple textboxes on the screen)
    AUDIO      = 'AUDIO',        // Audio Response
    // IMAGE      = 'IMAGE'         // item is an image, response is an image.
}
export enum MarkingType {
    MACHINE = 'MACHINE',
    HUMAN = 'HUMAN',
}

export interface ITrainingSet {
    id: number;
    name: string;
}
export interface IReliabilitySet {
    id: number;
    name: string;
}

export interface IItem {
    id: number;
    name: string;
    type: ItemType;
    marking_type?: MarkingType;

    response_count: number;
    responses_expected: number;

    marked_count: number;

    training_sets?: ITrainingSet[];
    reliability_sets?: IReliabilitySet[];

    image_url?: string;
}

export enum SelectionType {
    UNSELECTED = 'UNSELECTED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    ZERO = 'ZERO',
}
export enum MarkingCode {
    NONE = '',
    NR = 'NR',
    FOUR = '4',
    THREE = '3',
    TWO = '2',
    ONE = '1',
    ZERO = '0',
}

export interface ILeaderSelectionItem {
    item: IItem;
    isSelectedDone: boolean;
    isScoredDone: boolean;
    isReliabilitySet: boolean;
    isOnSiteSet: boolean;
    isOffSiteSet: boolean;

    responses: IItemResponse[];
}
export interface IMarkingItem {
    item: IItem;
    responses: IMarkingItemResponse[];
}
export interface IMarkingItemResponse {
    id: number;
    raw: string;

    scores: IMarkingScore[];
}
export interface IMarkingScore {
    uid: number;
    score: MarkingCode;
    rationale?: string;
}
export interface IItemResponse {
    id: number;
    raw: string; // {"1":"700 km","2":"5 : 35"}

    uid?: number;
    item_id?: number;

    isReliability?: boolean; // deprecated
    isOnSite?: boolean; // deprecated
    isOffSite?: boolean; // deprecated

    selection?: any; // SelectionType;
    status?: string; // reliability, onsite, offsite.
    score?: any; // MarkingCode;
    rationale?: string;
    archived?: string;
    order?: number;
}

export interface ILeader {
    id: number;
    first_name: string;
    last_name: string;
    notes: string;
}

export interface IStudent {
    id: number;
    first_name: string;
    last_name: string;
}

export interface IMarker {
    id: number;
    first_name: string;
    last_name: string;
    notes: string;

    // expectedPace?: number;
    // itemAssignments: IItem[]; // IDragMarker
}

export interface IAssessment {
    id: number;
    name: string;

    items: IItem[];

    start_date: DATETIME;
    end_date: DATETIME;

    isActive: boolean;
    isInMarkingSession: boolean;
}

export enum TimeSlotPart {
    MORNING = 'Morning',
    AFTERNOON = 'Afternoon',
}

export interface ITimeSlot {
    index: number;
    date?: DATETIME;
    day?: number; // 1 or 2.
    part?: TimeSlotPart;
}

export interface IDay {
    index: number;
    date: DATETIME;
}

export interface IMarkingSession {
    id: number;

    name: string;
    assessments: IAssessment[];
    start_date: DATETIME;
    end_date: DATETIME;
    leaders: ILeader[];
    markers: IMarker[];
    unique_items: number; // is this also generated from assessments?
    active_training: number;

    notes: string;

    days: IDay[];
    // timeSlots: ITimeSlot[]; // contains 4 time slots.

    // get these from assessments.
    // responses_left: number;
    // responses_total: number;
}

export interface IItemScore {
    item_id: number;
    taqr_id: number;
    marker: IMarker;
    score: IMarkingScore;
}

const trainingSets: ITrainingSet[] = [
    {id: 1, name: 'None'},
    {id: 2, name: 'Training Set A'},
    {id: 3, name: 'Training Set B'},
];
const reliabilitySets: IReliabilitySet[] = [
    {id: 1, name: 'None'},
    {id: 2, name: 'Reliability Set A'},
    {id: 3, name: 'Reliability Set B'},
    {id: 4, name: 'Reliability Set C'},
];


const timeSlots: ITimeSlot[] = [
    {index: 0, date: '2020-07-25T09:00:00.000Z', day: 1, part: TimeSlotPart.MORNING},
    {index: 1, date: '2020-07-25T09:00:00.000Z', day: 1, part: TimeSlotPart.AFTERNOON},
    {index: 2, date: '2020-07-26T09:00:00.000Z', day: 2, part: TimeSlotPart.MORNING},
    {index: 3, date: '2020-07-26T09:00:00.000Z', day: 2, part: TimeSlotPart.AFTERNOON},
];

const assessmentNames = [
    'June 2020 Numeracy Grade 10 Assessment Form A',
    'June 2020 Numeracy Grade 10 Assessment Form B',
    'June 2020 Numeracy Grade 10 Assessment Form C',

    'June 2019 Literacy Grade 10 Assessment Form A',
    'June 2019 Literacy Grade 10 Assessment Form B',
    'June 2019 Literacy Grade 10 Assessment Form C',
];
const markingSessionNames = [
    'June 2020 Numeracy A, B',
    'June 2020 Numeracy C',
    'June 2019 Literacy',
];

const markers: IMarker[] = [...Array(70)].map((_, i) => {
    return {
        id: i + 1,
        first_name: randomFirstName(),
        last_name: randomLastName(),
        notes: '',
    };
});
const leaders: ILeader[] = [...Array(10)].map((_, i) => {
    return {
        id: i,
        first_name: randomFirstName(),
        last_name: randomLastName(),
        notes: ''
    };
});

const items: IItem[] = [
    {id: 1, name: 'Dairy Farm', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 30, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 2, name: 'Maple Syrup', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 37, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 3, name: 'Olympics', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 150, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 4, name: 'Wastewater', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 1000, responses_expected: 2000, marked_count: 16, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 5, name: 'Salmon Run', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 1200, responses_expected: 2000, marked_count: 0, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 6, name: 'Totem Poles', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 7, name: 'Solar Energy', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets},

    {id: 8, name: 'Done 1', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 9, name: 'Done 2', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 10, name: 'Done 3', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets},
    {id: 11, name: 'Done 4', type: ItemType.SYSTEM, marking_type: MarkingType.HUMAN, response_count: 2000, responses_expected: 2000, marked_count: 2000, training_sets: trainingSets, reliability_sets: reliabilitySets}
];

const scores: IItemScore[] = [
    // TAQR 1
    {item_id: 1, taqr_id: 1, marker: markers[0], score: {uid: 1, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[1], score: {uid: 2, score: MarkingCode.TWO, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[2], score: {uid: 3, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[3], score: {uid: 4, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[4], score: {uid: 5, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[5], score: {uid: 6, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[6], score: {uid: 7, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[7], score: {uid: 8, score: MarkingCode.TWO, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[8], score: {uid: 9, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[9], score: {uid: 10, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[10], score: {uid: 11, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[11], score: {uid: 12, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[12], score: {uid: 13, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[13], score: {uid: 14, score: MarkingCode.ONE, rationale: ''}},
    {item_id: 1, taqr_id: 1, marker: markers[13], score: {uid: 15, score: MarkingCode.ONE, rationale: ''}},

    // TAQR 2
    {item_id: 1, taqr_id: 2, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[5], score: {uid: 6, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 2, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 3
    {item_id: 1, taqr_id: 3, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[5], score: {uid: 6, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 3, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 4
    {item_id: 1, taqr_id: 4, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 4, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 5
    {item_id: 1, taqr_id: 5, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 5, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 6
    {item_id: 1, taqr_id: 6, marker: markers[0], score: {uid: 1, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[1], score: {uid: 2, score: MarkingCode.NR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 6, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 7
    {item_id: 1, taqr_id: 7, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 7, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 8
    {item_id: 1, taqr_id: 8, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 8, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 9
    {item_id: 1, taqr_id: 9, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[1], score: {uid: 2, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 9, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 10
    {item_id: 1, taqr_id: 10, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[1], score: {uid: 2, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 10, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 11
    {item_id: 1, taqr_id: 11, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[1], score: {uid: 2, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 11, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},

    // TAQR 12
    {item_id: 1, taqr_id: 12, marker: markers[0], score: {uid: 1, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[1], score: {uid: 2, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[2], score: {uid: 3, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[3], score: {uid: 4, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[4], score: {uid: 5, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[5], score: {uid: 6, score: MarkingCode.NONE, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[6], score: {uid: 7, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[7], score: {uid: 8, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[8], score: {uid: 9, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[9], score: {uid: 10, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[10], score: {uid: 11, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[11], score: {uid: 12, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[12], score: {uid: 13, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[13], score: {uid: 14, score: MarkingCode.FOUR, rationale: ''}},
    {item_id: 1, taqr_id: 12, marker: markers[13], score: {uid: 15, score: MarkingCode.FOUR, rationale: ''}},
];

const assessments: IAssessment[] = [
    // active
    {
        id: 1,
        name: assessmentNames[0],

        items: [items[0], items[1], items[2], items[3]],

        start_date: '',
        end_date: '',

        isActive: true,
        isInMarkingSession: false,
    },
    {
        id: 2,
        name: assessmentNames[1],

        items: [items[4], items[5]],

        start_date: '',
        end_date: '',

        isActive: true,
        isInMarkingSession: false,
    },
    {
        id: 3,
        name: assessmentNames[2],

        items: [items[7]],

        start_date: '',
        end_date: '',

        isActive: true,
        isInMarkingSession: false,
    },

    // past
    {
        id: 4,
        name: assessmentNames[3],

        items: [items[8]],

        start_date: '',
        end_date: '',

        isActive: false,
        isInMarkingSession: false,
    },
    {
        id: 5,
        name: assessmentNames[4],

        items: [items[9]],

        start_date: '',
        end_date: '',

        isActive: false,
        isInMarkingSession: false,
    },
    {
        id: 6,
        name: assessmentNames[5],

        items: [items[10]],

        start_date: '',
        end_date: '',

        isActive: false,
        isInMarkingSession: false,
    },
];

const days: IDay[] = [
    {index: 1, date: '2020-07-15T09:00:00.000Z'},
    {index: 2, date: '2020-07-16T09:00:00.000Z'},
    {index: 3, date: '2020-07-17T09:00:00.000Z'},
    {index: 4, date: '2020-07-18T09:00:00.000Z'},
    {index: 5, date: '2020-07-19T09:00:00.000Z'},
    {index: 6, date: '2020-07-20T09:00:00.000Z'},
    {index: 7, date: '2020-07-21T09:00:00.000Z'},
    {index: 8, date: '2020-07-22T09:00:00.000Z'},
    {index: 9, date: '2020-07-23T09:00:00.000Z'},
    {index: 10, date: '2020-07-24T09:00:00.000Z'},
    {index: 11, date: '2020-07-25T09:00:00.000Z'},
    {index: 12, date: '2020-07-26T09:00:00.000Z'},
    {index: 13, date: '2020-07-27T09:00:00.000Z'},
    {index: 14, date: '2020-07-28T09:00:00.000Z'},
    {index: 15, date: '2020-07-29T09:00:00.000Z'},
    {index: 16, date: '2020-07-30T09:00:00.000Z'},
    {index: 17, date: '2020-07-31T09:00:00.000Z'},
];

const markingSessions: IMarkingSession[] = [
    {
        id: 1,

        name: markingSessionNames[0], // 'June 2020 Numeracy A, B'
        assessments: [assessments[0], assessments[1]],
        start_date: '2020-06-30T09:00:00.000Z',
        end_date: '2020-07-30T09:00:00.000Z',
        leaders: leaders.slice(0, 4),
        markers: markers.slice(0, 12),
        unique_items: 7, // is this also generated from assessments?
        active_training: 10,

        notes: '',
        days: days

        // timeSlots: timeSlots,
    },
    /*
    {
        id: 2,

        name: markingSessionNames[1], // 'June 2020 Numeracy C'
        assessments: [assessments[2]],
        start_date: '2020-06-30T09:00:00.000Z',
        end_date: '2020-07-30T09:00:00.000Z',
        leaders: 0,
        markers: 7,
        unique_items: 3, // is this also generated from assessments?
        active_training: 0,
    },*/
    {
        id: 3,

        name: markingSessionNames[2], // 'June 2019 Literacy'
        assessments: [assessments[3], assessments[4], assessments[5]],
        start_date: '2019-06-30T09:00:00.000Z',
        end_date: '2019-07-30T09:00:00.000Z',
        leaders: leaders.slice(4, 10),
        markers: markers.slice(12, 30),
        unique_items: 10, // is this also generated from assessments?
        active_training: 0,

        notes: '',
        days: days
        // timeSlots: timeSlots,
    },
];

export function generateAssessments() {
    markingSessions.forEach(session => {
        session.assessments.forEach(assessment => {
            assessment.isInMarkingSession = true;
        });
    });
    return assessments;
}

export function generateMarkingSessions() {
    return markingSessions;
}


// Leader stuff.

enum FirstAnswer {
    A = '700 km',
    B = '7 km',
    C = '3500 km',
    D = '175 km',
}

enum SecondAnswer {
    A = '1 : 20',
    B = '5 : 100',
    C = '35 : 100',
    D = '20 : 1',
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function randomValidFirstAnswer() {
    let i = getRandomInt(0, 3);
    switch (i) {
        case 0:
            return FirstAnswer.A;
        case 1:
            return FirstAnswer.B;
        case 2:
            return FirstAnswer.C;
        case 3:
            return FirstAnswer.D;
        default:
            return FirstAnswer.D;
    }
}

function randomValidSecondAnswer() {
    let i = getRandomInt(0, 3);
    switch (i) {
        case 0:
            return SecondAnswer.A;
        case 1:
            return SecondAnswer.B;
        case 2:
            return SecondAnswer.C;
        case 3:
            return SecondAnswer.D;
        default:
            return SecondAnswer.D;
    }
}

export function generateLeaderScoresOverview() {
    return scores;
}

export function generateLeaderMarkingSessions() {
    return [markingSessions[0]]; // this is just the ACTIVE marking session.
}

export function randomSelection() {
    const selectionOptions = [
        null,
        0,
        1,
        2,
    ];
    return selectionOptions[getRandomInt(0, 3)];
}
export function randomScore() {
    const options = [
        -1,
        0,
        1,
        2,
        3,
        4
    ];
    return options[getRandomInt(0, 5)];
}

function genS1Data() {
    const leaderItems: ILeaderSelectionItem[] = [];
    const session = generateLeaderMarkingSessions()[0];
    session.assessments.forEach((assessment: IAssessment) => {
        assessment.items.forEach((item: IItem, responseId: number) => {
            let responses: IItemResponse[] = [];
            for (let i = 0; i < RESPONSE_GEN_COUNT; i++) {
                const raw = "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}";

                let isOffSite, isOnSite, isReliability;
                isOffSite = isOnSite = isReliability = false;
                responses.push({ id: responseId, raw, isOffSite, isOnSite, isReliability });
            }
            let isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet;
            isSelectedDone = isScoredDone = isReliabilitySet = isOnSiteSet = isOffSiteSet = false;
            leaderItems.push({item, isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet, responses});
        });
    });
    return leaderItems;
}
function genS2Data() {
    const leaderItems: ILeaderSelectionItem[] = [];
    const session = generateLeaderMarkingSessions()[0];
    session.assessments.forEach((assessment: IAssessment) => {
        assessment.items.forEach((item: IItem, responseId: number) => {
            let responses: IItemResponse[] = [];
            for (let i = 0; i < RESPONSE_GEN_COUNT; i++) {
                const raw = "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}";

                let isOffSite, isOnSite, isReliability;
                isOffSite = isOnSite = isReliability = false;
                const selection = randomSelection();
                responses.push({ id: responses.length + 1, raw, isOffSite, isOnSite, isReliability, selection });
            }
            let isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet;
            isSelectedDone = isScoredDone = isReliabilitySet = isOnSiteSet = isOffSiteSet = false;
            leaderItems.push({item, isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet, responses});
        });
    });
    return leaderItems;
}
function genS3Data() {
    const leaderItems: ILeaderSelectionItem[] = [];
    const session = generateLeaderMarkingSessions()[0];
    session.assessments.forEach((assessment: IAssessment) => {
        assessment.items.forEach((item: IItem, responseId: number) => {
            let responses: IItemResponse[] = [];
            for (let i = 0; i < RESPONSE_GEN_COUNT; i++) {
                const raw = "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}";

                let isOffSite, isOnSite, isReliability;
                isOffSite = isOnSite = isReliability = false;
                const selection = randomSelection();
                const score = randomScore();
                responses.push({ id: responses.length + 1, raw, isOffSite, isOnSite, isReliability, selection, score });
            }
            let isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet;
            isSelectedDone = isScoredDone = isReliabilitySet = isOnSiteSet = isOffSiteSet = false;
            leaderItems.push({item, isSelectedDone, isScoredDone, isReliabilitySet, isOnSiteSet, isOffSiteSet, responses});
        });
    });
    return leaderItems;
}
function genMarkerData() {
    const markerItems: IMarkingItem[] = [];
    const session = generateLeaderMarkingSessions()[0];
    session.assessments.forEach((assessment: IAssessment) => {
        assessment.items.forEach((item: IItem) => {
            let responses: IMarkingItemResponse[] = [];
            for (let i = 0; i < RESPONSE_GEN_COUNT; i++) {
                const id = responses.length + 1;
                const raw = "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}";
                const scores: IMarkingScore[] = [];

                responses.push({ id, raw, scores });
            }
            markerItems.push({item, responses});
        });
    });
    return markerItems;
}

function genLeaderScores() {
    const markerItems: IMarkingItem[] = [];
    const session = generateLeaderMarkingSessions()[0];
    session.assessments.forEach((assessment: IAssessment) => {
        assessment.items.forEach((item: IItem) => {
            let responses: IMarkingItemResponse[] = [];
            for (let i = 0; i < RESPONSE_GEN_COUNT; i++) {
                const id = responses.length + 1;
                const raw = "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"" + getRandomInt(0, 100) + "\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}";
                const scores: IMarkingScore[] = [];

                responses.push({ id, raw, scores });
            }
            markerItems.push({item, responses});
        });
    });
    return markerItems;
}

export function getLeaderSessionItems(id: number) {
    // all this stuff is to generate new data.
    // print it in the console, press 'store as global variable', type copy(temp1) then paste it here!
    // const leaderItems = genS1Data();

    // const leaderItems = genS3Data();

    return leaderItems;
}
export function getS2LeaderSessionItems(id: number) {
    // const s2LeaderItems = genS2Data();
    // console.log(s2LeaderItems);
    return s2LeaderItems;
}
export function getS3LeaderSessionItems(id: number) {
    // const s3LeaderItems = genS3Data();
    // console.log(s3LeaderItems);
    return s3LeaderItems;
}

export function getMarkerItems(id: number) {
    // const markerItems = genMarkerData();
    // console.log(markerItems);
    return markerItems;
}
