<div class="chat-widget-container">
    <div *ngIf="isOpen && isLoaded && (!openRow && !isSupportOpen)" class="chat-view">
        <div class="chat-header">
            <i (click)="toggleOpen()" class="fa fa-times-circle fa-2x close-button"></i>
            <div class="field search-bar-container">
                <p class="control has-icons-left">
                    <input (input)="searchChange()" [formControl]="searchField" class="input is-fullwidth search-icon" type="text" placeholder="Search">
                    <span class="icon is-small is-left">
                        <i class="fa fa-search"></i>
                    </span>
                </p>
            </div>
            <i (click)="toggleSupport()" class="fa fa-question-circle fa-2x help-button"></i>
        </div>
        <div *ngIf="!searchResults" class="chat-body">
            <div class="chat-row-header">
                <p>RECENT</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of data.recent.rows" class="chat-row recent">
                <span *ngIf="row.chat.type === ChatRowType.GROUP" [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <i *ngIf="row.chat.type === ChatRowType.PERSON" class="fa fa-user" [class.active]="!!row.activeCount"></i>
                <p class="row-title">{{ row.chat.title }}</p>
                <div *ngIf="!!row.unreadMessagesCount" class="notification-circle push-right">{{ row.unreadMessagesCount }}</div>
            </div>

            <div class="chat-row-header">
                <p>MARKING SESSIONS</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of getVisibleGroups()" class="chat-row sessions">
                <span [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <p class="row-title">{{ row.chat.title }}</p>
                <div *ngIf="!!row.unreadMessagesCount" class="notification-circle push-right">{{ row.unreadMessagesCount }}</div>
            </div>
            <div *ngIf="data.groups.rows.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMoreGroups()">
                <span *ngIf="!showMoreGroups">Show {{ data.groups.rows.length - SECTION_SOFT_MAX }} More</span>
                <span *ngIf="showMoreGroups">Show Less</span>
            </div>

            <div class="chat-row-header">
                <p>PEOPLE</p>
            </div>
            <div (click)="selectRow(row)" *ngFor="let row of getVisiblePeople()" class="chat-row people">
                <i class="fa fa-user" [class.active]="!!row.activeCount"></i>
                <p class="row-title">{{ row.chat.title }}</p>
                <div *ngIf="!!row.unreadMessagesCount" class="notification-circle push-right">{{ row.unreadMessagesCount }}</div>
            </div>
            <div *ngIf="data.people.rows.length > SECTION_SOFT_MAX" class="chat-row show-more" (click)="toggleShowMorePeople()">
                <span *ngIf="!showMorePeople">Show {{ data.people.rows.length - SECTION_SOFT_MAX }} More</span>
                <span *ngIf="showMorePeople">Show Less</span>
            </div>

        </div>

        <div *ngIf="searchResults" class="chat-body">
            <div *ngFor="let row of searchResults" class="chat-row recent">
                <span *ngIf="row.chat.type === ChatRowType.GROUP" [class.active]="!!row.activeCount">{{ row.activeCount }}</span>
                <i *ngIf="row.chat.type === ChatRowType.PERSON && !row.activeCount" class="fa fa-user-times"></i>
                <i *ngIf="row.chat.type === ChatRowType.PERSON && !!row.activeCount" class="fa fa-user active"></i>
                <p (click)="selectRow(row)" class="row-title">{{ row.chat.title }}</p>
            </div>
        </div>

    </div>
    <div *ngIf="isOpen && isLoaded && openRow && !isSupportOpen" class="chat-messages-view">
        <div class="chat-messages-header">
            <div class="chat-buttons">
                <i (click)="closeRow()" class="fa fa-caret-left fa-2x back-button"></i>
                <div *ngIf="hasUnread" class="notification-circle">
                    {{ getTotalUnread() }}
                </div>
            </div>
            <p class="messages-header-title">{{ openRow.chat.title }}</p>
        </div>
        <hr/>

        <div class="chat-main-container" [style.overflow]="isEmojiPickerShowing ? 'hidden' : 'auto'">
            <div *ngIf="isEmojiPickerShowing" class="emoji-picker-container">
                <chat-emoji-picker (insertHandler)="emojiHandler($event)"></chat-emoji-picker>
            </div>
            <div *ngIf="!isEmojiPickerShowing" class="chat-messages" #chatHistory>
                <div *ngFor="let message of openRow.chat.chat" class="chat-message-row">
                    <chat-message [message]="message"></chat-message>
                </div>
            </div>
        </div>
        <div class="chat-send-message">
            <div (click)="toggleEmojis()" class="emoji-button">
                <i class="fa fa-smile-o fa-2x"></i>
            </div>
            <div class="message-input-container">
                <div class="input-container">
                    <textarea #textInput="cdkTextareaAutosize"
                              cdkTextareaAutosize [cdkAutosizeMinRows]="1" [cdkAutosizeMaxRows]="maxRows()"
                              class="message-text-area" placeholder="Send a message"
                              [formControl]="chatField" (keyup.enter)="sendMessage()"
                              (input)="inputHandler()" (change)="changeHandler()"
                    ></textarea>
                </div>
            </div>
            <div (click)="sendMessage()" class="send-button">
                <i class="fa fa-caret-right fa-2x"></i>
            </div>
        </div>
    </div>

    <!-- XXX Support Chat -->
    <div *ngIf="isOpen && isLoaded && isSupportOpen && !openRow" class="chat-messages-view">
        <div class="chat-messages-header">
            <div class="chat-buttons">
                <i (click)="closeRow()" class="fa fa-caret-left fa-2x back-button"></i>
                <div *ngIf="hasUnread" class="notification-circle">
                    {{ getTotalUnread() }}
                </div>
            </div>
            <p class="messages-header-title">{{ data.supportChat.chat.title }}</p>
        </div>
        <hr/>

        <div class="chat-main-container" [style.overflow]="isEmojiPickerShowing ? 'hidden' : 'auto'">
            <div *ngIf="isEmojiPickerShowing" class="emoji-picker-container">
                <chat-emoji-picker (insertHandler)="emojiHandler($event)"></chat-emoji-picker>
            </div>
            <div *ngIf="!isEmojiPickerShowing" class="chat-messages" #chatHistory>
                <div *ngFor="let message of data.supportChat.chat.chat" class="chat-message-row">
                    <chat-message [message]="message"></chat-message>
                </div>
            </div>
        </div>
        <div class="chat-send-message">
            <div (click)="toggleEmojis()" class="emoji-button">
                <i class="fa fa-smile-o fa-2x"></i>
            </div>
            <div class="message-input-container">
                <div class="input-container">
                    <textarea #textInput="cdkTextareaAutosize"
                              cdkTextareaAutosize [cdkAutosizeMinRows]="1" [cdkAutosizeMaxRows]="maxRows()"
                              class="message-text-area" placeholder="Send a message"
                              [formControl]="chatField" (keyup.enter)="sendSupportMessage()"
                              (input)="inputHandler()" (change)="changeHandler()"
                    ></textarea>
                </div>
            </div>
            <div (click)="sendSupportMessage()" class="send-button">
                <i class="fa fa-caret-right fa-2x"></i>
            </div>
        </div>
    </div>
    <!-- End Support Chat -->


    <div *ngIf="!isOpen" (click)="toggleOpen()" class="open-chat-button">
        <p>Marking Chat</p>
        <div *ngIf="hasUnread" class="notification-circle closed-notification">
            {{ getTotalUnread() }}
        </div>
    </div>
</div>
