import * as _ from 'lodash';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IContentElementInput, InputFormat, QuestionState, IEntryStateInputText, IEntryStateInputNumber, IEntryStateInputMath, IEntryState, IEntryStateInputFraction, IEntryStateInputRatio, ScoringTypes, IEntryStateScored, getElementWeight } from '../models';
import { FormControl } from '@angular/forms';
import { ScaleLegendComponent } from '@swimlane/ngx-charts';
import { LangService } from '../../core/lang.service';

const SCORING_TYPE = ScoringTypes.REVIEW;

const parseNumber = (num:string ,lang:string) =>{
  num = ''+num
  if (lang === 'fr'){
    num = num.replace( /\,/g, '.' )
  }
  return +(num.replace( /[^0-9.]/g, '' ));
}

const checkBlankValue = (val:any) => (val === null || val === undefined || val === '');
@Component({
  selector: 'element-render-input',
  templateUrl: './element-render-input.component.html',
  styleUrls: ['./element-render-input.component.scss']
})
export class ElementRenderInputComponent implements OnInit, OnChanges {

  @Input() element:IContentElementInput;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  lastTrackedQuestionState;
  numberInput = new FormControl('');
  textInput = new FormControl('');
  fractionWholeInput = new FormControl('');
  fractionNumeInput = new FormControl('');
  fractionDenoInput = new FormControl('');
  isStarted:boolean;
  latexCapture = {latexInput:''};
  ratioTerms:FormControl[];

  constructor(
    private lang:LangService,
  ) { }

  ngOnInit() {
    if (this.element.startingLatex){
      this.latexCapture.latexInput = this.element.startingLatex
    }
    
    this.numberInput.valueChanges.subscribe( () => this.updateState() );
    this.textInput.valueChanges.subscribe( () => this.updateState() );
    this.fractionWholeInput.valueChanges.subscribe( () => this.updateState() );
    this.fractionNumeInput.valueChanges.subscribe( () => this.updateState() );
    this.fractionDenoInput.valueChanges.subscribe( () => this.updateState() );
    /* math element has change even in dom */
    if (this.element.format === InputFormat.RATIO){
      this.ensureRatioTerms(); // subscription is done here
    }
    
  }
  ensureRatioTerms(){
    if (!this.ratioTerms && this.element.ratioTerms){
      this.ratioTerms = [];
      this.element.ratioTerms.forEach(() => {
        const fc = new FormControl()
        this.ratioTerms.push(fc)
        fc.valueChanges.subscribe( () => this.updateState() );
      });
    }
  }

  ngOnChanges(){
    if (this.lastTrackedQuestionState !== this.questionState){
      this.lastTrackedQuestionState = this.questionState;
      if (this.questionState){
        this.handleNewState();
      }
    }
  }

  isFormatTypeNumber(){ return this.element.format === InputFormat.NUMBER }
  isFormatTypeFraction(){ return this.element.format === InputFormat.FRACTION }
  isFormatTypeAlgebra(){ return this.element.format === InputFormat.ALGEBRA }
  isFormatTypeRatio(){ return this.element.format === InputFormat.RATIO }
  isFormatTypeTextShort(){ return (this.element.format === InputFormat.TEXT) && (this.isTextEntryShort())   }
  isFormatTypeTextLong(){ return (this.element.format === InputFormat.TEXT) && (!this.isTextEntryShort())  }

  isTextEntryShort(){
    return (this.element.maxChars && this.element.maxChars < 20);
  }

  getEntryState(){
    switch (this.element.format){
      case InputFormat.TEXT:     return this.getInputTextState();
      case InputFormat.NUMBER:   return this.getInputNumberState();
      case InputFormat.FRACTION: return this.getInputFractionState();
      case InputFormat.RATIO:    return this.getInputRatioState();
      case InputFormat.ALGEBRA:  return this.getInputAlgebraState();
    }
    return <IEntryStateInputText>{
      type: 'input-longtext',
      isCustomGrading: true,
      isStarted: false,
      isFilled: false,
      str: '{ERROR}',
    }
  }

  getInputNumberState(){
    const value = this.numberInput.value;
    const isFilled = !checkBlankValue(value);
    const weight = getElementWeight(this.element);
    let isCorrect = this.isNumberCorrect(); 
    return <IEntryStateInputNumber>{
      type: 'input-number',
      isCorrect,
      isStarted: this.isStarted,
      isFilled,
      value,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }
  getInputFractionState(){
    let wholenumber = this.fractionWholeInput.value;
    let numerator = this.fractionNumeInput.value;
    let denominator = this.fractionDenoInput.value;
    let isFilled = true;
    if (this.element.isMixedNumber && checkBlankValue(wholenumber)){ isFilled = false; }
    if (checkBlankValue(numerator)){ isFilled = false; }
    if (checkBlankValue(denominator)){ isFilled = false; }
    let isCorrect = false;
    const weight = getElementWeight(this.element);
    let wholeGiven:number = 0;
    let wholeInput:number = 0;
    const lang = this.lang.c();
    let numeInput:number = parseNumber(numerator, lang);
    let denoInput:number = parseNumber(denominator, lang);
    let numeGiven:number = parseNumber(this.element.fracNumerator, lang);
    let denoGiven:number = parseNumber(this.element.fracDenominator, lang);
    if (this.element.isMixedNumber){
      wholeGiven = parseNumber(this.element.fracWholeNumber, lang);
      wholeInput = parseNumber(wholenumber, lang);
    }
    if (this.element.isStrictSimplified){
      isCorrect = (wholeGiven === wholeInput) 
               && (numeGiven === numeInput)
               && (denoGiven === denoInput);
    }
    else{
      if (denominator === 0 && denoGiven === 0){
        isCorrect = true;
      }
      else {
        const approx = (wholeGiven + (numeGiven/denoGiven)) - (wholeInput + (numeInput / denoInput));
        if (Math.abs(approx) < 0.000001){
          isCorrect = true;
        }
      }
    }
    return <IEntryStateInputFraction>{
      type: 'input-fraction',
      isCorrect,
      isStarted: this.isStarted,
      isFilled,
      wholenumber,
      numerator,
      denominator,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }
  getInputRatioState(){
    let isFilled = true;
    this.ensureRatioTerms();
    const lang = this.lang.c();
    const terms = this.ratioTerms.map(termFc => {
      const value = termFc.value;
      if (checkBlankValue(value)){
        isFilled = false;
      }
      return parseNumber(value, lang);
    })
    let isCorrect = false;
    const weight = getElementWeight(this.element);
    
    const given = this.element.ratioTerms.map(val => parseNumber(val, lang) ) || [];
    const input = terms || [];
    if (given.length === 1){ // rare case?
      if (given[0] === input[0]){
        isCorrect = true;
      }
    }
    else {
      let scalingFactor = 1;
      if (!this.element.isStrictLowestTerms){
        scalingFactor = input[0] / given[0];
      }
      let isAllMatched = true; // until contra case found
      for (let i=0; i<given.length; i++){
        if ( Math.abs(given[i] - input[i]*scalingFactor) > 0.00001){
          isAllMatched = false;
        }
      }
      isCorrect = isAllMatched;
    }
    return <IEntryStateInputRatio>{
      type: 'input-ratio',
      isCorrect,
      isStarted: this.isStarted,
      isFilled,
      terms,
      score: isCorrect ? weight : 0,
      weight,
      scoring_type: ScoringTypes.REVIEW, 
    }
  }
  getInputAlgebraState(){
    const latex = this.latexCapture.latexInput;
    const isFilled = !checkBlankValue(latex);
    return <IEntryStateInputMath>{
      type: 'input-algebra',
      isCustomGrading: true,
      isStarted: this.isStarted,
      isFilled,
      latex,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }
  getInputTextState(){
    const str = this.textInput.value;
    const isFilled = !checkBlankValue(str);
    return <IEntryStateInputText>{
      type: 'input-longtext',
      isCustomGrading: true,
      isStarted: this.isStarted,
      isFilled,
      str,
      score: 0,
      weight: getElementWeight(this.element),
      scoring_type: ScoringTypes.MANUAL, 
    }
  }

  getRemainingCharacters(){
    const input = this.textInput.value || '';
    return this.element.maxChars - input.length;
  }

  isNumberCorrect(){
    if (this.numberInput.value !== null && this.numberInput.value != ''){
      const lang = this.lang.c();
      let valInput = parseNumber(this.numberInput.value, lang);
      let valExpected = parseNumber(this.element.value, lang);
      let tolerance = this.element.roundingTolerance || 0.00000001
      if( Math.abs(valExpected - valInput) < tolerance ){
        return true
      }
    }
    return false;
  }

  updateState = _.throttle(() => {
    this.questionState[this.element.entryId] = this.getEntryState();
  }, 500);

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
      if (!this.questionState[entryId]){
        let entryState:IEntryStateScored = {
          type: 'input',
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  handleNewState(){
    if (this.questionState){
      const entryState:IEntryState = this.questionState[this.element.entryId];
      if (entryState){
        this.isStarted = entryState.isStarted;
        this.injectStateToDom(entryState)
      }
      else{
        this.ensureState();
      }
    }
  }
  injectStateToDom(state:IEntryState){
    switch (this.element.format){
      case InputFormat.TEXT: return this.injectTextState(<IEntryStateInputText> state);
      case InputFormat.NUMBER: return this.injectNumberState(<IEntryStateInputNumber> state);
      case InputFormat.ALGEBRA: return this.injectAlgebraState(<IEntryStateInputMath> state);
      case InputFormat.FRACTION: return this.injectFractionState(<IEntryStateInputFraction> state);
      case InputFormat.RATIO: return this.injectRatioState(<IEntryStateInputRatio> state);
    }
  }
  injectNumberState(state:IEntryStateInputNumber){
    this.numberInput.setValue(state.value);
  }
  injectFractionState(state:IEntryStateInputFraction){
    this.fractionWholeInput.setValue(state.wholenumber);
    this.fractionNumeInput.setValue(state.numerator);
    this.fractionDenoInput.setValue(state.denominator);
  }
  injectRatioState(state:IEntryStateInputRatio){
    this.ensureRatioTerms();
    if (state.terms){
      state.terms.forEach( (value, i) => {
        const termFc = this.ratioTerms[i];
        if (termFc){
          termFc.setValue(value);
        }
      })
    }
  }
  injectAlgebraState(state:IEntryStateInputMath){
    this.latexCapture.latexInput = state.latex;
  }
  injectTextState(state:IEntryStateInputText){
    this.textInput.setValue(state.str);
  }
  
}
