import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';

@Component({
  selector: 'tra',
  templateUrl: './tra.component.html',
  styleUrls: ['./tra.component.scss']
})
export class TraComponent implements OnInit {

  @Input() id: number;
  @Input() slug: string;
  @Input() whitelabel: string; // whitelabel text which needs to be routed to determine what it should be

  val: string;

  constructor(
    private langService:LangService,
  ) { }

  ngOnInit() {
    this.val = this.langService.traWithWhitelabel(this.whitelabel, this.slug || this.id);
  }

}
