import {IMarker} from '../data';
import {randomFirstName, randomLastName} from '../../../constants/fakenames';

export const REAL_MARKERS: IMarker[] = [
    {
        'id': 6270,
        'first_name': 'Demo 1',
        'last_name': 'Marker',
        'notes': ''
    },
    {
        'id': 6271,
        'first_name': 'Demo 2',
        'last_name': 'Marker',
        'notes': ''
    },
    {
        'id': 6272,
        'first_name': 'Demo 3',
        'last_name': 'Marker',
        'notes': ''
    }
];

export const DUMMY_MARKERS: IMarker[] = [
    {
        'id': 1,
        'first_name': 'Jaquelin',
        'last_name': 'Gillett',
        'notes': ''
    },
    {
        'id': 2,
        'first_name': 'Brandy',
        'last_name': 'Kelula',
        'notes': ''
    },
    {
        'id': 3,
        'first_name': 'Arly',
        'last_name': 'Davidde',
        'notes': ''
    },
    {
        'id': 4,
        'first_name': 'Maurene',
        'last_name': 'Zolner',
        'notes': ''
    },
    {
        'id': 5,
        'first_name': 'Constanta',
        'last_name': 'Tawney',
        'notes': ''
    },
    {
        'id': 6,
        'first_name': 'Kassia',
        'last_name': 'Felike',
        'notes': ''
    },
    {
        'id': 7,
        'first_name': 'Harmony',
        'last_name': 'Lorine',
        'notes': ''
    },
    {
        'id': 8,
        'first_name': 'Arly',
        'last_name': 'Lynus',
        'notes': ''
    },
    {
        'id': 9,
        'first_name': 'Phelia',
        'last_name': 'Crispas',
        'notes': ''
    },
    {
        'id': 10,
        'first_name': 'Norene',
        'last_name': 'Magnuson',
        'notes': ''
    },
    {
        'id': 11,
        'first_name': 'Constanta',
        'last_name': 'Hosfmann',
        'notes': ''
    },
    {
        'id': 12,
        'first_name': 'Dalila',
        'last_name': 'Reba',
        'notes': ''
    },
    {
        'id': 13,
        'first_name': 'Evie',
        'last_name': 'Franci',
        'notes': ''
    },
    {
        'id': 14,
        'first_name': 'Rosalyn',
        'last_name': 'Shela',
        'notes': ''
    },
    {
        'id': 15,
        'first_name': 'Charla',
        'last_name': 'Benson',
        'notes': ''
    },
    {
        'id': 16,
        'first_name': 'Randene',
        'last_name': 'Liederman',
        'notes': ''
    },
    {
        'id': 17,
        'first_name': 'Charla',
        'last_name': 'Wilscam',
        'notes': ''
    },
    {
        'id': 18,
        'first_name': 'Lucinda',
        'last_name': 'Courtenay',
        'notes': ''
    },
    {
        'id': 19,
        'first_name': 'Celle',
        'last_name': 'Reba',
        'notes': ''
    },
    {
        'id': 20,
        'first_name': 'Olivia',
        'last_name': 'Morganne',
        'notes': ''
    },
    {
        'id': 21,
        'first_name': 'Amabelle',
        'last_name': 'Magnuson',
        'notes': ''
    },
    {
        'id': 22,
        'first_name': 'Eolande',
        'last_name': 'Amena',
        'notes': ''
    },
    {
        'id': 23,
        'first_name': 'Cara',
        'last_name': 'Reba',
        'notes': ''
    },
    {
        'id': 24,
        'first_name': 'Valera',
        'last_name': 'Veleda',
        'notes': ''
    },
    {
        'id': 25,
        'first_name': 'Anna-Diana',
        'last_name': 'Bourque',
        'notes': ''
    },
    {
        'id': 26,
        'first_name': 'Arlyne',
        'last_name': 'Chaim',
        'notes': ''
    },
    {
        'id': 27,
        'first_name': 'Eada',
        'last_name': 'Kleeman',
        'notes': ''
    },
    {
        'id': 28,
        'first_name': 'Brandy',
        'last_name': 'Timothea',
        'notes': ''
    },
    {
        'id': 29,
        'first_name': 'Mirabella',
        'last_name': 'Hurff',
        'notes': ''
    },
    {
        'id': 30,
        'first_name': 'Gwen',
        'last_name': 'Schecter',
        'notes': ''
    },
    {
        'id': 31,
        'first_name': 'Hertha',
        'last_name': 'Caughey',
        'notes': ''
    },
    {
        'id': 32,
        'first_name': 'Saraann',
        'last_name': 'Corso',
        'notes': ''
    },
    {
        'id': 33,
        'first_name': 'Eleonora',
        'last_name': 'Liederman',
        'notes': ''
    },
    {
        'id': 34,
        'first_name': 'Lucinda',
        'last_name': 'Diahann',
        'notes': ''
    },
    {
        'id': 35,
        'first_name': 'Constanta',
        'last_name': 'Liederman',
        'notes': ''
    },
    {
        'id': 36,
        'first_name': 'Lise',
        'last_name': 'Cristionna',
        'notes': ''
    },
    {
        'id': 37,
        'first_name': 'Valry',
        'last_name': 'Oram',
        'notes': ''
    },
    {
        'id': 38,
        'first_name': 'Trula',
        'last_name': 'Benson',
        'notes': ''
    },
    {
        'id': 39,
        'first_name': 'Carly',
        'last_name': 'Thilde',
        'notes': ''
    },
    {
        'id': 40,
        'first_name': 'Lusa',
        'last_name': 'Sinai',
        'notes': ''
    },
    {
        'id': 41,
        'first_name': 'Nicol',
        'last_name': 'Winer',
        'notes': ''
    },
    {
        'id': 42,
        'first_name': 'Jaquelin',
        'last_name': 'Vinita',
        'notes': ''
    },
    {
        'id': 43,
        'first_name': 'Brandy',
        'last_name': 'Mooney',
        'notes': ''
    },
    {
        'id': 44,
        'first_name': 'Jaquelin',
        'last_name': 'Vinita',
        'notes': ''
    },
    {
        'id': 45,
        'first_name': 'Arlyne',
        'last_name': 'Caughey',
        'notes': ''
    },
    {
        'id': 46,
        'first_name': 'Cybil',
        'last_name': 'Hurff',
        'notes': ''
    },
    {
        'id': 47,
        'first_name': 'Saraann',
        'last_name': 'Prudie',
        'notes': ''
    },
    {
        'id': 48,
        'first_name': 'Claire',
        'last_name': 'Sinai',
        'notes': ''
    },
    {
        'id': 49,
        'first_name': 'Madel',
        'last_name': 'Hurff',
        'notes': ''
    },
    {
        'id': 50,
        'first_name': 'Anabal',
        'last_name': 'Jarlathus',
        'notes': ''
    },
    {
        'id': 51,
        'first_name': 'Molli',
        'last_name': 'Azral',
        'notes': ''
    },
    {
        'id': 52,
        'first_name': 'Eada',
        'last_name': 'Lorine',
        'notes': ''
    },
    {
        'id': 53,
        'first_name': 'Mehetabel',
        'last_name': 'Oram',
        'notes': ''
    },
    {
        'id': 54,
        'first_name': 'Anabal',
        'last_name': 'Ludvig',
        'notes': ''
    },
    {
        'id': 55,
        'first_name': 'Constanta',
        'last_name': 'Naresh',
        'notes': ''
    },
    {
        'id': 56,
        'first_name': 'Randene',
        'last_name': 'Veleda',
        'notes': ''
    },
    {
        'id': 57,
        'first_name': 'Eada',
        'last_name': 'Winifield',
        'notes': ''
    },
    {
        'id': 58,
        'first_name': 'Stacia',
        'last_name': 'Grantham',
        'notes': ''
    },
    {
        'id': 59,
        'first_name': 'Talyah',
        'last_name': 'Alfi',
        'notes': ''
    },
    {
        'id': 60,
        'first_name': 'Loella',
        'last_name': 'Walden',
        'notes': ''
    }
];

export function getNumeracyMarkers() {
    return [REAL_MARKERS[0]].concat(DUMMY_MARKERS.slice(0, 30));
}
export function getLiteracyMarkers() {
    return [REAL_MARKERS[1], REAL_MARKERS[2]].concat(DUMMY_MARKERS.slice(30, 60));
}

const markers: IMarker[] = [...Array(70)].map((_, i) => {
    return {
        id: i + 1,
        first_name: randomFirstName(),
        last_name: randomLastName(),
        notes: '',
    };
});

export function genMarkers() {
    console.log(markers);
    // 1. Print to console.
    // 2. store as global variable (right click the printed array in the console).
    // 3. copy(temp1).
    // 4. paste here.
}
