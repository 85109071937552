import {Component, Input, OnInit} from '@angular/core';
import {IMessage} from '../chat-box/chat-box.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {AuthService} from '../../api/auth.service';

interface IMessageSection {
  isEmoji: boolean;
  message: string;
}

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  MY_UID = 1;

  @Input() message: IMessage;

  // public messageArray: IMessageSection[] = [];

  // private colonMatcher = '\\:(.*?)\\:'; // \:(.*?)\: ===> This is the best regex thus far

  constructor(
      public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.MY_UID = this.auth.user().value.uid;
    // const arr = this.message.message.split(/:(.*?):/);

    /*
    let messageArray: IMessageSection[] = [];
    let wasLastEmoji = true;
    arr.forEach(s => {
      if (!!s && this.message.message.charAt(0) === ':') {
        wasLastEmoji = true;
        const newS = ':' + s + ':';
        messageArray.push({isEmoji: true, message: newS});
      } else if (!!s && !wasLastEmoji) {
        wasLastEmoji = true;
        const newS = ':' + s + ':';
        messageArray.push({isEmoji: true, message: newS});
      } else if (!!s) {
        wasLastEmoji = false;
        messageArray.push({isEmoji: false, message: s});
      }
    });
    this.messageArray = messageArray;
    */

  }

}
