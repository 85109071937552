
import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IPaginatorCtrl {
  pageSize: number,
  totalRecords?: number,
  loadNewData: (skip:number) => Promise<any>;
  //////////////////////
  currentPage?: number,
  totalPages?: number;
  configurablePageSize?: boolean;
}

enum PageSize {
  SIZE_10 = 10,
  SIZE_50 = 50,
  SIZE_100 = 100,
  SIZE_ALL = 0,
}

interface IPageSize {
  id: PageSize,
  size: number;
}

const PageSizes: IPageSize[] = [
  { id: PageSize.SIZE_10, size: 10},
  { id: PageSize.SIZE_50, size: 50},
  { id: PageSize.SIZE_100, size: 100},
  { id: PageSize.SIZE_ALL, size: 0},
];

const pageSizeMap: Map<PageSize, IPageSize> = new Map();
PageSizes.forEach( ps => pageSizeMap.set(ps.id, ps));

const DEFAULT_PAGE_SIZE = PageSize.SIZE_10;

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input() model: IPaginatorCtrl;
  @Input() page: number;
  @Input() numEntries: number;
  @Input() isStrip: boolean = true;
  @Output() pageChange = new EventEmitter();
  @Input() isSmall: boolean;

  constructor(
    
  ) { }

  public isLoading:boolean;
  public currentPage:FormControl = new FormControl();
  public selectedPageSize:FormControl = new FormControl();
  PageSizes = PageSizes;

  ngOnInit(): void {
    if (this.model.configurablePageSize) {
      this.selectedPageSize.setValue(pageSizeMap.get(DEFAULT_PAGE_SIZE).size);
      this.model.pageSize = pageSizeMap.get(DEFAULT_PAGE_SIZE).size; 
    }
    if (!this.model.currentPage ){
      this.model.currentPage = 1;
    }
    this.model.totalPages = 1;
    this.currentPage.setValue(this.model.currentPage);
    this.refreshData();
  }

  selectPageSize(pageSize: number) {
    pageSize = +pageSize || this.model.totalRecords;
    this.model.pageSize = pageSize;
    this.model.currentPage = 1;
    this.currentPage.setValue(this.model.currentPage);
    this.refreshData();
  }

  getPageSizeLabel(ps: IPageSize): string {
    return ps.id === PageSize.SIZE_ALL ? 'lbl_page_size_all' : String(ps.size);
  }
  
  ngOnChanges(changes:SimpleChanges){
    if (changes.page){
      this.model.currentPage = this.page;
      if (this.model.currentPage<=0) this.model.currentPage = 1;
      this.currentPage.setValue(this.model.currentPage);
    }
    if (changes.numEntries && this.numEntries){
      this.refreshData();
    }
  }

  updatePage() {
    // this.constrainCurrentPage();
    if (this.currentPage.value != this.model.currentPage){
      if (this.currentPage.value > this.getMaxPages()) {
        this.model.currentPage = this.getMaxPages()
      } else if (this.currentPage.value < 1) {
        this.model.currentPage = 1
      } else {
        this.model.currentPage = this.currentPage.value;
      }
      this.refreshData();
      this.pageChange.emit();
    }
  }

  constrainCurrentPage(){
    let val = this.currentPage.value;
    if (!val){ val = 1; }
    if (val > this.model.totalPages){ val = this.model.totalPages }
    if (val < 1){ val = 1 }
    if (val != this.currentPage.value){
      this.currentPage.setValue(val);
    }
  }

  refreshData(){
    this.isLoading = true;
    while (this.numEntries < (this.model.pageSize * (this.model.currentPage-1) + 1)) {
      --this.model.currentPage;
    }
    const numSkip = this.model.pageSize * (this.model.currentPage-1);
    this.model
      .loadNewData(numSkip)
      .then(res => {
        this.isLoading = false;
        if (this.model.totalRecords){
          this.model.totalPages = Math.ceil(this.model.totalRecords / this.model.pageSize)
        }
        else{
          this.model.totalPages = 1;
        }
        this.constrainCurrentPage();
        if (this.model.currentPage > 1 && this.model.currentPage > this.model.totalPages){
          this.currentPage.setValue(this.model.totalPages);
        }
      })
  }
  getCurrentPageNum(){
    return this.model.currentPage;
  }
  getMaxPages(){
    return this.model.totalPages;
  }
  isOnFirstPage(){
    return this.model.currentPage === 1;
  }
  isOnLastPage(){
    return this.model.currentPage === this.model.totalPages;
  }
  updateCurrentPage(newPageNum:number){
    this.currentPage.setValue(newPageNum);
  }
  prevPage(){
    if (this.model.currentPage > 1){
      this.updateCurrentPage(this.model.currentPage - 1);
      this.updatePage();
    }
  }
  nextPage(){
    if (this.model.currentPage < this.model.totalPages){
      this.updateCurrentPage(this.model.currentPage + 1);
      this.updatePage();
    }
    this.refreshData();
  }

}

