
<div 
  #elPosContainer
  class="pos-container"
  [ngStyle]="{
    'width.px' :  element.width, 
    'height.px' : element.height
  }"
>
<!-- need to split this behaviour for bg/drg/target -->

  <!-- Background -->
  <div 
    *ngFor="let elPos of backgroundElements" 
    class="pos-el" 
    style="pointer-events: none;"
    [ngStyle]="elPos.style"
  >
    <element-render 
      *ngIf="elPos.ref.element" 
      [contentElement]="elPos.ref.element" 
      [isLocked]="true"  
      [questionState]="{}"
    ></element-render>
  </div>

  <!-- Targets -->
  <!-- [class.draggable-target]="elPos.isTarget" -->
  <div 
    *ngFor="let elPos of targets" 
    class="pos-el draggable-target" 
    [ngStyle]="elPos.style"
  ></div>

  <!-- Draggable -->
  <div 
    *ngFor="let elPos of draggables" 
    cdkDrag 
    cdkDragBoundary=".pos-container"
    class="drag-el" 
    [ngStyle]="elPos.style"
  >
    <element-render 
      *ngIf="elPos.ref.element" 
      [contentElement]="elPos.ref.element" 
      [isLocked]="true"  
      [questionState]="{}"
    ></element-render>
  </div>
</div>