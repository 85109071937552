<div class="tabs">
  <ul>
    <li 
      *ngFor="let tab of menuTabs" 
      [class.is-hidden]="!isTabHidden(tab)"
      [class.is-active]="isActiveId(tab.id)"
      (click)="setActiveId(tab.id)"
    >
      <a>
        <span>{{tab.caption}} </span>
        <span *ngIf="tab.isCountEnabled" style="margin-left:0.3em;">
          ({{tab.count()}})
        </span>
      </a>
    </li>
  </ul>
</div>