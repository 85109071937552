import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit, OnChanges {

  @Input() fc:FormControl;
  @Input() isDisabled:boolean;

  constructor() { }

  datePicker = new FormControl();
  isValid:boolean = true;

  ngOnInit(): void {
    this.fc.valueChanges.subscribe(v => this.injectNewDate());
    this.datePicker.valueChanges.subscribe(v => this.onDatePickerChange());
    this.injectNewDate();
    this.updateDisabledStatus();
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.isDisabled){ this.updateDisabledStatus() }
  }

  updateDisabledStatus(){
    if (this.isDisabled){
      this.datePicker.disable();
    }
    else{
      this.datePicker.enable();
    }
  }

  injectNewDate(){
    const date:string = this.fc.value;
    if (date){
      this.datePicker.setValue(date);
    }
  }

  onDatePickerChange(){
    const dateStr:string = this.datePicker.value;
    this.isValid = true;
    if (dateStr){
        this.sanitizeDateStr(dateStr);
        // TODO: ADD SANITIZE FOR TIME PART  
    }
  }

  onDateChange($event){
    this.sanitizeDateStr($event.target.value)
  }

  sanitizeDateStr(dateStr){
   // const dateStrSanitized = dateStr.substr(0, 10);
    const datePieces = dateStr.split('-');  
    if(datePieces.length > 2 && datePieces[2].toString().length > 1 ){
    // else{
    //   if(datePieces[2] === ''){this.isValid = false}
    // }   
    if (datePieces[0] && datePieces[0].length !== 4) { this.isValid = false; }
    if (datePieces[1] && datePieces[1].length !== 2) { this.isValid = false; }
    if (datePieces[2] && datePieces[2].length !== 2) { this.isValid = false; }
    if (datePieces[1] && +datePieces[1] > 12) { this.isValid = false; }
    if (datePieces[2] && +datePieces[2] > 32) { this.isValid = false; }
    }
    if (this.isValid) {
      if (dateStr !== this.fc.value) {
        this.fc.setValue(dateStr);
      }
    }
  }

}
