<div>

  <div *ngIf="!isFormatTypeTextLong()">
    <div style="display:inline-block; vertical-align:middle; margin-right:0.2em;" *ngIf="element.prefix">
      {{element.prefix}}
    </div>
    <div style="display:inline-block; vertical-align:middle; ">
      <div *ngIf="isFormatTypeNumber()">
        <input type="text" [formControl]="numberInput" class="input" style="width:5em; text-align:center" >
      </div>
      <div *ngIf="isFormatTypeFraction()">
        <div style="display:inline-block; vertical-align:middle;">
          <div *ngIf="element.isMixedNumber" style="display:inline-block; vertical-align:middle; margin-right:0.2em;">
            <input type="text" [formControl]="fractionWholeInput" class="input input-fraction"  >
          </div>
          <div style="display:inline-block; vertical-align:middle;">
            <input type="text" [formControl]="fractionNumeInput" class="input  input-fraction is-small"  >
            <div style="margin-bottom:6px; border:1px solid #333;"></div>
            <input type="text" [formControl]="fractionDenoInput" class="input  input-fraction is-small"  >
          </div>
        </div>
      </div>
      <div *ngIf="isFormatTypeTextShort()">
        <input 
          type="text" 
          class="input" 
          [formControl]="textInput" 
          [ngStyle]="{'width.em':element.maxChars*1 + 2}"
        />
      </div>
      <div *ngIf="isFormatTypeRatio()">
        <span *ngFor="let listEntry of ratioTerms; let listIndex = index;">
          <span *ngIf="listIndex !== 0" style="margin-left:0.3em; margin-right:0.3em;">:</span>
          <input type="text" class="input" [formControl]="listEntry" style="width:40px; vertical-align:middle; text-align:center">
        </span>
      </div>
      <div *ngIf="isFormatTypeAlgebra()" style="min-width:12em">
        <capture-math 
          [obj]="latexCapture" 
          prop="latexInput"
          [isManualKeyboard]="true"
          (onChange)="updateState()"
        ></capture-math>
      </div>
    </div>
    <div style="display:inline-block; vertical-align:middle; margin-left:0.2em;" *ngIf="element.suffix">
      {{element.suffix}}
    </div>
  </div>
  
  <div *ngIf="isFormatTypeTextLong()">
    <textarea 
      class="textarea is-fullwidth" 
      style="width: 24em;"
      [maxlength]="element.maxChars"
      [formControl]="textInput"
    ></textarea>
    <small *ngIf="element.maxChars" class="remaining-chars" [class.is-complete]="getRemainingCharacters() == 0" >
      Remaining characters: {{getRemainingCharacters()}}
    </small>
  </div>


</div>
