import {IAssessment} from '../data';
import {literacyItems, numeracyItems} from './items';

export const numeracyAssessment: IAssessment = {
    id: 1, name: 'Grade 10 Sample Graduation Numeracy Assessment - Form A',
    items: numeracyItems, start_date: '2020-08-28T13:00:00.000Z', end_date: '2020-09-01T13:00:00.000Z',
    isActive: true, isInMarkingSession: true,
};
export const literacyAssessment: IAssessment = {
    id: 2, name: 'Grade 10 Sample Graduation Literacy Assessment',
    items: literacyItems, start_date: '2020-08-28T13:00:00.000Z', end_date: '2020-09-01T13:00:00.000Z',
    isActive: true, isInMarkingSession: true,
};

export const allAssessments: IAssessment[] = [numeracyAssessment, literacyAssessment];

