import { Component, OnInit } from '@angular/core';
import {ENS_TOPICS} from './data/topics';
import {ENS_ITEM_MAP} from './data/item-map';
import { AuthService } from '../../api/auth.service';

enum ViewSub {
  LANDING = 'LANDING',
  TEST_RUNNER = 'TEST_RUNNER',
  DIAGNOSTIC = 'DIAGNOSTIC',
  CERTIFICATE = 'CERTIFICATE',
  CONGRATULATIONS = 'CONGRATULATIONS'
} 

export interface IENSCategory {
  name: string,
  score: number,
  scoreTally:number,
  scoreMax: number,
}
type ICategory = IENSCategory;

@Component({
  selector: 'view-ens-homepage',
  templateUrl: './view-ens-homepage.component.html',
  styleUrls: ['./view-ens-homepage.component.scss']
})
export class ViewEnsHomepageComponent implements OnInit {

  constructor(
    private auth:AuthService,
  ) { }

  ViewSub = ViewSub;
  currentSubView:ViewSub = ViewSub.LANDING;

  topics = ENS_TOPICS;
  itemMap = ENS_ITEM_MAP;
  questionStates = {};
  testDesign:any;
  questionSrcDb;
  categoriesTop:ICategory[];
  categoriesBott:ICategory[];

  name:string;
  email:string;
  accessCode:string;

  ngOnInit(): void {
    this.initQuestionStates();
  }

  initQuestionStates(){
    // let cachedState = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    // if (cachedState && cachedState !== '') {
    //   this.testState = deobf(cachedState);
    //   if (this.testState.languageCode !== this.lang.c()) {
    //     // do not restore state if it was in a different language
    //     this.testState = null;
    //   }
    // }
  }
  
  
  saveQuestionResponse = (data:any) => {
    console.log('to do: save change to localStorage');
    const id = data.test_question_id
    return Promise.all([]);
  }

  submitTest = () => {
    // console.log('process responses', this.questionStates)
    this.showDiagnosticResults()
  }
  
  showTestRunner(){
    this.auth
      .apiFind('public/whitelabel/vretta-ens/testform')
      .then(testDesigns => {
        this.testDesign = testDesigns[0];
        this.questionSrcDb = new Map();
        Object.keys(this.testDesign.questionDb).forEach(questionId => {
          try {
            const question = this.testDesign.questionDb[questionId];
            question.test_question_version_id = questionId;
            this.questionSrcDb.set(+questionId, question);
          } catch (err) {
            console.warn('could not process question id', questionId, err);
          }
        });
        this.testDesign.questionSrcDb = null;
        this.currentSubView=ViewSub.TEST_RUNNER; //Need to load the test design before initializing the component
      });
  }

  showDiagnosticResults(){

    const allQuestionIds = [];
    this.testDesign.sections.forEach(section => {
      section.questions.forEach(questionId=> {
        allQuestionIds.push(+questionId);
      })
    });

    // console.log('questionStates', {allQuestionIds, states: this.questionStates, ENS_TOPICS, ENS_ITEM_MAP});
    const categoryRef = new Map();
    const categories:ICategory[] = ENS_TOPICS.tags.map(tag => {
      const category:ICategory = {
        name: tag.name,
        score: 0,
        scoreTally:0,
        scoreMax:0,
      }
      categoryRef.set(tag.code, category);
      return category;
    });
    const questionIdCategoryRef = new Map();
    ENS_ITEM_MAP.forEach(question => {
      const categoryCode = question.config.meta[ENS_TOPICS.code];
      const category = categoryRef.get(categoryCode);
      questionIdCategoryRef.set(+question.id, category);
    });
    // console.log('allQuestionIds', allQuestionIds, this.questionStates)
    allQuestionIds.forEach(questionId => {
      const category = questionIdCategoryRef.get(questionId);
      category.scoreMax ++;
      let questionState = this.questionStates[+questionId];
      if (!questionState){
        questionState = this.questionStates[''+questionId];
      }
      let isCorrect = false;
      if (questionState){
        Object.keys(questionState).forEach(entryId => {
          const entryState = questionState[entryId];
          if (entryState.isCorrect){
            isCorrect = true;
          }
        })
      }
      if (isCorrect){
        category.scoreTally ++;
      }
      // console.log('state', questionId, isCorrect, questionState)
    });
    categories.forEach(category => {
      if (category.scoreMax > 0){
        category.score = category.scoreTally / category.scoreMax;
      }
    });

    this.categoriesTop = categories.filter(cat => cat.score >= 0.8);
    this.categoriesBott = categories.filter(cat => cat.score < 0.8);

    this.currentSubView=ViewSub.DIAGNOSTIC;
  }

  showCongratulations() {
    if (this.categoriesBott.length === 0){
      this.currentSubView=ViewSub.CONGRATULATIONS;
    }
    else{
      this.returnToStart();
    }
  }

  showCertificate(data) {
    this.name = data.name;
    this.email = data.email;
    this.accessCode = data.accessCode;

    this.currentSubView=ViewSub.CERTIFICATE;
  }

  returnToStart() {
    this.currentSubView=ViewSub.LANDING;
    this.questionStates = {};
  }

}
