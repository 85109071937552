<div *ngIf="element">
  <div class="prop-input-grid" style="margin-bottom:1em;" *ngIf="!isHideId">
    <div class="prop-input-cell ">
      <div class="prop-label is-main" (click)="idClick.emit()">ID</div> 
      <input type="text" [formControl]="id">
    </div>
  </div>
  <div class="prop-input-grid" *ngIf="!isHidePos">
    <div class="prop-input-cell coord-cell">
      <div class="prop-label">X</div> 
      <input type="number" [formControl]="x">
    </div>
    <div class="prop-input-cell coord-cell">
      <div class="prop-label">Y</div> 
      <input type="number" [formControl]="y">
    </div>
  </div>
  <div class="prop-input-grid" *ngIf="isSetDim">
    <div class="prop-input-cell coord-cell">
      <div class="prop-label">W</div> 
      <input type="number" [formControl]="width">
    </div>
    <div class="prop-input-cell coord-cell">
      <div class="prop-label">H</div> 
      <input type="number" [formControl]="height">
    </div>
  </div>
</div>
