<div class="field has-addons" style="margin-top:1em;">
  <div class="control is-expanded">
    <div class="select is-fullwidth">
      <select [formControl]="elementTypeForInsertion">
        <option *ngFor="let elementType of elementTypes; let index = index" [value]="index">
          {{elementType.caption}}
        </option>
      </select>
    </div>
  </div>
  <div class="control">
    <button type="submit" [disabled]="isReadOnly()" (click)="insertListEntry()" class="button is-primary">Add</button>
  </div>
</div>