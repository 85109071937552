import { Component, OnInit, Input, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { QuestionState, ScoringTypes, getElementWeight, IEntryStateGroup, IContentElementDndSub, IContentElementDndDraggable } from '../models';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

const SCORING_TYPE = ScoringTypes.AUTO;
@Component({
  selector: 'element-render-matching',
  templateUrl: './element-render-matching.component.html',
  styleUrls: ['./element-render-matching.component.scss']
})
export class ElementRenderMatchingComponent implements OnInit {
  @Input() element;
  @Input() isLocked: boolean;
  @Input() questionState: QuestionState;
  @Input() changeCounter: number;
  draggables: IElementPos[] = [];
  targets: IElementPos[] = [];
  @ViewChild('mainDraggables') mainDraggables;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      this.updateDisplayEls();
    }
  }
  

  updateDisplayEls() {

    this.draggables = [];
    this.targets = [];
    this.element.targets.forEach(element => this.addElementToList(element, this.targets, true));
    this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));
  }

  addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.container.id === this.mainDraggables.nativeElement.id) {
      this.dragAction(event);
    } else if (!event.container.data.length) {
      this.dragAction(event);
    }
  }

  dragAction(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    this.element.draggables = this.draggables.map(drag => drag.ref)
    let entryState: IEntryStateGroup = {
      type: 'matching',
      isCorrect: false,
      isStarted: this.element.targets.find(target => target.groups.length) ? true : false,
      isFilled: this.element.targets.find(target => target.groups.length) ? true : false,
      score: this.element.targets.find(target => target.groups.length) ? getElementWeight(this.element) : 0,
      weight: getElementWeight(this.element),
      scoring_type: SCORING_TYPE,
      draggables: this.element.draggables,
      targets: this.element.targets
    };
    this.questionState[this.element.entryId] = entryState;
  }

}
