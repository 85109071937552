import { elementTypes, TextParagraphStyle, InputFormat, IContentElementMath, IContentElementImage, IContentElementText, IContentElement, IContentElementVideo, IContentElementMcq, IContentElementInput, ElementType, McqDisplay, IContentElementTable, IContentElementDnd, DndTargetType, IContentElementOrder, IContentElementMcqOption, IContentElementSbs } from "../../../ui-testrunner/models";

export interface ICustomTaskSet { 
  __id?: string,
  __backup__id?: string,
  __backup__userDisplayName?: string,
  name?: string,
  order?: number,
  taskId?: string, // cross link to collection `tasks`
  project?: string,
  isReady?: boolean,
  labelCounter?:number,
  isArchived?: boolean,
  questions?:IQuestionConfig[] | string,
  creatorUid?: string,
  timeCreated?: number,
  timeLastSaved?: number,
  schemaVersion?: number,
  lastTouchedBy?: string,
  assessmentFrameworkId?:string,
  isProtected?:boolean,
}

export interface ICustomTaskTag {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdBy: string,
  timestamp: number,
}

export interface ICustomTaskComment {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdByName: string,
  createdByUid: string,
  timestamp: number,
}
export interface IQuestionRun {
  content: IContentElement[],
  // voiceover?: {url:string},
  entryOrder?: number[],
  test_question_version_id?:number,
}
export interface IQuestionConfig extends IQuestionRun {
  label: string,
  isReady?: boolean,
  isInfoSlide?: boolean,
  notes?: string,
  taskId?: string,
  localTaskId: number,
  entryIdCounter?: number,
}

export const generateDefaultElementText = (elementType):IContentElementText => {
  return {
    elementType,
    caption: '',
    simpleList: [],
    advancedList: [],
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementTable = (elementType):IContentElementTable => {
  console.log('generateDefaultElementTable')
  return {
    elementType,
    grid: [
      [{val:''}, {val:''}],
      [{val:''}, {val:''}],
      [{val:''}, {val:''}],
    ],
    isHeaderRow: false,
    isHeaderCol: false,
    isColWidthConst: false,
    isTableOfValues: true,
    colWidthConst: 4
  }
}
export const generateDefaultElementMath = (elementType):IContentElementMath => {
  return {
    elementType,
    latex: '',
    _changeCounter: 0,
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementImage = (elementType):IContentElementImage => {
  return {
    elementType,
    url: null,
    scale: 100,
  }
}
export const generateDefaultElementVideo = (elementType):IContentElementVideo => {
  return {
    elementType,
    url: null,
  }
}


export const generateDefaultElementMcq = (elementType):IContentElementMcq => {
  return {
    elementType,
    displayStyle: McqDisplay.VERTICAL,
    customInstructions: '',
    options: [
      {elementType:ElementType.TEXT, content: 'Option 1', isCorrect:false, optionId: 1},
      {elementType:ElementType.TEXT, content: 'Option 2', isCorrect:true,  optionId: 2},
      {elementType:ElementType.TEXT, content: 'Option 3', isCorrect:false, optionId: 3},
      {elementType:ElementType.TEXT, content: 'Option 4', isCorrect:false, optionId: 4},
    ]
  }
}

export const generateDefaultElementOrder = (elementType):IContentElementOrder => {
  return {
    elementType,
    displayStyle: McqDisplay.HORIZONTAL,
    isScrambled: false,
    delimeter: '',
    options: [
    ]
  }
}

export const generateDefaultElementDnd = (elementType):IContentElementDnd => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    backgroundElements: [], 
    draggables: [],
    targets: [],
    groups:[],
  } 
}

export const generateDefaultElementGrouping = (elementType) => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    draggables: [],
    targets: [],
  }
}

export const generateDefaultElementInput = (elementType):IContentElementInput => {
  return {
    elementType,
    format: null,
    alternativeValues: [],
    ratioTerms: [],
  }
}

export const getElementChildrenText = (element:IContentElementText) => {
  if (element.advancedList){
    return element.advancedList.map(el => el);
  }
  return [];
}
export const getElementChildrenTable = (element:IContentElementTable) => {
  const elements:IContentElement[] = [];
  if (element.grid){
    element.grid.forEach(row => {
      row.forEach(cell => {
        if (cell.elementType){
          elements.push(<IContentElement> cell);
        }
      })
    })
  }
  return elements;
}
export const getElementChildrenSbs = (element:IContentElementSbs) => {
  return element.left.concat(element.right);
}
export const getElementChildrenMcq = (element:IContentElementMcq) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}
export const getElementChildrenOrder = (element:IContentElementOrder) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}

export const elementIconById:Map<string, string> = new Map();
elementTypes.forEach(elementType => {
  elementIconById.set(elementType.id, elementType.icon)
});
  
export const createDefaultElement = (elementType:string):IContentElement => {
  switch(elementType){
    case ElementType.TEXT:    return generateDefaultElementText(elementType);
    case ElementType.TABLE:   return generateDefaultElementTable(elementType);
    case ElementType.MATH:    return generateDefaultElementMath(elementType);
    case ElementType.IMAGE:   return generateDefaultElementImage(elementType);
    case ElementType.MCQ:     return generateDefaultElementMcq(elementType);
    case ElementType.ORDER:   return generateDefaultElementOrder(elementType);
    case ElementType.DND:     return generateDefaultElementDnd(elementType);
    case ElementType.VIDEO:   return generateDefaultElementVideo(elementType);
    case ElementType.INPUT:   return generateDefaultElementInput(elementType);
    case ElementType.GROUPING: return generateDefaultElementGrouping(elementType);
    case ElementType.MATCHING: return generateDefaultElementGrouping(elementType);
    default: return { elementType }
  }
}

export const getElementChildren = (element:IContentElement) : IContentElement[] => {
  switch(element.elementType){
    case ElementType.TEXT:    return getElementChildrenText  (<IContentElementText>  element);
    case ElementType.TABLE:   return getElementChildrenTable (<IContentElementTable> element);
    case ElementType.MCQ:     return getElementChildrenMcq   (<IContentElementMcq>   element);
    case ElementType.ORDER:   return getElementChildrenOrder (<IContentElementOrder> element);
    case ElementType.SBS:     return getElementChildrenSbs   (<IContentElementSbs>   element);
    default: return []
  }
}

export const checkElementIsEntry = (element:IContentElement) : boolean => {
  switch(element.elementType){
    case ElementType.CAMERA:
    case ElementType.DND:
    case ElementType.INPUT:
    case ElementType.MCQ:
    case ElementType.HOTSPOT:
    case ElementType.HOTTEXT:
    case ElementType.GRAPHING:
    case ElementType.MATCHING:
    case ElementType.MIC:
    case ElementType.ORDER:
    case ElementType.UPLOAD:
      return true;
    default: 
      return false;
  }
}



export const applyDefaultElementProps = (target:any, elementType:string) => {
  const ref = createDefaultElement(elementType);
  Object.keys(ref).forEach(key => {
    target[key] = ref[key];
  })
}
