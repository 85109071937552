import {ILeaderSelectionItem} from './data';

enum SelectionType {
    UNSELECTED = 'UNSELECTED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    ZERO = 'ZERO',
}
enum MarkingCode {
    NONE = '',
    NR = 'NR',
    FOUR = '4',
    THREE = '3',
    TWO = '2',
    ONE = '1',
    ZERO = '0',
}

enum ItemType {
    SYSTEM = 'SYSTEM', // comes from the system itself, authoring => test taking.
    SCAN = 'SCAN',
}


export const s3LeaderItems: ILeaderSelectionItem[] = [
    {
        "item": {
            "id": 1,
            "name": "Dairy Farm",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 30
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            }
        ]
    },
    {
        "item": {
            "id": 2,
            "name": "Maple Syrup",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 37
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            }
        ]
    },
    {
        "item": {
            "id": 3,
            "name": "Olympics",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 150
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            }
        ]
    },
    {
        "item": {
            "id": 4,
            "name": "Wastewater",
            "type": ItemType.SYSTEM,
            "response_count": 1000,
            "responses_expected": 2000,
            "marked_count": 16
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"14\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"98\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"34\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"91\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            }
        ]
    },
    {
        "item": {
            "id": 5,
            "name": "Salmon Run",
            "type": ItemType.SYSTEM,
            "response_count": 1200,
            "responses_expected": 2000,
            "marked_count": 0
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"45\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"22\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"4\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"77\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"49\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"42\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"86\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"81\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"53\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"0\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"89\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"17\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"27\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"100\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"2\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            }
        ]
    },
    {
        "item": {
            "id": 6,
            "name": "Totem Poles",
            "type": ItemType.SYSTEM,
            "response_count": 2000,
            "responses_expected": 2000,
            "marked_count": 2000
        },
        "isSelectedDone": false,
        "isScoredDone": false,
        "isReliabilitySet": false,
        "isOnSiteSet": false,
        "isOffSiteSet": false,
        "responses": [
            {
                "id": 1,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 2,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 3,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 4,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 5,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"38\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 6,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 7,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 8,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 9,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"99\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 10,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 11,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"57\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 12,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 13,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 14,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 15,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"30\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 16,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 17,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"90\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 18,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 19,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"50\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 20,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 21,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"87\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"35\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 22,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 23,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 24,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 25,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"95\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 26,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"6\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 27,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 28,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 29,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"97\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 30,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"70\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 31,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 32,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 33,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"29\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"32\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 34,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 35,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.TWO
            },
            {
                "id": 36,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 37,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"64\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 38,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 39,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 40,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 41,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 42,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 43,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 44,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 45,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.THREE
            },
            {
                "id": 46,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 47,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"78\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 48,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 49,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"55\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"73\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 50,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"21\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 51,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 52,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"58\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ZERO
            },
            {
                "id": 53,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 54,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"28\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"13\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 55,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"88\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 56,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"1\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 57,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"71\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 58,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 59,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 60,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"37\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 61,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"16\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"67\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 62,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"61\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"10\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.TWO
            },
            {
                "id": 63,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"82\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 64,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 65,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"60\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"48\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 66,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"74\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.THREE
            },
            {
                "id": 67,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.NR
            },
            {
                "id": 68,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"75\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 69,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"24\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 70,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"36\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 71,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.TWO
            },
            {
                "id": 72,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"59\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"11\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 73,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"18\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 74,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"40\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 75,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"8\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 76,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"31\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"41\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 77,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"51\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"43\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 78,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"65\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"5\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 79,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"44\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 80,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"62\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            },
            {
                "id": 81,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"15\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ZERO
            },
            {
                "id": 82,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"84\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"20\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 83,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"96\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 84,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 85,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"56\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"92\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.NR
            },
            {
                "id": 86,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"52\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"80\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 87,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"3\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.NR
            },
            {
                "id": 88,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"25\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.THREE
            },
            {
                "id": 89,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"63\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 90,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"47\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"69\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.ONE
            },
            {
                "id": 91,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"26\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"46\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.HIGH,
                "score": MarkingCode.NR
            },
            {
                "id": 92,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"7\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"72\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 93,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"12\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"54\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.ONE
            },
            {
                "id": 94,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"83\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"93\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.LOW,
                "score": MarkingCode.TWO
            },
            {
                "id": 95,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"9\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"79\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ONE
            },
            {
                "id": 96,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"23\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"66\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 97,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"39\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ZERO
            },
            {
                "id": 98,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"68\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"19\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.UNSELECTED,
                "score": MarkingCode.ONE
            },
            {
                "id": 99,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"33\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"76\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.ZERO
            },
            {
                "id": 100,
                "raw": "{\"1\":{\"type\": \"input-fraction\",\"isCorrect\": true,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"94\",\"denominator\": \"100\",\"score\": \"2\",\"weight\": \"2\",\"scoring_type\": \"REVIEW\"},\"2\": {\"type\": \"input-fraction\",\"isCorrect\": false,\"isStarted\": false,\"isFilled\": true,\"numerator\": \"85\",\"denominator\": \"100\",\"score\": 0,\"weight\": \"1\",\"scoring_type\": \"REVIEW\"}}",
                "isOffSite": false,
                "isOnSite": false,
                "isReliability": false,
                "selection": SelectionType.ZERO,
                "score": MarkingCode.THREE
            }
        ]
    }
];
