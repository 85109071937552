import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {LangService} from "../../core/lang.service";

@Component({
  selector: 'check-toggle',
  templateUrl: './check-toggle.component.html',
  styleUrls: ['./check-toggle.component.scss']
})
export class CheckToggleComponent implements OnInit {

  @Input() isChecked:boolean;
  @Input() disabled:boolean;
  @Input() isSquare:boolean;
  @Output() toggle = new EventEmitter();
  @Output() toggleOn = new EventEmitter();
  @Output() toggleOff = new EventEmitter();

  constructor(
      public lang: LangService
  ) { }

  ngOnInit() {
  }

  emitToggle(){
    
    if (this.disabled) { return; }

    const isChecked = this.isChecked;
    this.toggle.emit(this.isChecked);
    if (isChecked){
      this.toggleOn.emit();
    }
    else{
      this.toggleOff.emit();
    }
  }

}
