<div [ngSwitch]="contentElement.elementType">
  <div *ngSwitchCase="ElementType.AUDIO">         <element-render-audio      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-audio></div>
  <div *ngSwitchCase="ElementType.CAMERA">        <element-render-camera     [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-camera></div>
  <div *ngSwitchCase="ElementType.DND">           <element-render-dnd        [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter" ></element-render-dnd></div>
  <div *ngSwitchCase="ElementType.GRAPHICS">      <element-render-graphics   [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-graphics></div>
  <div *ngSwitchCase="ElementType.GRAPHING">      <element-render-graphing   [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-graphing></div>
  <div *ngSwitchCase="ElementType.HOTSPOT">       <element-render-hotspot    [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-hotspot></div>
  <div *ngSwitchCase="ElementType.HOTTEXT">       <element-render-hottext    [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-hottext></div>
  <div *ngSwitchCase="ElementType.IFRAME">        <element-render-iframe     [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-iframe></div>
  <div *ngSwitchCase="ElementType.IMAGE">         <element-render-image      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-image></div>
  <div *ngSwitchCase="ElementType.INPUT">         <element-render-input      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-input></div>
  <div *ngSwitchCase="ElementType.MATCHING">      <element-render-matching   [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-matching></div>
  <div *ngSwitchCase="ElementType.MATH">          <element-render-math       [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-math></div>
  <div *ngSwitchCase="ElementType.MCQ">           <element-render-mcq        [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-mcq></div>
  <div *ngSwitchCase="ElementType.MIC">           <element-render-mic        [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-mic></div>
  <div *ngSwitchCase="ElementType.ORDER">         <element-render-order      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter" ></element-render-order></div>
  <div *ngSwitchCase="ElementType.READER">        <element-render-reader     [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-reader> </div>
  <div *ngSwitchCase="ElementType.SBS">           <element-render-sbs        [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-sbs> </div>
  <div *ngSwitchCase="ElementType.TABLE">         <element-render-table      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-table> </div>
  <div *ngSwitchCase="ElementType.TEXT">          <element-render-text       [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-text> </div>
  <div *ngSwitchCase="ElementType.UPLOAD">        <element-render-upload     [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-upload></div>
  <div *ngSwitchCase="ElementType.VIDEO">         <element-render-video      [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" ></element-render-video></div>
  <div *ngSwitchCase="ElementType.GROUPING">      <element-render-grouping   [element]="contentElement" [isLocked]="isLocked"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-grouping></div>
</div>