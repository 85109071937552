<div *ngIf="isCertifiedReleaseInvalidated" style="padding:6em;">
    Failed Authentication (refresh to enter your password)
</div>
<div *ngIf="isCertifiedRelease" style="height:100%;">
    <div class="page-content content">
        <router-outlet></router-outlet>
    </div>
    <div class="confirmation-modal"></div>
    <div class="log-back-in modal-screen-cover" [class.is-hidden]="!( getLoginReq() | async)">
        <div class="modal-screen-cover-content is-boxed">
            <div class="modal-scrollpane">
                <login-form></login-form>
            </div>
            <div class="delete-container">
                <button (click)="gotoHomeScreen()" class="delete is-large"></button>
            </div>
        </div>
    </div>
    <div class="connection-display">
        <span class="tag is-danger" [class.is-hidden]="!(getDevNetFail() | async)">
      <tra slug="error_device_net"></tra>
    </span>
        <span class="tag is-warning" [class.is-hidden]="!(getApiNetFail() | async)">
      <tra slug="error_server_net"></tra>
    </span>

    <span class="tag is-warning" *ngIf="(getAutoLogoutWarning() | async)">
        <tra slug="alert_logging_out"></tra>
        <a (click)="refreshLogin()"> <tra slug="link_logging_out"></tra> </a>
      </span>
    </div>
    <div class="device-net-fail-overlay modal-screen-cover" *ngIf="isShowingDevNetFailOverlay">
        <div class="modal-screen-cover-content  is-boxed">
            <div class="modal-scrollpane">
                <div>
                    <tra slug="txt_offline_error"></tra>
                </div>
                <div>
                    <button (click)="isShowingDevNetFailOverlay = false" class="button is-danger">
            <tra slug="btn_dismiss_notif"></tra>
          </button>
                </div>
            </div>
        </div>
    </div>
    <div class="api-net-fail-overlay modal-screen-cover" *ngIf="isShowingApiNetFailOverlay">
        <div class="modal-screen-cover-content  is-boxed">
            <div class="modal-scrollpane">
                <div>
                    <tra slug="error_server_net_long"></tra>
                </div>
                <hr/>
                <strong><tra slug="lbl_serv_health"></tra></strong>
                <table class="table is-outlined is-bordered" style="margin-top: 1em;">
                    <tr>
                        <th><tra slug="lbl_cur_stat"></tra></th>
                        <th><tra slug="lbl_cur_det"></tra></th>
                    </tr>
                    <tr>
                        <td>✅ <tra slug="lbl_reg"></tra></td>
                        <td><tra slug="lbl_op_stat"></tra></td>
                    </tr>
                    <tr>
                        <td>✅ <tra slug="lbl_log"></tra></td>
                        <td><tra slug="lbl_op_stat"></tra></td>
                    </tr>
                    <tr>
                        <td>✅ Invigilation</td>
                        <td><tra slug="lbl_op_stat"></tra></td>
                    </tr>
                </table>
                <hr/>
                <div>
                    <tra-md slug="txt_dev_conn_error_2"></tra-md>
                </div>
                <hr/>
                <div>
                    <button (click)="isShowingApiNetFailOverlay = false" class="button is-warning"><tra slug="btn_dismiss_notif"></tra></button>
                </div>
            </div>
        </div>
    </div>
    <div class="support-overlay modal-screen-cover" [class.is-hidden]="!(getSupportReq() | async) ">
        <div class="modal-screen-cover-content  is-boxed is-wide">
            <div class="modal-scrollpane">
                <info-center-details [isAuthActive]="isAuthActive()" (dismiss)="dismissSupportPopup()"></info-center-details>
            </div>
        </div>
    </div>
    <div class="confirmation-overlay modal-screen-cover" [class.is-hidden]="!currentConfirmation">
        <div class="modal-screen-cover-content  is-boxed">
            <div class="modal-scrollpane">
                <div *ngIf="currentConfirmation && currentConfirmation.caption">
                    <tra-md [slug]="currentConfirmation.caption" class="center"></tra-md>
                </div>
                <div class="modal-buttons">
                    <button class="button" (click)="closeModal()" *ngIf="!showCancel()">{{confirmationCancelCaption}}</button>
                    <button class="button is-info" (click)="confirmModal()">{{confirmationProceedCaption}}</button>
                </div>
            </div>
        </div>
    </div>
</div>