<div class="menu-panel" *ngIf="instInfo">
  
  <div class="panel-header">
    <h2><tra whitelabel="my_inst"></tra></h2>
    <div class="instutition-name">
      <div class="center-icon">
        <img src="assets/icons/test_center.svg" [attr.alt]="lang.tra('sr_test_center_image')">
      </div>
      <div>
        <div class="label">{{instInfo.name}}</div>
        <div *ngIf="isMeInstMngr()" class="special-role-indic"> 
          <tra-md whitelabel="my_inst_admin"></tra-md>
        </div>
      </div>
      
    </div>
  </div>

  <div class="section-institution">
    <div class="instutition-address" [class.is-editing]="currentView == InstMngView.INST_INFO">
      <div *ngIf="isMeInstMngr()" style="flex:1;">
        <a [routerLink]="getRouterMngInstInfo()" class="edit-address" [class.is-link-disabled]="currentView == InstMngView.INST_INFO">
          <i class="fa fa-pencil-alt" aria-hidden="true"></i> <tra slug="btn_edit"></tra>
        </a>
      </div>
      <div class="address-icon"><img src="assets/icons/location.svg" [attr.alt]="lang.tra('sr_location_pin_image')"></div>
      <div>
        <div>{{instInfo.address}}</div>
        <div>{{instInfo.city}} {{instInfo.province}} {{instInfo.postalCode}}</div>
        <div *ngIf="instInfo.phoneNumber"> 
          <tra slug="ta_my_faculty_tele_lbl"></tra>
          {{instInfo.phoneNumber}}
        </div>
        <div *ngIf="instInfo.faxNumber">
          <tra slug="ta_my_faculty_fax_lbl"></tra>
          {{instInfo.faxNumber}}
        </div>
      </div>
    </div>

    <div class="instutition-polices" *ngIf="isShowBookingStats()">
      <div class="label"><tra slug="ta_policy_resp_time_lbl"></tra></div>
      <div class="policy">
        <div>
          <div><b><tra slug="ta_policy_reg_buffer"></tra></b></div>
          <div><tra slug="ta_policy_reg_buffer_desc"></tra></div>
        </div>
        <div class="label" *ngIf="instInfo.reg_buffer_policy">{{ instInfo.reg_buffer_policy }} <span><tra slug="txt_days"></tra></span> <span *ngIf="false" class="unit"><tra [slug]="instInfo.reg_buffer_policy_unit"></tra></span></div>
        <div class="label" *ngIf="!instInfo.reg_buffer_policy"><tra slug="ta_policy_not_set"></tra></div>
      </div>
      <div class="policy">
        <div>
          <div><b><tra slug="ta_policy_resp_time_lbl"></tra></b></div>
          <div><tra slug="ta_policy_resp_time_lbl_desc"></tra></div>
        </div>
        <div class="label" *ngIf="instInfo.resp_time_policy">{{ instInfo.resp_time_policy }} <span><tra [slug]="instInfo.resp_time_policy_unit"></tra></span></div>
        <div  class="label" *ngIf="!instInfo.resp_time_policy"><tra slug="ta_policy_not_set"></tra></div>
      </div>
      <div class="policy">
        <div>
          <div><b><tra slug="ta_policy_applicant_resp_time_lbl"></tra></b></div>
        </div>
        <div class="label" *ngIf="instInfo.applicant_rt_policy">{{ instInfo.applicant_rt_policy }} <span><tra [slug]="instInfo.applicant_rt_policy_unit"></tra></span></div>
        <div class="label" *ngIf="!instInfo.applicant_rt_policy"><tra slug="ta_policy_not_set"></tra></div>
      </div>
      <div *ngIf="isMeInstMngr()">
        <a routerLink="/{{lang.c()}}/test-admin/seb-validation" [class.is-disabled]="currentView == InstMngView.SEB_VALIDATION" >
          <tra slug="lbl_seb_config"></tra>
        </a>
      </div>
    </div>
  </div>
  <div class="section-accounts-list">
    <div class="section-accounts" *ngIf="isMeInstMngr()">
      <div class="feature-warning">
        <tra slug="manage_account_warning_1" style="color: red;" *ngIf="!instInfo.is_accomm_coord_assigned && !instInfo.is_invigilator_assigned"></tra>
        <tra slug="manage_account_warning_2" style="color: #dc0000;" *ngIf="instInfo.is_accomm_coord_assigned && !instInfo.is_invigilator_assigned"></tra>
        <tra slug="manage_account_warning_3" style="color: red;" *ngIf="!instInfo.is_accomm_coord_assigned && instInfo.is_invigilator_assigned"></tra>
      </div>
      <div>
        <a [routerLink]="getRouterMngInstAccounts()" [class.is-disabled]="currentView == InstMngView.ACCOUNTS" class="button is-info">
          <tra slug="ta_my_inst_btn_accounts" whitelabel="my_inst_manage_acct"></tra>
        </a>
      </div>
    </div>
    <div class="section-accounts" *ngIf="isMeInstMngr() && appealsEnabled">
      <div>
        <a [routerLink]="getRouterPendingAppeals()" [class.is-disabled]="currentView == InstMngView.PENDING_APPEALS" class="button is-info">
          <tra slug="btn_appeals"></tra>
        </a>
      </div>
    </div>
    <div class="section-accounts" *ngIf="isMngStudents()">
      <div>
        <a [routerLink]="getRouterMngStudentAccounts()" [class.is-disabled]="currentView == InstMngView.STUDENTS" class="button is-info">
          <tra slug="Manage Students"></tra>
        </a>
      </div>
    </div>
    <div class="section-accounts" *ngIf="isMngStudents()">
      <div>
        <a [routerLink]="getRouterReporting()" [class.is-disabled]="currentView == InstMngView.REPORTING" class="button is-info">
          <tra slug="Reporting "></tra>
        </a>
      </div>
    </div>
    <div class="section-sessions"  *ngIf="isMeAccommCoord()">
      <a [routerLink]="getRouterPendingAccomm()" [class.is-disabled]="currentView == InstMngView.PENDING_ACCOMM"  class="button is-info">
        <tra slug="btn_ta_accomm_req"></tra>
      </a>
    </div>
    <div class="section-sessions" *ngIf="false">
      <!-- <div class="problem-indicator">
        <tra slug="ta_my_faculty_no_session_warn"></tra>
      </div> -->
      <div>
        <a [routerLink]="getRouterAllTestSessions()" [class.is-disabled]="currentView == InstMngView.ALL_SESSIONS" class="button is-info">
          <tra slug="ta_my_inst_btn_view_all"></tra>
        </a>
      </div>
    </div>
  </div>


</div>