import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { IContentElementMcq, IContentElementMcqOption, QuestionState, IEntryStateMcq, McqDisplay, IContentElementText, IScoredResponse, getElementWeight, ScoringTypes } from '../models';
import { indexOf } from '../services/util';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';
import { AuthScopeSetting } from '../../ui-item-maker/auth-scope-settings.service';
import { getRandomInt } from 'src/app/marking/data/data';

const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-mcq',
  templateUrl: './element-render-mcq.component.html',
  styleUrls: ['./element-render-mcq.component.scss']
})
export class ElementRenderMcqComponent implements OnInit, OnChanges, OnDestroy {

  @Input() element:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  lastTrackedQuestionState;
  isStarted:boolean;
  alreadyScrambled:boolean;
  isShowSolutionApplied:boolean;
  buttonLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  dropdownSelector = new FormControl();
  clickTriggers:Map<IContentElementMcqOption, Subject<boolean>> = new Map();

  constructor(
    private textToSpeech:TextToSpeechService,
  ) { }

  currentSelections:IContentElementMcqOption[] = [];

  isDisplayStyleDropdown(){   return this.element.displayStyle === McqDisplay.DROPDOWN; }

  ngOnInit() {
    if(this.questionState && this.questionState[this.element.entryId]) {
      this.alreadyScrambled = this.questionState[this.element.entryId].alreadyScrambled;
    }
    this.ensureState();
    this.dropdownSelector.valueChanges.subscribe(change => this.updateDropdownState() )
    
    if(this.questionState && !this.questionState[this.element.entryId].alreadyScrambled && this.element.isScrambled) {
      this.scrambleOrder();
    }
  }

  ngOnDestroy(): void {
    this.updateState(); //Make sure we're saving the alreadyScrambled value on changing of questions
  }
  
  ngOnChanges(){
    if (this.lastTrackedQuestionState !== this.questionState){
      this.lastTrackedQuestionState = this.questionState;
      if (this.questionState){
        this.handleNewState();
      }
    }
    if (this.isShowSolution && !this.isShowSolutionApplied){
      this.showSolution()
      this.isShowSolutionApplied = true;
    }
  }

  scrambleOrder() {
    //algorithm: Fisher-Yates shuffle
    let options = this.element.options;
    for(let i  = options.length - 1; i > 0; i--) {
      let j = getRandomInt(0, i);
      [options[i], options[j]] = [options[j], options[i]]; //swap them
    }
    this.alreadyScrambled = true;
  }

  isVoiceoverEnabled(){
    return this.textToSpeech.isActive;
  }

  getClickTrigger(option: IContentElementMcqOption){
    let trigger = this.clickTriggers.get(option);
    if (!trigger){
      trigger = new Subject();
      this.clickTriggers.set(option, trigger);
    }
    return trigger;
  }

  showSolution(){
    this.deselectAllOptions();
    this.element.options.forEach(option => {
      if (option.isCorrect){
        this._selectOption(option);
      }
    })
  }

  getOptionsContainerStyle(){
    let style:any = {};
    if (this.element.displayStyle==='grid'){
      if (this.element.gridNumColumns){
        style['width.em'] = (this.element.gridCellWidth+1) * this.element.gridNumColumns;
        style['justify-content'] = 'center';
      }
    }
    return style;
  }

  getButtonContainerStyle(optionElement){
    if (this.element.displayStyle==='grid'){
      return {
        'width.em': this.element.gridCellWidth || 10
      }
    }
    return {}
  }

  isSelected(option:IContentElementMcqOption){
    if (indexOf(this.currentSelections, option) !== -1){
      return true;
    }
    return false;
  }

  private _selectOption(option:IContentElementMcqOption){
    if (!this.element.isMultiSelect){
      if (this.isSelected(option)){
        if (this.element.isSelectToggle){
          this.currentSelections = [];
        }
      }
      else {
        this.currentSelections = [option];
      }
    }
    else{
      let i = indexOf(this.currentSelections, option);
      let isSelected = (i !== -1);
      if (isSelected){ 
        this.currentSelections.splice(i, 1);
      }
      else{
        this.currentSelections.push(option);
      }
    }
  }

  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  selectOptionManual(option:IContentElementMcqOption){
    if (this.isLocked){ return; }
    this.isStarted = true;
    this.selectOption(option);  
  }

  selectOption(option:IContentElementMcqOption){
    this._selectOption(option);
    this.updateState();
    this.getClickTrigger(option).next(true);
  }

  deselectAllOptions(){
    this.currentSelections.splice(0, this.currentSelections.length);
  }

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
      if (!this.questionState[entryId]){
        let entryState:IEntryStateMcq = {
          type: 'mcq',
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          selections: [],
          alreadyScrambled: this.alreadyScrambled,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  handleNewState(){
    if (this.questionState){
      const entryState:IEntryStateMcq = this.questionState[this.element.entryId];
      if (entryState){
        this.isStarted = entryState.isStarted;
        if (this.element.displayStyle === McqDisplay.DROPDOWN){
          this.dropdownSelector.setValue(entryState.selections[0])
        }
        else{
          entryState.selections.forEach(selectionState => {
            let i = selectionState.i;
            this.selectOption(this.element.options[i]);
          })
        }
      }
      else{
        this.ensureState();
      }
    }
  }

  updateDropdownState(){
    let isCorrect = false;
    let isFilled = false;

    let i = this.dropdownSelector.value;
    if (i>=0){
      let option = this.element.options[i]
      if (option){
        isFilled = true
        isCorrect = option.isCorrect;
      }
    }

    const weight = getElementWeight(this.element);
    const score =  isCorrect ? weight : 0 ;

    let entryState:IEntryStateMcq = {
      type: 'mcq',
      isCorrect,
      isStarted: this.isStarted,
      isFilled,
      selections: [i],
      alreadyScrambled: this.alreadyScrambled,
      score,
      weight,
      scoring_type: SCORING_TYPE, 
    }
    if (this.questionState){
      this.questionState[this.element.entryId] = entryState;
    }
  }

  updateState(){

    // console.log('updateState mcq -----')
    
    const weight = getElementWeight(this.element);
    let score =  weight ;

    let isCorrect = true;

    let selectionsMap = new Map();
    let isOneCorrectOptionSelected = false;

    let selections = this.currentSelections.map( selection => {
      let i = indexOf(this.element.options, selection);
      let temporaryId = i;
      selectionsMap.set(temporaryId, true);
      if (selection.isCorrect){
        isOneCorrectOptionSelected = true
      }
      let content;
      if (selection.elementType === 'image'){
        content = selection.url;
      }
      else{
        content = selection.content;
      }
      return {
        i,
        id: selection.optionId,
        elementType: selection.elementType,
        content
      }
    });

    if (this.element.isMultiSelect){

      this.element.options.forEach((option, i) => {
        const temporaryId = i;
        const expected = !!option.isCorrect;
        const input = !!selectionsMap.has(temporaryId);
        // console.log('  multi   ', expected !== input, expected && !input, expected, input)
        if (expected !== input){
          isCorrect = false;
          score --;
        }
      })
    }
    else if (!this.element.isMultiSelect){
      if (isOneCorrectOptionSelected){
        isCorrect = true;
      }
      else{
        isCorrect = false
        score = 0;
      }
    }

    let entryState:IEntryStateMcq = {
      type: 'mcq',
      isCorrect,
      isStarted: this.isStarted,
      isFilled: selections.length > 0,
      selections,
      alreadyScrambled: this.alreadyScrambled,
      score,
      weight,
      scoring_type: SCORING_TYPE, 
      isGlobalFill: this.element.isGlobalFill
    }
    if (this.questionState){
      this.questionState[this.element.entryId] = entryState;
    }

    // console.log('-----')
    
  }


}
