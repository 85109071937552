<table style="width:auto;" *ngIf="element && element.grid">
  <tr *ngFor="let row of element.grid; let i = index;"  [class.header-row]="checkHeaderByIndex(i)">
    <td 
      *ngFor="let cell of row; let col_i = index;" 
      [class.header-col]="checkColHeaderByIndex(col_i)" 
      [ngStyle]="{
        'text-align':parseCellAlignment(cell.align),
        'width.em':getColWidth(col_i)
      }"
      >
      <div [ngSwitch]="cell.elementType">
        <div *ngSwitchCase="'text'"   class="inline-block">            <element-render-text      [element]="cell" [questionState]="questionState"  ></element-render-text></div>
        <div *ngSwitchCase="'math'"   class="inline-block is-math">    <element-render-math      [element]="cell" [questionState]="questionState" [isInline]="true"  ></element-render-math></div>
        <div *ngSwitchCase="'image'"  class="inline-block">            <element-render-image     [element]="cell" [questionState]="questionState" ></element-render-image></div>
        <div *ngSwitchDefault class="element-text">
          <markdown-inline [input]="cell.val"></markdown-inline>
        </div>
      </div>
    </td>
  </tr>
</table>