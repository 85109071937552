import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {AccountType} from '../constants/account-types';
import {AuthService, IUserInfo} from './auth.service';
import {LangService} from '../core/lang.service';
import {Router} from '@angular/router';

// const ACTIVITY_TRACKER_BUFFER = 10*60*1000; // 10 minutes
const MINUTES_MS = 60*1000;
const AUTO_LOGOUT_WARNING_INTERVAL = 20*MINUTES_MS
const AUTO_LOGOUT_INTERVAL = 120*MINUTES_MS
const ACTIVITY_REFRESH_INTERVAL = 20*MINUTES_MS
export interface ISupportCenterData {
  text: string,
  email: string,
  phone: string,
}

export interface IConfirmationReq {
  caption: string;
  confirm?: () => any;
  btnProceedCaption?: string;
  btnCancelCaption?: string;
  hideCancel?: boolean;
}

const NET_STABILITY_INTERVAL = 5 * 1000;
const NET_STABILITY_STEPS = Math.floor(20 / 5);

export interface IConfirmationReq {
  caption: string;
  confirm?: () => any;
  btnProceedCaption?: string;
  btnCancelCaption?: string;
  hideCancel?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {
  private loginReq: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private devNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private ioActivity: Subject<boolean> = new Subject();
  private supportReq: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private confirmationReq: BehaviorSubject<IConfirmationReq> = new BehaviorSubject(null);
  private isActivated: boolean;
  private isAuthenticated: boolean;
  private isReauthCompleted: boolean;
  private isDevNetOnline: boolean;
  private isDevNetOnlinePersistent: boolean; // needs to be persistently bad for NET_STABILITY_STEPS before this is activated
  private isValidAccountType: boolean;
  private isMonitoringDevNet: boolean;
  private currentAccountType: AccountType;
  private devNetDiscrepCounter = 0;
  private autoLogoutWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private autoLogout: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentTokenExp: number;
  private hasRecentActivity: boolean;
  private autoLogoutWarningTimeoutId;
  private autoLogoutTimeoutId;
  private recentActivityIntervalId;

  constructor(
    private auth: AuthService,
    private lang: LangService,
    private router: Router,

  ) {
    //console.log("intitializing login guard service")
    this.loginReq.next(false);
    this.apiNetFail.next(false);

    this.updateOnlineStatus(); // devNetFail can be checked right away
    // console.log('LoginGuardService construct');
    this.initRecentActivityChecks();
    const trackNewActivity = () => {
      this.hasRecentActivity = true;
      this.recentActivityChecks(true);
      this.ioActivity.next(true);
      this.autoLogoutWarning.next(false);
    };
    document.addEventListener('keydown',trackNewActivity);
    document.addEventListener('mousedown',trackNewActivity);
    document.addEventListener('touchstart',trackNewActivity);

    setInterval(this.monitorDevNet, NET_STABILITY_INTERVAL);
    this.auth.registerNoApiNetSub(this.apiNetFail);
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.auth.user().subscribe(this.updateUserInfo);
    this.auth.getReauthCompletedSub().subscribe(this.updateReauthCompleted);
  }

  private updateOnlineStatus = (e?: any) => {
    this.isDevNetOnline = navigator.onLine;
    if (this.isMonitoringDevNet) {
      this.devNetDiscrepCounter = 0;
    } else {
      if (this.isDevNetOnline !== this.isDevNetOnlinePersistent) {
        this.devNetDiscrepCounter = 0;
        this.isMonitoringDevNet = true;
      }
    }
  }

  private monitorDevNet = () => {
    if (this.isMonitoringDevNet) {
      this.devNetDiscrepCounter ++;
      // console.log('this.devNetDiscrepCounter', this.devNetDiscrepCounter)
      if (this.devNetDiscrepCounter >= NET_STABILITY_STEPS) {
        this.concludeDevNet();
      }
    }
  }
  private concludeDevNet() {
    this.isMonitoringDevNet = false;
    const v = this.isDevNetOnline;
    if (this.isDevNetOnlinePersistent !== v) { // this check should not be required, but just making sure so that we have the right rate limiting on the BSubject
      this.isDevNetOnlinePersistent = v;
      this.devNetFail.next(!v);
    }

  }

  private updateReauthCompleted = (v: boolean) => {
    this.isReauthCompleted = v;
    this.updateLoginReqState();
  }

  private updateUserInfo = (userInfo) => {
    if (userInfo) {
      this.isAuthenticated = true;
      this.currentAccountType = userInfo.accountType;
      this.initRecentActivityChecks();
    } else {
      this.isAuthenticated = false;
      this.currentAccountType = null;
      this.clearRecentActivityChecks(true);
    }
    this.updateLoginReqState();
  }
  isAuthActive() {
    return this.isAuthenticated;
  }

  getLoginReq() {  return this.loginReq; }
  getDevNetFail() {return this.devNetFail; }
  getApiNetFail() {return this.apiNetFail; }
  getSupportReq() {return this.supportReq; }
  getConfirmationReq() {return this.confirmationReq; }

  async refreshLogin () {
    return this.auth.refreshRefreshToken();
  }

  updateLoginReqState() {
    //console.log("updating login req state")
    const isLoginReq = this.isReauthCompleted && this.isActivated && !this.isAuthenticated;
    // console.log('updateLoginReqState', isLoginReq, this.isReauthCompleted, this.isActivated, this.isAuthenticated)
    if (this.loginReq.getValue() !== isLoginReq) {
      this.loginReq.next(isLoginReq);
    }
  }

  public gotoUserDashboard(userInfo: IUserInfo) {
    const langCode = this.lang.getCurrentLanguage();
    if (userInfo) {
      const accountType = userInfo.accountType;
      switch(accountType){
        case AccountType.TEST_ADMIN : 
        case AccountType.TEST_TAKER : 
        case AccountType.TEST_CTRL  : 
        case AccountType.TEST_CTRLD  : 
        case AccountType.CERT_BODY  : 
        case AccountType.TEST_AUTH  : 
        case AccountType.SUPPORT  :
        case AccountType.MRKG_MRKR :
        case AccountType.MRKG_SUPR :
        case AccountType.MRKG_CAND :
        case AccountType.MRKG_COORD :
        case AccountType.MRKG_LEAD :
        case AccountType.MRKG_CTRL :
        case AccountType.MRKG_UPLD :
        case AccountType.DIST_ADMIN :
          return this.router.navigate([langCode, accountType, 'dashboard']);
        default:
          throw new Error('Unkown user type');
      }
    }
  }

  activate(accountTypes?: AccountType[]) {
    this.isActivated = true;
    if (accountTypes) {
      if (accountTypes.indexOf(this.currentAccountType) === -1) {
        this.isValidAccountType = false;
      } else {
        this.isValidAccountType = true;
      }
    } else {
      this.isValidAccountType = true;
    }
    this.updateLoginReqState();
  }

  deactivate() {
    this.isActivated = false;
    this.updateLoginReqState();
  }
  supportReqActivate() {
    this.supportReq.next(true);
  }
  supportReqDeactivate() {
    this.supportReq.next(false);
  }

  quickPopup(caption?:string){
    this.confirmationReqActivate({
      caption: this.lang.tra(caption),
      hideCancel: true,
    })
  }
  
  disabledPopup(caption?:string){
    this.quickPopup(caption || 'feature_disabled');
  }

  confirmationReqActivate(config: IConfirmationReq) {
    if (config.confirm === undefined) {
      config.confirm = () => {};
    }
    this.confirmationReq.next(config);
  }
  confirmationReqDeactivate() {
    this.confirmationReq.next(null);
  }


  public activity(){
    return this.ioActivity
  }

  autoLogoutWarningTimeout:number;
  autoLogoutTimeout:number;

  private clearRecentActivityChecks(skipAutoLogout = false) {
    this.hasRecentActivity = false;
    this.autoLogoutWarning.next(false);
    if (!skipAutoLogout) {
      this.autoLogout.next(false);
    }
    clearInterval(this.recentActivityIntervalId);
    clearTimeout(this.autoLogoutWarningTimeoutId);
    clearTimeout(this.autoLogoutTimeoutId);
  }

  private recentActivityChecks = async (skipTokenRefresh = false) => {
     //console.log('recentActivityChecks', this.hasRecentActivity);
    if (!this.isAuthenticated) return;

    if (this.hasRecentActivity) {
      clearTimeout(this.autoLogoutWarningTimeoutId);
      this.autoLogoutWarningTimeoutId = setTimeout(() => {
        if (!this.hasRecentActivity) {
          this.autoLogoutWarning.next(true);
        }
      }, AUTO_LOGOUT_WARNING_INTERVAL);

      clearTimeout(this.autoLogoutTimeoutId);
      this.autoLogoutTimeoutId = setTimeout(() => {
        this.autoLogoutWarning.next(false);
        if (!this.hasRecentActivity) {
          this.autoLogout.next(true);
          this.auth.logout();
        }
      }, AUTO_LOGOUT_INTERVAL);

      // reset activity tracker
      this.hasRecentActivity = false;
      //console.log("checking skip token refresh",skipTokenRefresh)
      if (!skipTokenRefresh) {
        //console.log("calling refresh")
        await this.auth.refreshRefreshToken();
      }
    }
  }

  private initRecentActivityChecks() {
    //console.log('initRecentActivityChecks');
    this.clearRecentActivityChecks();
    this.hasRecentActivity = true;
    this.recentActivityChecks(true);
    this.recentActivityIntervalId = setInterval(this.recentActivityChecks, ACTIVITY_REFRESH_INTERVAL);
  }


  getAutoLogoutWarning() {return this.autoLogoutWarning; }
  getAutoLogout() {return this.autoLogout; }



}
