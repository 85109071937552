import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MarkingChatService} from '../../marking/marking-chat.service';
import {DATETIME} from '../../api/models/db-types';
import * as moment from 'moment-timezone';
import {FormControl} from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {AuthService} from '../../api/auth.service';

enum ChatRowType {
  PERSON = 'PERSON',
  GROUP = 'GROUP',
}

enum PersonType {
  MARKER = 'Marker',
  LEADER = 'Leader',
  COORDINATOR = 'Coordinator',
}

enum GroupType {
  LEADERS = 'LEADERS',
  MARKERS = 'Markers',
  ALL = 'BROADCAST'
}

export interface IMessage {
  senderUid: number;
  senderName: string;
  timestamp: DATETIME;

  message: string;
  isBroadcast: boolean;

  // seenBy: number[]; // array of UIDs.
}

// could be a group or person.
interface IChat {
  id: number;
  title: string;
  chat: IMessage[];

  type: ChatRowType;
  personType?: PersonType;
  groupType?: GroupType;
}

export interface IChatRow {
  unreadMessagesCount: number;
  activeCount: number;
  chat: IChat;
}

export interface IChatSection {
  rows: IChatRow[];
}

export interface IChatData {
  recent: IChatSection;
  groups: IChatSection;
  people: IChatSection;
  supportChat?: IChatRow;
}

@Component({
  selector: 'chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {
  SECTION_SOFT_MAX = 5;
  MAX_RECENT = 5;
  MY_UID = 1;

  @Input() accountType: string; // 'leader' or 'coordinator'

  ChatRowType = ChatRowType;

  constructor(
      public chat: MarkingChatService,
      public auth: AuthService,
  ) { }

  @ViewChild('textInput') textArea: CdkTextareaAutosize;
  @ViewChild('chatHistory') chatDiv: ElementRef;

  public data: IChatData;

  public isLoaded = false;
  public isOpen = false;
  public isEmojiPickerShowing = false;
 // public openChat: IChat;
  public openRow: IChatRow;

  public isSupportOpen = false;

  public hasUnread = false;

  public showMoreGroups = false;
  public showMorePeople = false;

  searchField = new FormControl();
  searchResults: IChatRow[];
  chatField = new FormControl();

  ngOnInit(): void {
    this.chat.getChatData(this.accountType).then(data => {
      this.data = data;
      this.isLoaded = true;
      this.checkUnread();
    });
  }

  getVisibleGroups() {
    if (this.showMoreGroups) {
      return this.data.groups.rows;
    } else {
      return this.data.groups.rows.slice(0, this.SECTION_SOFT_MAX);
    }

  }
  getVisiblePeople() {
    if (this.showMorePeople) {
      return this.data.people.rows;
    } else {
      return this.data.people.rows.slice(0, this.SECTION_SOFT_MAX);
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }
  toggleSupport() {
    this.isSupportOpen = !this.isSupportOpen;
  }
  selectRow(row: IChatRow) {
    // this.openChat = row.chat;
    this.openRow = row;
    this.clearUnread(row);
    this.scrollToBottom();
  }
  closeRow() {
    // this.openChat = null;
    this.isSupportOpen = false;
    this.openRow = null;
  }

  checkUnread() {
    for (let i = 0; i < this.data.people.rows.length; i++) {
      if (!!this.data.people.rows[i].unreadMessagesCount) {
        this.hasUnread = true;
        return;
      }
    }
    for (let i = 0; i < this.data.groups.rows.length; i++) {
      if (!!this.data.groups.rows[i].unreadMessagesCount) {
        this.hasUnread = true;
        return;
      }
    }
  }

  getTotalUnread() {
    let totalUnread = 0;
    this.data.people.rows.forEach((row: IChatRow) => {
      totalUnread += row.unreadMessagesCount;
    });
    this.data.groups.rows.forEach((row: IChatRow) => {
      totalUnread += row.unreadMessagesCount;
    });
    if (!totalUnread) { this.hasUnread = false; }
    return totalUnread;
  }

  searchChange() {
    let query: string = this.searchField.value;
    if (!query) {
      this.searchResults = null;
      return;
    }
    let searchQuery = query.toLowerCase();

    const groups = this.data.groups;
    const people = this.data.people;

    let searchResults: IChatRow[] = [];
    groups.rows.forEach((row: IChatRow) => {
      if (row.chat.title.toLowerCase().includes(searchQuery)) {
        searchResults.push(row);
      }
    });
    people.rows.forEach((row: IChatRow) => {
      if (row.chat.title.toLowerCase().includes(searchQuery)) {
        searchResults.push(row);
      }
    });

    this.searchResults = searchResults;

  }

  sendMessage() {
    const msg: string = this.chatField.value;
    if (!msg) {
      return;
    }

    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }
    const message: IMessage = {
      isBroadcast: false,
      message: msg,
      senderName: name,
      senderUid: uid,
      timestamp: moment().toString()
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.insertMessage(message);
    this.chat.sendMessage(message);
    this.chatField.setValue('');

    this.textArea.reset();
  }

  sendSupportMessage() {
    const msg: string = this.chatField.value;
    if (!msg) {
      return;
    }

    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }
    const message: IMessage = {
      isBroadcast: false,
      message: msg,
      senderName: name,
      senderUid: uid,
      timestamp: moment().toString()
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.data.supportChat.chat.chat.push(message);
    this.scrollToBottom();
    this.chat.sendSupportMessage(message);
    this.chatField.setValue('');

    this.textArea.reset();
  }

  clearUnread(row: IChatRow) {
    if (row.unreadMessagesCount) { // to reduce API calls.
      this.data.recent.rows.forEach((tempRow: IChatRow) => {
        if (tempRow.chat.id === row.chat.id) {
          tempRow.unreadMessagesCount = 0;
        }
      });

      const type = row.chat.type;
      if (type === ChatRowType.PERSON) {
        this.data.people.rows.forEach((tempRow: IChatRow) => {
          if (tempRow.chat.id === row.chat.id) {
            tempRow.unreadMessagesCount = 0;
          }
        });
      } else { // type === ChatRowType.GROUP
        this.data.groups.rows.forEach((tempRow: IChatRow) => {
          if (tempRow.chat.id === row.chat.id) {
            tempRow.unreadMessagesCount = 0;
          }
        });
      }
      row.unreadMessagesCount = 0;
      this.chat.clearUnread(0);
    }
  }

  insertMessage(message: IMessage) {
    // this.openChat.chat.push(message);
    this.openRow.chat.chat.push(message);
    this.updateRecent();
    this.scrollToBottom();
  }

  toggleEmojis() {
    this.isEmojiPickerShowing = !this.isEmojiPickerShowing;
    if (!this.isEmojiPickerShowing) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    let self = this;
    setTimeout(function() {
      if (self.chatDiv) {
        self.chatDiv.nativeElement.scrollTop = self.chatDiv.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined');
      }
    }, 10);
  }

  emojiHandler(emoji) {
    const raw = emoji.native || '';
    const currentTextValue = this.chatField.value || '';

    this.chatField.setValue(currentTextValue + raw);
  }

  inputHandler() {
    const val: string = this.chatField.value || '';
    if (!val) {
      this.textArea.reset();
    }
  }

  changeHandler() {
    this.textArea.reset(); // resizeToFitContent(true);
  }

  maxRows() {
    if (this.isEmojiPickerShowing) {
      return 1;
    } else {
      return 5;
    }
  }

  updateRecent() {
    // this.data.recent.rows;
    let newRows: IChatRow[] = [this.openRow];
    for (let i = 0; i < Math.min(this.MAX_RECENT, this.data.recent.rows.length + 1); i++) {
      if (this.data.recent.rows[i].chat.id !== this.openRow.chat.id) {
        newRows.push(this.data.recent.rows[i]);
      }
    }

    this.data.recent.rows = newRows;
  }

  toggleShowMorePeople() {
    this.showMorePeople = !this.showMorePeople;
  }
  toggleShowMoreGroups() {
    this.showMoreGroups = !this.showMoreGroups;
  }

}
