import { Component, OnInit, Input } from '@angular/core';
import { IContentElement, QuestionState, IEntryStateMic, ScoringTypes, getElementWeight } from '../models';

const SCORING_TYPE = ScoringTypes.MANUAL;

@Component({
  selector: 'element-render-mic',
  templateUrl: './element-render-mic.component.html',
  styleUrls: ['./element-render-mic.component.scss']
})
export class ElementRenderMicComponent implements OnInit {

  @Input() element:IContentElement;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  
  constructor() { }

  capture:any = {};

  ngOnInit() {
    this.ensureState();
  }

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
      if (!this.questionState[entryId]){
        let entryState:IEntryStateMic = {
          type: 'mic',
          isCorrect: null,
          isStarted: false,
          isFilled: false,
          url: '',
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
      this.capture = this.questionState[entryId];
    }
  }

  isRecording:boolean;
  startRecording(){
    this.isRecording = true;
  }
  stopRecording = () => {
    this.isRecording = false;
    if (this.questionState){
      const entryState:IEntryStateMic = this.questionState[this.element.entryId];
      if (entryState){
        entryState.isFilled = true;
        entryState.isStarted = true;
      }
    }
  }

}
