import {
    IChatData, IChatRow,
    IChatSection, IMessage,
} from '../../../ui-partial/chat-box/chat-box.component';
import {allMarkingSessions, markingSessionsByUID} from '../testing/markingSessions';
import {getRandomInt, ILeader, IMarker, IMarkingSession} from '../data';

enum ChatRowType {
    PERSON = 'PERSON',
    GROUP = 'GROUP',
}

enum PersonType {
    MARKER = 'Marker',
    LEADER = 'Leader',
    COORDINATOR = 'Coordinator',
}

enum GroupType {
    LEADERS = 'LEADERS',
    MARKERS = 'Markers',
    ALL = 'BROADCAST'
}

const matthewChat: IMessage[] = [
    {
        senderUid: 99,
        senderName: 'Matthew Wolfe',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Hey, how\'s it going?',
        isBroadcast: false,
    },
    {
        senderUid: 1,
        senderName: 'Charles Anifowose',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Good, thanks.',
        isBroadcast: false,
    },
    {
        senderUid: 99,
        senderName: 'Matthew Wolfe',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Good to hear!',
        isBroadcast: false,
    },
];
const groupChat: IMessage[] = [
    {
        senderUid: 1,
        senderName: 'Charles Anifowose',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'How is everything going?',
        isBroadcast: true,
    },
    {
        senderUid: 99,
        senderName: 'Matthew Wolfe',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Great, I love this marking system!',
        isBroadcast: false,
    },
    {
        senderUid: 98,
        senderName: 'Anand Karat',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Same here.',
        isBroadcast: false,
    },
    {
        senderUid: 99,
        senderName: 'Matthew Wolfe',
        timestamp: '2020-06-30T09:00:00.000Z',

        message: 'Yes :wink:',
        isBroadcast: false,
    },
];

const sections: IChatSection = {
    rows: [],
};
const coordinatorData: IChatData = {
    groups: {
        rows: [
            {
                unreadMessagesCount: 2,
                activeCount: 0,
                chat: {
                    id: 1,
                    title: 'June 2020 Numeracy (Broadcast)',
                    chat: groupChat,

                    type: ChatRowType.GROUP,
                    personType: null,
                    groupType: GroupType.ALL,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 3,
                chat: {
                    id: 2,
                    title: 'June 2020 Numeracy (Leaders)',
                    chat: [],

                    type: ChatRowType.GROUP,
                    personType: null,
                    groupType: GroupType.LEADERS,
                },
            }
        ]
    },
    people: {
        rows: [
            {
                unreadMessagesCount: 1,
                activeCount: 1,
                chat: {
                    id: 3,
                    title: 'Matthew Wolfe (Leader)',
                    chat: matthewChat,

                    type: ChatRowType.PERSON,
                    personType: PersonType.LEADER,
                    groupType: null,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 0,
                chat: {
                    id: 4,
                    title: 'Sam Recola (Marker)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.MARKER,
                    groupType: null,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 1,
                chat: {
                    id: 5,
                    title: 'Timothy Leary (Marker)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.MARKER,
                    groupType: null,
                },
            }
        ]
    },
    recent: {
        rows: [
            {
                unreadMessagesCount: 1,
                activeCount: 1,
                chat: {
                    id: 3,
                    title: 'Matthew Wolfe (Leader)',
                    chat: matthewChat,

                    type: ChatRowType.PERSON,
                    personType: PersonType.LEADER,
                    groupType: null,
                },
            },
            {
                unreadMessagesCount: 2,
                activeCount: 0,
                chat: {
                    id: 1,
                    title: 'June 2020 Numeracy (Broadcast)',
                    chat: groupChat,

                    type: ChatRowType.GROUP,
                    personType: null,
                    groupType: GroupType.ALL,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 3,
                chat: {
                    id: 2,
                    title: 'June 2020 Numeracy (Leaders)',
                    chat: [],

                    type: ChatRowType.GROUP,
                    personType: null,
                    groupType: GroupType.LEADERS,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 0,
                chat: {
                    id: 4,
                    title: 'Sam Recola (Marker)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.MARKER,
                    groupType: null,
                },
            },
            {
                unreadMessagesCount: 0,
                activeCount: 1,
                chat: {
                    id: 5,
                    title: 'Timothy Leary (Marker)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.MARKER,
                    groupType: null,
                },
            }
        ],
    }
};

const emptyData: IChatData = {
    groups: sections,
    people: sections,
    recent: sections
};

function sessionsToMarkerRows(sessions: IMarkingSession[]) {
    const rows: IChatRow[] = [];
    sessions.forEach((session: IMarkingSession) => {
        session.markers.forEach((marker: IMarker) => {
            const row: IChatRow = {
                unreadMessagesCount: 0,
                activeCount: getRandomInt(0, 1),
                chat: {
                    id: marker.id,
                    title: marker.first_name + ' ' + marker.last_name + ' (Marker)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.MARKER,
                    groupType: null,
                },
            };
            rows.push(row);
        });
    });
    return rows;
}
function sessionsToLeaderRows(sessions: IMarkingSession[]) {
    const rows: IChatRow[] = [];
    sessions.forEach((session: IMarkingSession) => {
        session.leaders.forEach((leader: ILeader) => {
            const row: IChatRow = {
                unreadMessagesCount: 0,
                activeCount: getRandomInt(0, 1),
                chat: {
                    id: leader.id,
                    title: leader.first_name + ' ' + leader.last_name + ' (Leader)',
                    chat: [],

                    type: ChatRowType.PERSON,
                    personType: PersonType.LEADER,
                    groupType: null,
                },
            };
            rows.push(row);
        });
    });
    return rows;
}
function sessionsToGroupRows(sessions: IMarkingSession[]) {
    const rows: IChatRow[] = [];
    sessions.forEach((session: IMarkingSession) => {
        const row: IChatRow = {
            unreadMessagesCount: 0,
            activeCount: getRandomInt(0, 10),
            chat: {
                id: session.id,
                title: session.name,
                chat: [],

                type: ChatRowType.GROUP,
                personType: null,
                groupType: GroupType.ALL,
            },
        };
        rows.push(row);
    });
    return rows;
}

function sessionsToCoordinatorGroupRows(sessions: IMarkingSession[]) {
    const rows: IChatRow[] = [];
    sessions.forEach((session: IMarkingSession) => {
        const row1: IChatRow = {
            unreadMessagesCount: 0,
            activeCount: getRandomInt(0, 10),
            chat: {
                id: session.id + 21,
                title: session.name + ' (Leaders)',
                chat: [],

                type: ChatRowType.GROUP,
                personType: null,
                groupType: GroupType.ALL,
            },
        };
        const row2: IChatRow = {
            unreadMessagesCount: 0,
            activeCount: getRandomInt(0, 10),
            chat: {
                id: session.id,
                title: session.name + ' (Broadcast)',
                chat: [],

                type: ChatRowType.GROUP,
                personType: null,
                groupType: GroupType.ALL,
            },
        };
        rows.push(row1);
        rows.push(row2);
    });
    return rows;
}

export function genCoordinatorData(): IChatData {
    return coordinatorData;
}

export function genLeaderData(): IChatData {
    // return leaderData;
    return coordinatorData;
}

const supportChat: IChatRow = {
    unreadMessagesCount: 0,
    activeCount: getRandomInt(0, 10),
    chat: {
        id: -1,
        title: 'Technical Support',
        chat: [],

        type: ChatRowType.PERSON,
        personType: PersonType.COORDINATOR,
        groupType: null,
    }

};

export function chatDataByUid(uid: number): IChatData {
    let sessions: IMarkingSession[];
    let groups: IChatSection;
    let people: IChatSection;
    let recent: IChatSection = {rows: []};
    switch (uid) {
        case 6265: // Coordinator 1
        case 6266: // Coordinator 2
            // all leaders.
            // all markers.
            // all marking sessions
            sessions = allMarkingSessions;
            groups = {rows: sessionsToCoordinatorGroupRows(sessions)};
            const markers = sessionsToMarkerRows(sessions);
            const leaders = sessionsToLeaderRows(sessions);
            people = {rows: markers.concat(leaders)};
            return {
                groups,
                people,
                recent,
                supportChat,
            };
        case 6267: // Leader 1
            // all markers (in session).
            // marking session
        case 6268: // Leader 2
            // all markers (in session).
            // marking session
        case 6269: // Leader 3
            // all markers (in session).
            // marking session
            sessions = markingSessionsByUID(uid);
            groups = {rows: sessionsToGroupRows(sessions)};
            people = {rows: sessionsToMarkerRows(sessions)};
            return {
                groups,
                people,
                recent,
                supportChat
            };
        case 6270: // Marker 1
        case 6271: // Marker 2
        case 6272: // Marker 3
            sessions = markingSessionsByUID(uid);
            groups = {rows: []};
            people = {rows: sessionsToLeaderRows(sessions)};
            return {
                groups,
                people,
                recent,
                supportChat
            };
    }
    return emptyData;
}
