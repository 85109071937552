import {randomFirstName, randomLastName} from '../../../constants/fakenames';
import {ILeader} from '../data';

export const REAL_LEADERS = [
    {
        'id': 6267,
        'first_name': 'Demo 1',
        'last_name': 'Leader',
        'notes': ''
    },
    {
        'id': 6268,
        'first_name': 'Demo 2',
        'last_name': 'Leader',
        'notes': ''
    },
    {
        'id': 6269,
        'first_name': 'Demo 3',
        'last_name': 'Leader',
        'notes': ''
    },
];

export const DUMMY_LEADERS = [
    {
        'id': 0,
        'first_name': 'Maridel',
        'last_name': 'Franci',
        'notes': ''
    },
    {
        'id': 1,
        'first_name': 'Corella',
        'last_name': 'Felike',
        'notes': ''
    },
    {
        'id': 2,
        'first_name': 'Randene',
        'last_name': 'Farrington',
        'notes': ''
    },
    {
        'id': 3,
        'first_name': 'Elana',
        'last_name': 'Kamin',
        'notes': ''
    },
    {
        'id': 4,
        'first_name': 'Sindee',
        'last_name': 'Head',
        'notes': ''
    },
    {
        'id': 5,
        'first_name': 'Lise',
        'last_name': 'Magnuson',
        'notes': ''
    },
    {
        'id': 6,
        'first_name': 'Tara',
        'last_name': 'Courtenay',
        'notes': ''
    },
    {
        'id': 7,
        'first_name': 'Marianna',
        'last_name': 'Sinai',
        'notes': ''
    },
    {
        'id': 8,
        'first_name': 'Nerty',
        'last_name': 'Caughey',
        'notes': ''
    },
    {
        'id': 9,
        'first_name': 'Lise',
        'last_name': 'Kleeman',
        'notes': ''
    },
    {
        'id': 10,
        'first_name': 'Talyah',
        'last_name': 'Farrington',
        'notes': ''
    },
    {
        'id': 11,
        'first_name': 'Bethena',
        'last_name': 'Amena',
        'notes': ''
    }
];

export function getNumeracyLeaders() {
    return [REAL_LEADERS[0]].concat(DUMMY_LEADERS.slice(0, 6));
}
export function getLiteracyLeaders() {
    return [REAL_LEADERS[1], REAL_LEADERS[2]].concat(DUMMY_LEADERS.slice(6, 12));
}

const leaders: ILeader[] = [...Array(12)].map((_, i) => {
    return {
        id: i,
        first_name: randomFirstName(),
        last_name: randomLastName(),
        notes: ''
    };
});

export function genLeaders() {
    console.log(leaders);
    // 1. Print to console.
    // 2. store as global variable (right click the printed array in the console).
    // 3. copy(temp1).
    // 4. paste here.
}
