<div *ngIf="!isSimple" class="widget-display-container" style="height:550px; padding:20px; margin:auto; width:340px; background-color: #2A304B; cursor:grab" cdkDrag>
    <element-render-iframe  [element]="element"></element-render-iframe>
</div>

<div *ngIf="isSimple" style="position:absolute; left: 21em; top: 6em;">
    <div class="calc-container" 
         #container cdkDrag tabindex="0" 
         (focus)="onCalculatorFocus()" 
         (focusout)="onCalculatorFocusOut()"
    >
        <div class="calc-display">
        <div [ngStyle]="{'font-size.em':getFontDisplayScale()}">
            {{getScreenDisplay()}}
        </div>
    </div>
    <div class="button-container">
        <div class="button-container-main">
            <div class="button-container-main-top">
            <span
                    *ngFor="let btn of functionButtons"
                    (click)="pressFunction(btn.func)"
                    [class.is-2w]="btn.isDoubleWidth"
                    [class.is-3w]="btn.isTripleWidth"
            >{{btn.caption}}</span>
            </div>
            <div  class="button-container-main-bottom">
            <span
                    *ngFor="let btn of numberButtons"
                    (click)="pressNumber(btn.num)"
                    [class.is-2w]="btn.isDoubleWidth"
                    [class.is-3w]="btn.isTripleWidth"
            >{{btn.caption || btn.num}}</span>
            </div>
        </div>
        <div class="button-container-side">
            <button
            *ngFor="let btn of operatorButtons"  
            (click)="pressOperator(btn.operator)"
            [class.is-2w]="btn.isDoubleWidth"
            [class.is-3w]="btn.isTripleWidth"
            tabindex="-1"
            >{{btn.caption}}</button>
        </div>
    </div>
    <input id="null-focus" style="display:none"/>
</div>
