import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IContentElementMcq, IContentElementMcqOption, QuestionState, McqDisplay, IContentElementOrder, ScoringTypes } from '../models';
import { indexOf } from '../services/util';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-order',
  templateUrl: './element-render-order.component.html',
  styleUrls: ['./element-render-order.component.scss']
})
export class ElementRenderOrderComponent implements OnInit, OnChanges {

  @Input() element:IContentElementOrder;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;

  constructor() { }

  options;
  currentSelections = [];

  ngOnInit() {
    this.resetOptions();
  }

  resetOptions(){
    this.options = [].concat(this.element.options);
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.changeCounter){
      this.resetOptions();
    }
  }

  isVertical(){
    return (this.element.displayStyle===McqDisplay.VERTICAL)
  }
  isHorizontal(){
    // return (this.element.displayStyle==='vertical')
    return !this.isVertical()
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

}
