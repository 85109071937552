import { FormControl, FormArray } from "@angular/forms";


export const bindFormControls = (element:{[key:string]:any}, bindings:Array<{f:FormControl|FormArray, p:string, isFormArray?:boolean}>) => {
    bindings.forEach(binding => {
        bindFormControl(binding.f, element, binding.p, binding.isFormArray);
    })
}

export const bindFormControl = (formControlorArray:FormControl|FormArray, element:{[key:string]:any}, property:string, isFormArray?:boolean) => {
    if (element[property]){
        if (isFormArray){
            let formArray:FormArray = <FormArray>formControlorArray;
            element[property].forEach(entry => {
                formArray.push(new FormControl(entry));
            })
            // console.log('formArray', formArray)
            // formArray.setValue(element[property])
        }
        else{
            let formControl:FormControl = <FormControl>formControlorArray;
            formControl.setValue(element[property]);
        }
    }
    formControlorArray.valueChanges.subscribe( () => {
        element[property] = formControlorArray.value;
    });
}
