<audio
    #audioPlayer
    [src]="sanitizeUrl(url)"
    controlsList="nodownload"
    [controls]="hasControls"
    [class.has-controls]="hasControls"
></audio>
    
    
<!-- <plyr plyrTitle=""
      *ngIf="hasControls"
      [plyrType]="audio"
      [plyrPlaysInline]="false"
      [plyrOptions]="plyrOptions"
      [plyrSources]="getAudioSources()"
      plyrCrossOrigin="anonymous"
></plyr> -->
