import { Injectable } from '@angular/core';
import {IChatData, IMessage} from '../ui-partial/chat-box/chat-box.component';
import {chatDataByUid, genCoordinatorData, genLeaderData} from './data/chatData/chatData';
import {AuthService} from '../api/auth.service';
import Sockette from "sockette";

@Injectable({
  providedIn: 'root'
})
export class MarkingChatService {

  socket: any;
  readonly uri: string = 'wss://mwgmbuiprh.execute-api.ca-central-1.amazonaws.com/production';
  private uid = 6265;

  constructor(
      public auth: AuthService,
  ) {
    /*
    this.socket = new Sockette(this.uri, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        console.log('opened');
      },
      onmessage: e => {
        console.log('got a message:', e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => console.log('Closed!', e),
      onerror: e => console.log('Error:', e)
    });*/
  }

  getChatData(accountType: string): Promise<IChatData> {
    let user = this.auth.user().value;
    if (!user) {
      user = this.auth.user().getValue();
    }
    if (user) {
      this.uid = user.uid;
    }
    return Promise.resolve(chatDataByUid(this.uid));
    /*
    if (accountType === 'coordinator') {
      return Promise.resolve(genCoordinatorData());
    } else {
      return Promise.resolve(genLeaderData());
    }*/
  }

  sendMessage(msg: IMessage) {
    //
  }

  clearUnread(id: number) {
    //
  }

  // support and socket stuff
  sendSupportMessage(msg: IMessage) {
    console.log(msg);
    const data = {
      uid: this.uid,
      msg: msg.message
    };
    this.emit('messageToSupport', data);
  }

  public emit(action, data) {
    this.socket.json({action, data});
  }

}
