import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { transformFrenchDecimal, transformThousandsSeparator } from '../render-math/render-math.component';
import { LangService } from '../../core/lang.service';


const procesAutosSup = (str:string, lang:string) => {
  const i_letters = str.search(/[a-zA-Z]+/);
  const number = str.substr(0, i_letters)
  let letters = str.substr(i_letters, str.length-1);
  let allowedSup = [];
  if (lang === 'en'){ 
    allowedSup = [
      'st',
      'nd',
      'rd',
      'th',
    ]
  }
  if (lang === 'fr'){
    allowedSup = [
      'e',
      'er',
      're',
      'ée',
      'ee',
      'ieme',
      'ième',
    ]
  }
  if (allowedSup.indexOf(letters) !== -1){
    letters = '<sup>'+letters+'</sup>'
  }
  return number + letters
}
const stripMarkedupEdges = (count:number, str:string) => {
  return str.substr(count, str.length-count*2)
}
const formatNumber = (str:string, lang:string) => {
  str = transformThousandsSeparator(str, lang);
  str = transformFrenchDecimal(str, lang);
  return str;
}

@Component({
  selector: 'markdown-inline',
  templateUrl: './markdown-inline.component.html',
  styleUrls: ['./markdown-inline.component.scss']
})
export class MarkdownInlineComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('span', {static:false}) span:ElementRef;
  @Input() input:string;
  output:string;

  constructor(
    private lang:LangService,
  ) { }

  ngOnInit() {
    this.updateRender();
  }

  ngAfterViewInit(){
    // document.addEventListener('selectionchange', () => {
    //   // console.log('document change selection')
    // });
    // this.span.nativeElement.addEventListener('selectionchange', () => {
    //   console.log('element change selection')
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateRender();
  }

  updateRender(){
    
    let val = this.input;

    const lang = this.lang.c();

    const regex_bold = /\*\*(\S(.*?\S)?)\*\*/gi;
    const regex_em = /\*(\S(.*?\S)?)\*/gi;
    const regex_del = /\~\~(\S(.*?\S)?)\~\~/gi;
    const regex_exponent_number = /\^\d+/g;
    const regex_number_space = /\d+\s\w/g;
    const regex_number_ord = /\d+[a-zA-Z]+/g;
    const regex_number = /\$?Y?\d[\d\ \,]*([\.\,]\d+)?(\s?\$)?/g; // the extra Y in front is to escape years in French
    // const regex_dollarsign = /\$/g;
    const regex_variable = /\`\w\`/gi;
  

    val = val.replace(regex_bold, (str) => '<strong>' + stripMarkedupEdges(2, str) + '</strong>');
    val = val.replace(regex_em, (str) => '<em>' + stripMarkedupEdges(1, str) + '</em>');
    val = val.replace(regex_del, (str) => '<del>' + stripMarkedupEdges(2, str) + '</del>');
    if (lang === 'fr'){
      val = val.split(' :').join('&nbsp;:') ;  
      val = val.split(' !').join('&nbsp;!') ;  
      val = val.split(' ?').join('&nbsp;?') ;  
    }
    val = val.replace(/m[2-3]\b/g, (str) => {
      const letter = str.substr(0, 1);
      const number = str.substr(1, 1);
      return `${letter}<sup>${number}</sup>`;
    })
    val = val.replace(regex_number_space, (str) => str.split(' ').join('&nbsp;') );
    val = val.replace(regex_number_ord, str => procesAutosSup(str, lang));
    val = val.replace(regex_exponent_number, (str) => {
      return '<sup>' + str.substr(1, str.length -1) + '</sup>'
    })
    val = val.replace(regex_number, (str) => {
      if (str === '.'){
        return str;
      }
      else{
        return '<span class="katex keep-together">' + formatNumber(str, lang) + '</span>'
      }
    });
    // val = val.replace(regex_dollarsign, (str) => '<span class="katex keep-together">' + str + '</span>');
    val = val.replace(regex_variable, (str) => '<em><span class="katex keep-together">' + stripMarkedupEdges(1, str) + '</span></em>');

    this.output = val;
  }

}


