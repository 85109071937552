import { Injectable, OnDestroy } from '@angular/core';
import { AuthScopeSettingsService, AuthScopeSetting } from './auth-scope-settings.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EditingDisabledService implements OnDestroy {

  private isEditingDisabled = new BehaviorSubject<boolean>(false);
  private isEditingDisabled$ = this.isEditingDisabled.asObservable();
  private fcReadOnlySubscription: Subscription;
  
  private formControls: FormControl[]

  constructor(private authScopeSettings: AuthScopeSettingsService) { 
    this.formControls = [];
    this.fcReadOnlySubscription = this.isEditingDisabled$.subscribe(enabled => {
      this.formControls.forEach(fc => {
        if(this.isReadOnly()) {
          fc.disable();
        } else {
          fc.enable();
        }
      });
    })
  }

  ngOnDestroy(): void {
    this.fcReadOnlySubscription.unsubscribe();
  }

  setEditingDisabled(b:boolean) {
    this.isEditingDisabled.next(b);
  }

  getEditingDisabled() {
    return this.isEditingDisabled.getValue();
  }

  //Register a list of form controls passed in as an object where each field is a form control
  registerFormControlsObj(formControls: any) {
    Object.values(formControls).forEach( (f: FormControl) => {
      this.formControls.push(f);
      if(this.isReadOnly()) {
        f.disable();
      }
    });
  }

  registerFormControls(formControls: any) {
    formControls.forEach(fc => {
      if(fc.p !== 'isShowAdvancedOptions') {
        this.formControls.push(fc.f);
        if(this.isReadOnly()) {
          fc.f.disable();
        }
      }
    })
  }

  registerFormControlsArr(formControls: FormControl[]) {
    formControls.forEach( fc => {
      this.formControls.push(fc);
      if(this.isReadOnly()) {
        fc.disable();
      }
    });
  }

  isReadOnly() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_EDITING) || this.getEditingDisabled();
  }
}