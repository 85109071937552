<div>
  <div class="element-group-header">
    <i class="far fa-square" aria-hidden="true"></i>
    Background Elements
  </div>
  <div cdkDropList [cdkDropListDisabled]="isReadOnly()" (cdkDropListDropped)="drop(element.backgroundElements, $event);">
    <div cdkDrag class="draggable-row" *ngFor="let backgroundEl of element.backgroundElements">
      <a 
        cdkDragHandle
        class="button  is-handle" 
        [class.is-info]="isSelectedPosRef(backgroundEl)"
      >
        <i class="fas fa-arrows-alt" aria-hidden="true"></i>
      </a>
      <div>
        <div style="margin-bottom:1em;">
          <capture-dnd-style 
            [element]="backgroundEl" 
            [isHideId]="true" 
            [isSetDim]="false" 
            (change)="throttledUpdate()"
            (idClick)="selectPosElByRef(backgroundEl)"
          ></capture-dnd-style>
        </div>
        <element-config class="row-content" [contentElement]="backgroundEl.element"></element-config>
      </div>    
      <a class="button is-expanded" (click)="removeElement(element.backgroundElements, backgroundEl)">
        <i class="fas fa-trash" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div>
    <select-element 
      [elementTypes]="subElementTypes"
      (insert)="insertBackgroundElement($event)"
      style="margin-top:1em;" 
    ></select-element>
  </div>

  <hr />
  
  <div class="element-group-header">
    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
    Draggable Elements
  </div>
  <div 
    cdkDropList 
    [cdkDropListDisabled]="isReadOnly()"
    (cdkDropListDropped)="drop(element.draggables, $event);"
  >
    <div 
      cdkDrag 
      *ngFor="let draggableEl of element.draggables"
      class="draggable-row" 
    >
      <a 
        cdkDragHandle 
        [class.no-pointer-events]="isReadOnly()"
        class="button is-handle" 
        [class.is-info]="isSelectedPosRef(draggableEl)"
        (mousedown)="selectPosElByRef(draggableEl)"
      >
        <i class="fas fa-arrows-alt" aria-hidden="true"></i>
      </a>
      <div class="row-content">
        <capture-dnd-style 
          [element]="draggableEl" 
          (change)="throttledUpdate()"
          (idClick)="selectPosElByRef(draggableEl)"
        ></capture-dnd-style>
        <element-config  [contentElement]="draggableEl.element"></element-config>
      </div>
      <a class="button is-expanded" (click)="removeElement(element.draggables, draggableEl)">
        <i class="fas fa-trash" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div>
    <select-element 
      [elementTypes]="subElementTypes"
      (insert)="insertDraggableElement($event)"
      style="margin-top:1em;" 
    ></select-element>
  </div>
  <hr/>
  <div class="element-group-header">
    <i class="fa fa-crosshairs" aria-hidden="true"></i>
    Target Elements
  </div>
  <div style="margin-bottom:1em;">
    <div style="margin-bottom:0.2em;">
      Default Size: (<label><input type="checkbox" [formControl]="customTargetDim"><span>customize for each target?</span></label>)
    </div>
    <capture-dnd-style
      [element]="element.defaultTargetStyle" 
      [isHideId]="true"
      [isHidePos]="true"
      [isSetDim]="true"
      (change)="throttledUpdate()"
    ></capture-dnd-style>
    <hr style="background-color:#f1f1f1"/>
  </div>
  <div 
    cdkDropList 
    [cdkDropListDisabled]="isReadOnly()"
    (cdkDropListDropped)="drop(element.targets, $event);"
  >
    <div 
      cdkDrag 
      *ngFor="let targetEl of element.targets"
      class="draggable-row" 
    >
      <a 
        [class.no-pointer-events]="isReadOnly()"
        class="button  is-handle" 
        cdkDragHandle 
        (mousedown)="selectPosElByRef(targetEl)"
        [class.is-info]="isSelectedPosRef(targetEl)"
      >
        <i class="fas fa-arrows-alt" aria-hidden="true"></i>
      </a>
      <div class="row-content" >
        <capture-dnd-style 
          [element]="targetEl" 
          [isSetDim]="element.customTargetDim" 
          (change)="throttledUpdate()"
          (idClick)="selectPosElByRef(targetEl)"
        ></capture-dnd-style>
      </div>
      <a class="button is-expanded" (click)="removeElement(element.targets, targetEl)">
        <i class="fas fa-trash" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div>
    <button 
      [disabled]="isReadOnly()"
      (click)="insertTargetElement()"
      class="button is-primary is-fullwidth"
      style="margin-top:1em;"
    > Add </button>
  </div>
  
  <hr />
  
  
  <div class="element-group-header">
    Position and Preview
  </div>

  <div class="prop-input-grid">
    <div class="prop-input-cell">
      <div class="prop-label">W</div> 
      <input type="number" [formControl]="width">
    </div>
    <div class="prop-input-cell">
      <div class="prop-label">H</div> 
      <input type="number" [formControl]="height">
    </div>
  </div>

  
  <div 
    *ngIf = "false"
    #elPosContainer
    class="pos-container"
    style="margin-top:1em;"
    [ngStyle]="{
      'width.px' :  element.width, 
      'height.px' : element.height
    }"
  >
    <div 
      *ngFor="let elPos of elementsToPosition" 
      class="pos-el" 
      [ngStyle]="elPos.style" 
      cdkDrag
      cdkDragBoundary=".pos-container"
      (cdkDragEnded)="setElPosFromDrag(elPos, $event)"
      [class.draggable-target]="elPos.isTarget"
      [class.drag-el]="elPos.isDraggable"
      [class.is-selected]="isSelectedPos(elPos)"
      (mousedown)="selectPosEl(elPos)"
    >
      <element-render 
        *ngIf="elPos.ref.element" 
        [class.no-pointer-events]="isReadOnly()"
        [contentElement]="elPos.ref.element" 
        [isLocked]="true"  
        [questionState]="{}"
      ></element-render>
    </div>
  </div>

  <hr />

  <div class="form-row" *ngIf="isScoreWeightEnabled()">
    <div class="form-row-label">
      Score Weight
    </div>
    <div class="form-row-input">
      <input type="text" class="input" [formControl]="scoreWeight">
    </div>
  </div>

</div>